import $ from 'jquery';
import { ConsoleLog } from "./Logger";
import Utils from "formiojs/utils";
import DOMPurify from 'dompurify';
import {config} from "../config";
import {getDownload, deleteDownload} from "./API";
import { WHT_CONSTANT } from './CommonEnumTypes';
import _ from 'lodash';

const MAX_EXECUTIONS = 1;
const EXECUTION_INTERVAL_MS = 1000;
const HelperFindDataGridComponentFromChildren_ForRenderEvent = (childComponent) => {
  if(!childComponent)
    return null;
  if(childComponent.type === 'datagrid') {
    return childComponent;
  }
  return HelperFindDataGridComponentFromChildren_ForRenderEvent(childComponent.parent);
}

const HelperFindFileUploadComponentsFromDataGrid = (dataGrid, fileComponentArray) => {
  Utils.searchComponents(dataGrid.components, { type: "file" }).forEach(
    (instance) => {
      fileComponentArray.push(instance);
  });

  // Fix 1b
  // Handle specifically for datagrid in page 5, where Utils.searchComponents unable to find "file".
  //--> In this event, we manually loop and push it into the array ourself
  //--> Impact: will not cause a side effect to other functions using this helper class, as i use the check component for "file" type only to safeguard 
  //    it from breaking others
  //if (fileComponentArray?.length === 0 &&  dataGrid?.key === 'dataGridAttachmentOfSgBankOrFI') {
    if (dataGrid?.key === 'dataGridAttachmentOfSgBankOrFI') {
    if (dataGrid?.components?.length !== 0) {
      for (let i = 0; i < dataGrid?.components?.length; ++i) {
        let fileComponent = dataGrid?.components[i]?.components?.[0];
        if (fileComponent && fileComponent?.component?.type === "file") {
          fileComponentArray.push(fileComponent);
        }
      }
    }
  }
}

const HelperFormatBaseUrl = (startingString, inputUrl) => {
  const startIndex = inputUrl?.indexOf(startingString);
  let url = config.appFormBaseURL;
  const baseUrl = new URL(url).origin;
  const resultUrl = baseUrl + inputUrl?.slice(startIndex);
  return (startIndex >= 0 ? DOMPurify.sanitize(resultUrl) : undefined);
}

const HelperToggleSpinner = (setStartSpinner, booleanValue) => {
  if(setStartSpinner)
    setStartSpinner(booleanValue);
}

const HelperFileUploadRender_IsInputValid = (renderAllPages, currentForm) => {
  if(renderAllPages) {
    if(currentForm?.components === undefined || currentForm?.components === null)
      return false;
  } else {
    if(currentForm?.page === undefined)
      return false;
    if(currentForm?.allPages?.[currentForm.page]?.components === undefined)
      return false;
  }
  return true;
}

const HelperFileUploadRender_Get_FileComponentArray_And_TargetElements_Download = (currentForm, isDataGridChildren, instance, fileComponentArray) => {
  let targetElement = null;
  if(isDataGridChildren) {
    const elementId = instance?.id?.toString();
    let dataGridComp = HelperFindDataGridComponentFromChildren_ForRenderEvent(currentForm?.getComponentById(elementId));
    if(dataGridComp) {
      targetElement = $("#" + dataGridComp.id).find("a:not(.browse)");
      HelperFindFileUploadComponentsFromDataGrid(dataGridComp, fileComponentArray);
    } else {
      targetElement = $("#" + instance?.component?.id?.toString()).find("a:not(.browse)");
      fileComponentArray.push(instance);
    }
  }
  else {
    targetElement = $("#" + instance?.component?.id?.toString()).find("a:not(.browse)");
    fileComponentArray.push(instance);
  }

  return targetElement;
}

const HelperFileUploadRender_Get_FileComponentArray_And_TargetElements_Delete = (currentForm, isDataGridChildren, instance, fileComponentArray) => {
  let targetElement;
  // Custom handling of DataGrid "FileComponent" separately, as all FileComponent in DataGrid have the same attr->"id"
  if(isDataGridChildren) {
    const elementId = instance?.id?.toString();
    let dataGridComp = HelperFindDataGridComponentFromChildren_ForRenderEvent(currentForm.getComponentById(elementId));
    if(dataGridComp) {
      targetElement = $("#" + dataGridComp.id).find(".fa-remove");
      HelperFindFileUploadComponentsFromDataGrid(dataGridComp, fileComponentArray);
    } else {
      targetElement = $("#" + instance?.component?.id?.toString()).find(".fa-remove");
      fileComponentArray.push(instance);
    }
  }
  else {
    targetElement = $("#" + instance?.component?.id?.toString()).find(".fa-remove");
    fileComponentArray.push(instance);
  }
  return targetElement;
}

const HelperFileUploadRender_OverwriteFileComponent_HTMLElement_Download = (fileName, url, targetElement, targetElementCounter, setStartSpinner) => {
  const newElement = $("<a>", {});
  $(newElement).attr("ref", "fileLink");
  $(newElement).attr(
    "style",
    "cursor:pointer;color: #0455d4;display: inline-block;"
  );
  $(newElement).attr(
    "onmouseover",
    "this.style.textDecoration='underline'"
  );
  $(newElement).attr(
    "onmouseout",
    "this.style.textDecoration='none'"
  );
  const spanElement = $("<span>", {
    class: "sr-only",
    text: "Press to open "
  });
  const textNode = document.createTextNode(fileName);
  $(newElement).append(spanElement);
  $(newElement).append(textNode);
  $(newElement).on("click", () => {
    HelperToggleSpinner(setStartSpinner, true);
    getDownload(DOMPurify.sanitize(url), fileName)
      .then(() => {
        HelperToggleSpinner(setStartSpinner, false);
      })
      .catch((err) => {
        ConsoleLog(err);
        HelperToggleSpinner(setStartSpinner, false);
      });
  });
  $(targetElement[targetElementCounter]).replaceWith(newElement);
}

const HelperFileUploadRender_OverwriteFileComponent_HTMLElement_Delete = (targetElement, targetElementCounter, clickFunction) => {
  let newElement = $("<div>", {
    ref: targetElement.attr("id"),
    class: targetElement.attr("class"),
    tabindex: targetElement.attr("tabindex"),
  });
  $(newElement).attr("style", "cursor:pointer;");
  $(newElement).on("click", clickFunction);
  $(targetElement[targetElementCounter]).replaceWith(newElement);
}

const processFileComponent_Download = (comp, targetElement, setStartSpinner, targetElementCounter) => {
  const dataValue = comp?.dataValue;
  if (!Array.isArray(dataValue) || dataValue.length === 0) {
      return targetElementCounter;
  }

  for (const element of dataValue) {
    const fileName = element?.originalName;
      const url = HelperFormatBaseUrl("/MPAFormIO", element?.url);
      if (fileName && url && targetElement.length > 0) {
          HelperFileUploadRender_OverwriteFileComponent_HTMLElement_Download(fileName, url, targetElement, targetElementCounter, setStartSpinner);
          ++targetElementCounter;
      }
  }

  return targetElementCounter;
};

const callbackFileDelete = (url, comp, propertyName, index, renderAllPages, currentForm, setStartSpinner) => {
  HelperToggleSpinner(setStartSpinner, true);
  deleteDownload(DOMPurify.sanitize(url))
    .then(() => {
      comp?._data?.[propertyName]?.splice(index, 1);
      comp.triggerRedraw();
      // When redraw, we need run this jquery below again, because form got re-rendered, reseting all jquery back to default
      setTimeout(() => {
        CommonHandleCustomFileUpload_Render(renderAllPages, currentForm, setStartSpinner);
        HelperToggleSpinner(setStartSpinner, false);
      }, 1000);
    })
    .catch((err) => {
      ConsoleLog(err);
      HelperToggleSpinner(setStartSpinner, false);
    });
}

const processFileComponent_Delete = (comp, targetElement, setStartSpinner, targetElementCounter, renderAllPages, currentForm) => {
  const dataValue = comp?.dataValue;
  const propertyName = comp?.component?.key;

  if (!Array.isArray(dataValue) || dataValue.length === 0) {
      return targetElementCounter;
  }

  dataValue.forEach((element, index) => {
      const fileName = element?.originalName;
      const url = HelperFormatBaseUrl("/MPAFormIO", element?.url);
      if (fileName && url && targetElement.length > 0) {
          HelperFileUploadRender_OverwriteFileComponent_HTMLElement_Delete(
            targetElement,
            targetElementCounter,
            () => {
              callbackFileDelete(url, comp, propertyName, index, renderAllPages, currentForm, setStartSpinner);
            }
          );
          ++targetElementCounter;
      }
  });

  return targetElementCounter;
};

const processFileInstance = (isDownload, instance, currentForm, setStartSpinner, renderAllPages) => {
  if (!instance) {
      return;
  }
  let fileComponentArray = [];
  const isDataGridChildren = (Object.getPrototypeOf(instance).constructor.name !== 'FileComponent');
  const targetElement = (isDownload?
    HelperFileUploadRender_Get_FileComponentArray_And_TargetElements_Download(currentForm, isDataGridChildren, instance, fileComponentArray):
    HelperFileUploadRender_Get_FileComponentArray_And_TargetElements_Delete(currentForm, isDataGridChildren, instance, fileComponentArray));
  if(!targetElement)
    return;
  let targetElementCounter = 0;
  for (const comp of fileComponentArray) {
    if(isDownload)
      targetElementCounter = processFileComponent_Download(comp, targetElement, setStartSpinner, targetElementCounter);
    else
      targetElementCounter = processFileComponent_Delete(comp, targetElement, setStartSpinner, targetElementCounter, renderAllPages, currentForm);
  }
};

const HandleFileUploadRender_CustomDownloadButton = (renderAllPages, currentForm, setStartSpinner) => {
  if(!HelperFileUploadRender_IsInputValid(renderAllPages, currentForm))
    return;

  let selectComponents = (renderAllPages ? currentForm.components : currentForm.allPages[currentForm.page].components);
  let fileComponents = Utils.searchComponents(selectComponents, {
      type: "file"
  });

  // Fix 1a
 // if (fileComponents?.length === 0 && currentForm?.page === 4) {
    let isAdded = false;
    Utils.eachComponent(currentForm.components, (dataGrid) => {
      if (
        dataGrid.key === 'dataGridAttachmentOfSgBankOrFI' &&
        dataGrid.type === 'datagrid' &&
        dataGrid.components &&
        Array.isArray(dataGrid.components)
      ) {
        dataGrid.components.forEach((columnsComponent) => {
          if (!isAdded &&
            columnsComponent &&
            columnsComponent.components &&
            Array.isArray(columnsComponent.components)
          ) {
            const containsFiles = columnsComponent.components.some((component) => {
              return component.type === 'file';
            });
  
            if (containsFiles) {
              fileComponents.push(columnsComponent.components[1]);
              isAdded = true;
              return;
            }
          }
        });
      }
    }); 
    // ContainerFA3
    // let containerFA3Component =
    //   currentForm?.allPages?.[currentForm?.page]?.components[0];
      
    // // ContainerFA3->Datagrid
    //     let targetDatagrid;
    //     if(currentForm.schema.name.includes('ship')){
    //        targetDatagrid =
    //       containerFA3Component?.components[2]?.components[5]?.components[0]
    //           ?.components[1];    
    //     }
    //     else{
    //       targetDatagrid =
    //       containerFA3Component?.components[1]?.components[5]?.components[0]
    //           ?.components[1];  
    //     }

    // // We pushed 1 of the childComponent of datagrid, so in "processFileInstance()" logic will be able to know this is datagrid file related issue,
    // // hence will handle. Reason for all this trouble as you know datagrid fileComponent all have same id, hence all this trouble.
    // //-- List of childrens in this datagrid
    // //[0]. file component --> Dont push this, bcs in processFileInstance, if it detects fileComponent, it will treat it as non-datagrid->file.
    // //[1]. text component --> Push this
    // fileComponents.push(targetDatagrid?.components[0].components[1]);
 // }

  if(fileComponents?.length > 0){
    fileComponents.forEach(instance => {
      processFileInstance(true, instance, currentForm, setStartSpinner, renderAllPages);
    });
  }  
}

const HandleFileUploadRender_CustomDeleteButton = (renderAllPages, currentForm, setStartSpinner) => {
  if(!HelperFileUploadRender_IsInputValid(renderAllPages, currentForm)) {
    return;
  }

  let selectComponents = (renderAllPages ? currentForm.components : currentForm.allPages[currentForm.page].components);
  const fileComponents = Utils.searchComponents(selectComponents, {
      type: "file"
  });

  fileComponents.forEach(instance => {
    processFileInstance(false, instance, currentForm, setStartSpinner, renderAllPages);
  });
}
const executeFileUpload = (renderAllPages, currentForm, setStartSpinner, executions) => {
  if (executions >= MAX_EXECUTIONS) {
    return;
  }
  CommonHandleCustomFileUpload_Render(renderAllPages, currentForm, setStartSpinner);
  setTimeout(() => {
    executeFileUpload(renderAllPages, currentForm, setStartSpinner, executions + 1);
  }, EXECUTION_INTERVAL_MS);
};

const CommonHandleCustomFileUpload_Render = (renderAllPages, currentForm, setStartSpinner) => {
  HandleFileUploadRender_CustomDownloadButton(renderAllPages, currentForm, setStartSpinner);
  HandleFileUploadRender_CustomDeleteButton(renderAllPages, currentForm, setStartSpinner);
}

const GenericFileUploadOnRender = (renderAllPages, currentForm, setStartSpinner) => {
  if(currentForm) {
    executeFileUpload(renderAllPages, currentForm, setStartSpinner, 0);
  }
}

const GenericFileUploadOnChange = (changed, renderAllPages, currentForm, setStartSpinner) => {
  const condition1_checkIsFileType = (changed?.changed?.instance?.type === "file" || changed?.changed?.instance?.type === "datagrid");
  if(currentForm && condition1_checkIsFileType) {
    executeFileUpload(renderAllPages, currentForm, setStartSpinner, 0);
  }
}

const GenericHandleDefaultCountry_BorrowerParticulars = (form) => {
  // Skip index 0, subsequent index required to be default Singapore
  const dgBorrower = form.submission.data.containerParticulars.containerBorrower.dataGridBorrower ?? undefined; 
  if (dgBorrower && Array.isArray(dgBorrower)) {
    dgBorrower.forEach((element, index) => {
      if(index === 0) {
        return;
      }

      if (element?.containerCompanyRegisteredAddress) {
        element.containerCompanyRegisteredAddress.country = _.capitalize(WHT_CONSTANT.SINGAPORE);
      }
    });
  }
}

export {
  GenericFileUploadOnChange,
  GenericFileUploadOnRender,
  GenericHandleDefaultCountry_BorrowerParticulars,
  HelperFormatBaseUrl,
};