import { Form } from "@formio/react";
import { Breadcrumbs, Button, CircularProgress, Fab, Grid, Link, Paper, Typography, } from "@mui/material";
import { IconAlertCircle, IconArrowLeft, IconChevronLeft, IconChevronRight, } from "@tabler/icons";
import $ from "jquery";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { fetch } from "./API";
import pagination from "../Components/ApplicationForm/pagination";
import paginationCountersOnly from "../Components/ApplicationForm/paginationCountersOnly";
import StatusChip from "../Components/ApplicationForm/StatusChip";
import Formio from "formiojs/Formio";
import { sanitizeObject } from '../Common/CommonUtils';
import { config } from "../config";
import { ConsoleInfo } from "./Logger";

export const createOrSave = async (
        submitState,
        currentForm,
        alreadySubmitted,
        createdData,
        saveCreatedData,
        createURL,
        appFormBaseURL,
        formId
) => {
  if (currentForm?._submission) {
    if (submitState && currentForm._submission !== undefined) {
        currentForm._submission.state = submitState;
    }
    let response;
    currentForm._submission.data = sanitizeObject(currentForm._submission.data);
    currentForm.data = currentForm._submission.data;
    if (!createdData._id) {
      //create
      response = await fetch(createURL, "POST", currentForm._submission).then((response) => {
        if (response.status === 201 || response.status === 200) {
          createdData = response.data;
          saveCreatedData(createdData);
          return {
            status: response.status,
            message: createdData,
          };
        }
      }).catch((error) => {
        return {
          status: error.response.status,
          message: error.response.data,
        };
      });
    } else {
      //update
      const updateURL = `${appFormBaseURL}/${formId}/submission/${createdData._id}`;
      response = await fetch(updateURL, "PUT", currentForm._submission).then((response) => {
        if (response.status === 200) {
          const updatedData = response.data;
          return {
            status: response.status,
            message: updatedData,
          };
        }
      }).catch((error) => {
        return {
          status: error.response.status,
          message: error.response.data, //await response.json()
        };
      });
    }
    return response;
  }
};

export const onRenderGeneric = async (
        setPage,
        currentForm,
        alreadySubmitted,
        createdData,
        saveCreatedData,
        createURL,
        appFormBaseURL,
        formId,
        status
) => {
  Formio.setProjectUrl(
          "https://mpa-omp-forms.sit.activate.sg/authoring-fqcnwskygtheazb"
  );
  if (formId?.toLowerCase() === config.form_name_target_msi_main?.toLowerCase()) {
    paginationCountersOnly(currentForm);
  } else {
    pagination(currentForm);
  }

  if (currentForm?.page !== undefined) {
    setPage(currentForm.page);
  }
  if (alreadySubmitted) {
    $("[type=submit]").css({display: "none"});
  }
  $('button:contains("Back")').addClass("backbtn");
  if ($('button:contains("Back")').find("i.leftarrowiconblue").length === 0) {
    $('button:contains("Back")').prepend('<i class="leftarrowiconblue"></i>');
  }
  $('button:contains("Previous")').addClass("backbtn");
  if (
          $('button:contains("Previous")').find("i.leftarrowiconblue").length === 0
  ) {
    $('button:contains("Previous")').prepend(
            '<i class="leftarrowiconblue"></i>'
    );
  }
  if ($('button:contains("Next")').find("i.rightarrowicon").length === 0) {
    $('button:contains("Next")').append('<i class="rightarrowicon"></i>');
  }
  if ($('button:contains("Proceed")').find("i.rightarrowicon").length === 0) {
    $('button:contains("Proceed")').append('<i class="rightarrowicon"></i>');
  }
  $('button:contains("Save as Draft")').addClass("saveasdraftbtn");
  $('button:contains("Cancel")').addClass("cancelbtn");
  $('button:contains("Submit Form")').css({display: "none"});

  $('button:contains("Review")').parent().parent().addClass("btnAlignEnd");
  $('button:contains("Next")').parent().parent().addClass("btnAlignEnd");
  $('button:contains("Submit Form")').parent().parent().addClass("btnAlignEnd");

  $('.formio-component-applicantcomment').css({display: 'inline-block'});
  $('.formio-component-applicantcomment').prev().css({display: 'inline-block'});

  if (status === "Draft" || status === "draft" || status === "All" || status === "all" || status === null || status === undefined) {
    $('.formio-component-applicantcomment').hide();
  }

  return "ok"
};

export const onChangeGeneric = async (
        alreadySubmitted,
        setFormReadOnly,
        event
) => {
  if (alreadySubmitted === true) {
    setFormReadOnly(true);
  }
};

export const onSubmitGeneric = async (
        submission,
        currentForm,
        alreadySubmitted,
        createdData,
        saveCreatedData,
        createURL,
        appFormBaseURL,
        formId
) => {
  await createOrSave(
          null,
          currentForm,
          alreadySubmitted,
          createdData,
          saveCreatedData,
          createURL,
          appFormBaseURL,
          formId
  );

  if (submission.state === "submitted") {
    currentForm.emit("submitDone", currentForm._submission);
    $("[name='data[submit2]']").css({display: "none"}); //hide Save as Draft btn
  }
  if (submission.state === "draft") {
    $(".fa-spin").css({display: "none"}); //hide Save as Draft spinner
  }

  //history push to application listing page
};

export const onErrorGeneric = async (errors) => {
  // This function is intentionally empty.
};

export const onSubmitDoneGeneric = (submission) => {
  // This function is intentionally empty.
};

export const PageCarousel = ({
	thisform,
	setpage,
	pagesErrors,
	status,
	removeAssessmentClick = false,
}) => {
	const [counter, setCounter] = useState(0);
	let pages;
	const iconnpage = [];
	if (thisform && thisform.pages) {
		pages = thisform.pages.map((x) => x.component.title);
	}
	if (pages !== undefined) {
		for (let x = 0; x < pages.length; x++) {
			if (x === 0) {
				if (pagesErrors.length > 0 && pagesErrors[x] > 0) {
					iconnpage[x] = (
						<>
							<div className="infoiconactivepartial">
								<div className="infoiconactiveError" />
							</div>
							<div className="page-link">{pages[x]}</div>
						</>
					);
				} else {
					if (thisform.page === 0) {
						iconnpage[x] = (
							<>
								<div class="infoiconactive" />
								<div class="page-link">{pages[x]}</div>
							</>
						);
					} else {
						iconnpage[x] = (
							<>
								<div class="infoiconinactive" />
								<div class="page-link">{pages[x]}</div>
							</>
						);
					}
				}
			} else {
				if (pagesErrors.length > 0) {
					// icons change to those showing error status
					if (pagesErrors[x] > 0) {
						iconnpage[x] = (
							<>
								<div class="partialicon">{x}</div>
								{/* <div class="page-link" onClick={() => setpage(x)}> */}
								<div
									class="page-link"
									// onClick={status !== "Returned" ? null : null}
								>
									{pages[x]}
								</div>
							</>
						);
					}
					if (pagesErrors[x] === 0) {
						iconnpage[x] = (
							<>
								<div class="tickicon" />
								{/* <div class="page-link" onClick={() => setpage(x)}> */}
								<div
									class="page-link"
									// onClick={status !== "Returned" ? null : null}
								>
									{pages[x]}
								</div>
							</>
						);
					}
				} else {
					if (x < thisform.page) {
						iconnpage[x] = (
							<>
								<div class="emptyicon">{x}</div>
								{/* <div class="page-link" onClick={() => setpage(x)}> */}
								<div
									class="page-link"
									// onClick={status !== "Returned" ? null : null}
								>
									{pages[x]}
								</div>
							</>
						);
					}
					if (x === thisform.page) {
						iconnpage[x] = (
							<>
								<div class="currenticon">{x}</div>
								<div class="page-link">{pages[x]}</div>
							</>
						);
					}
					if (x > thisform.page) {
						iconnpage[x] = (
							<>
								<div class="emptyicon">{x}</div>
								<div
									class="page-link"
									// onClick={thisform.page !== 0 && status !== "Returned" ? null : null}
								>
									{pages[x]}
								</div>
							</>
						);
					}
				}
			}
		}
	}

	function incrementCounter(e, x) {
		e.preventDefault();
		let newCounter = counter + x;
		if (newCounter >= 0 && newCounter < thisform?.pages?.length) {
			setCounter(counter + x);
		}
	}

	ConsoleInfo("rendering Page Navigation", iconnpage);
	return (
		<Paper>
			<Grid container>
				<Grid
					container
					item
					xs={10.5}
					sx={{
						flexDirection: "column",
						height: "98px",
						overflow: "clip",
						justifyContent: "center",
						display: "flex",
						"-webkit-mask-image":
							"-webkit-linear-gradient(left, rgba(0,0,0,1) 90%, rgba(0,0,0,0))",
						alignContent: "baseline",
					}}>
					{iconnpage.map((x, index) => {
						if (index >= counter) {
							return (
								<Grid
									item
									key={index}
									sx={{
										display: "flex",
										ml: 3,
										mr: 1.8,
										alignItems: "center",
									}}>
									{x}
								</Grid>
							);
						} else
							return (
								<Grid
									item
									key={index}
									sx={{
										display: "none",
										ml: 3,
										mr: 1.8,
										alignItems: "center",
									}}>
									{x}
								</Grid>
							);
					})}
				</Grid>
				<Grid
					item
					xs={1.5}
					sx={{ display: "flex", alignItems: "center" }}>
					<Button
						variant="outlined"
						sx={{
							height: "40px",
							minWidth: "40px",
							borderRadius: "50%",
							border: "1px solid #eaecf0",
							color: "#333",
							padding: 0,
							mr: 1,
						}}
						onClick={(e) => incrementCounter(e, -1)}>
						<IconChevronLeft />
					</Button>
					<Button
						variant="outlined"
						sx={{
							height: "40px",
							minWidth: "40px",
							borderRadius: "50%",
							border: "1px solid #eaecf0",
							color: "#333",
							padding: 0,
						}}
						onClick={(e) => incrementCounter(e, 1)}>
						<IconChevronRight />
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

export const PageCarouselCountersOnly = (
        thisform,
        setpage,
        pagesErrors,
        ignorePage = -1,
        removeAssessmentClick = false
) => {
  const [counter, setCounter] = useState(0);
  let pages;
  let pagesFilteredOut = 0;
  const iconnpage = [];

  if (ignorePage !== -1) pagesFilteredOut = 1;
  if (thisform && thisform.pages) {
    pages = thisform.pages.filter((x, i) => i !== ignorePage).map((x) => x.component.title);
  }
  if (pages !== undefined) {
    for (let x = 0; x < pages.length; x++) {
      if (pagesErrors.length > 0) {
        // icons change to those showing error status
        if (pagesErrors[x + pagesFilteredOut] > 0) {
          iconnpage[x] = (
                  <>
                    <div class="partialicon">{x + 1}</div>
                    <div class="page-link" onClick={() => setpage(x + pagesFilteredOut)}>
                      {pages[x]}
                    </div>
                  </>
          );
        }
        if (pagesErrors[x + pagesFilteredOut] === 0) {
          iconnpage[x] = (
                  <>
                    <div class="tickicon"/>
                    <div class="page-link" onClick={() => setpage(x + pagesFilteredOut)}>
                      {pages[x]}
                    </div>
                  </>
          );
        }
      } else {
        if (x < thisform.page - pagesFilteredOut) {
          iconnpage[x] = (
                  <>
                    <div class="emptyicon">{x + 1}</div>
                    <div class="page-link" onClick={() => setpage(x + pagesFilteredOut)}>
                      {pages[x]}
                    </div>
                  </>
          );
        }
        if (x === thisform.page - pagesFilteredOut) {
          iconnpage[x] = (
                  <>
                    <div class="currenticon">{x + 1}</div>
                    <div class="page-link">{pages[x]}</div>
                  </>
          );
        }
        if (x > thisform.page - pagesFilteredOut) {
          iconnpage[x] = (
                  <>
                    <div class="emptyicon">{x + 1}</div>
                    <div class="page-link" onClick={() => setpage(x + pagesFilteredOut)}>
                      {pages[x]}
                    </div>
                  </>
          );
        }
      }
    }
  }

  function incrementCounter(e, x) {
    e.preventDefault();
    let newCounter = counter + x;
    if (newCounter > -1 && newCounter < thisform?.pages?.length - pagesFilteredOut) {
      setCounter(counter + x);
    }
  }

  return (
          <Paper>
            <Grid container>
              <Grid
                      container
                      item
                      xs={10.5}
                      sx={{
                        flexDirection: "column",
                        height: "98px",
                        overflow: "clip",
                        justifyContent: "center",
                        display: "flex",
                        "-webkit-mask-image":
                                "-webkit-linear-gradient(left, rgba(0,0,0,1) 90%, rgba(0,0,0,0))",
                        alignContent: "baseline",
                      }}
              >
                {iconnpage.map((x, index) => {
                  if (index >= counter) {
                    return (
                            <Grid
                                    item
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      ml: 3,
                                      mr: 1.8,
                                      alignItems: "center",
                                    }}
                            >
                              {x}
                            </Grid>
                    );
                  } else return null;
                })}
              </Grid>
              <Grid item xs={1.5} sx={{display: "flex", alignItems: "center"}}>
                <Button
                        variant="outlined"
                        sx={{
                          height: "40px",
                          minWidth: "40px",
                          borderRadius: "50%",
                          border: "1px solid #eaecf0",
                          color: "#333",
                          padding: 0,
                          mr: 1,
                        }}
                        onClick={(e) => incrementCounter(e, -1)}
                >
                  <IconChevronLeft/>
                </Button>
                <Button
                        variant="outlined"
                        sx={{
                          height: "40px",
                          minWidth: "40px",
                          borderRadius: "50%",
                          border: "1px solid #eaecf0",
                          color: "#333",
                          padding: 0,
                        }}
                        onClick={(e) => incrementCounter(e, 1)}
                >
                  <IconChevronRight/>
                </Button>
              </Grid>
            </Grid>
          </Paper>
  );
};

export const FormHtmlHead = ({formname, schema}) => {
  const SchemaLink = (schema) => {
    const history = useHistory();
    switch (schema) {
      case "Training":
      case "MINT":
      case "IDP":
        return (
                <Link
                        underline="hover" sx={{cursor: 'pointer'}}
                        onClick={() => history.push("/Grants")}
                        className="univers-65-bold-scarpa-flow-12px"
                >
                  Grants
                </Link>
        );
      case "MSI-AIS":
      case "MSI-ML":
      case "MSI-SSS":
      case "WHT_Ship":
      case "WHT_Container":
        return (
                <Link
                        underline='hover' sx={{cursor: 'pointer'}}
                        m={1}
                        onClick={() => history.push("/Incentives")}
                        className="univers-65-bold-scarpa-flow-12px"
                >
                  Incentives
                </Link>
        );
      default:
        return null;
    }
  };

  return (

          <><Button href="/Dashboard" s>
            <IconArrowLeft color="#666"/>

          </Button><Breadcrumbs
                  sx={{display: "inline-block", verticalAlign: "middle"}}

                  aria-label="breadcrumb"
          >
            <Link
                    m={1}
                    underline='hover' sx={{cursor: 'pointer'}}
                    className="univers-65-bold-scarpa-flow-12px"
                    href="/Dashboard"
            >
              Dashboard
            </Link>
            {SchemaLink(schema)}
            <Link
                    ml={1}
                    underline="hover"
                    className="univers-65-bold-scarpa-flow-12px"
            >
              <span className="surname-1">{formname}</span>
            </Link>
          </Breadcrumbs><br/><br/></>

  );
};

export const FormHtmlTitle = ({formname, formid, formstatus, caseID}) => {
  return (
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h2">{formname}</Typography>
              {/* <Typography className="univers-55roman-normal-mine-shaft-22px">
          {formid}
        </Typography>
        {caseID} <StatusChip type={formstatus} /> */}
              {caseID !== null ?
                      <Typography className="univers-55roman-normal-mine-shaft-22px">

                        {/* {formid} <StatusChip type={formstatus} /> */}
                        {caseID} <StatusChip type={formstatus}/>

                      </Typography>
                      :
                      <></>}
            </Grid>
            {/* <Grid item xs={1} sx={{textAlign: 'end'}}>
        <Fab className="form-option-button">
          <IconDots />
        </Fab>
      </Grid> */}
          </Grid>
  );
};

export const FormHtmlErrors = ({thisForm, pagesErrors, currentPageErrors}) => {
  if (thisForm) {
    if (currentPageErrors > 0) {
      return (
              <Paper
                      sx={{
                        py: 2.5,
                        px: 5,
                        my: 3,
                        display: "flex",
                        color: "var(--rose)",
                        fontSize: 17,
                        fontFamily: "'Univers-55Roman', Helvetica",
                        alignItems: "center",
                      }}
              >
                <Fab
                        aria-label="error-alert"
                        size="small"
                        sx={{
                          backgroundColor: "error.dark",
                          color: "white",
                          boxShadow: "none",
                        }}
                >
                  <IconAlertCircle/>
                </Fab>
                &nbsp;&nbsp;Oops! <strong>&nbsp;{pagesErrors[thisForm.page]}&nbsp;</strong> error(s) in
                this section.
              </Paper>
      );
    }
  }
};

export const FormHtmlForm = ({
                               fetchingform,
                               formReadOnly,
                               props,
                               appFormURL,
                               onChange,
                               onError,
                               formReady,
                               onSubmit,
                               onSubmitDone,
                               onRender,
                               onCustomEvent,
                               isBusy,
                               appFormJson,
                               options = {}
                             }) => {
  // beginning of HW's change
  Formio.clearCache();
  const formProps = {
    form: appFormURL,
    url: appFormURL,
    onChange,
    onError,
    formReady,
    onSubmit,
    onSubmitDone,
    onRender,
    onCustomEvent,
    options
  };

  if (appFormJson.current && appFormJson.current.data) {
    formProps.submission = appFormJson.current
  }


  return (
          <Paper sx={{py: 2.5, px: 5, my: 3}}>
            {fetchingform ? (
                    <>
                      <CircularProgress/> Form is loading
                    </>
            ) : null}
            {isBusy
                    ? null
                    : appFormJson && (
                    <div style={{display: !formReadOnly ? "block" : "none"}}>
                      <Form
                              {...props}
                              {...formProps}
                      />
                    </div>
            )}
          </Paper>
  );
};

// end of  HW's change

export function FormHtmlForm2(props) {
  return (
          <Paper sx={{py: 2.5, px: 5, my: 3}}>
            {props.fetchingform ? (
                    <>
                      <CircularProgress/> Form is loading
                    </>
            ) : null}


            {props.isBusy
                    ? null
                    : props.appFormJson && (
                    <div style={{display: !props.formReadOnly ? "block" : "none"}}>
                      <Form {...props} />
                    </div>
            )}
          </Paper>
  );
}
