import { Container } from "@mui/material";
import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
	createOrSave,
	FormHtmlErrors,
	FormHtmlForm,
	FormHtmlHead,
	FormHtmlTitle,
	onChangeGeneric,
	onErrorGeneric,
	onRenderGeneric,
	onSubmitDoneGeneric,
	onSubmitGeneric,
	PageCarousel,
} from "../../../Common/ApplicationForm";
import { get_WHT_SRS_Info } from "../../../Common/SRS";
import { config } from "../../../config";
import "../../ApplicationForm/ApplicationForm.css";
import { useHistory, useLocation } from "react-router-dom";
import ValidationDialog from "../../../Common/ValidationDialog";
import { fetch } from "../../../Common/API";
import loginHelper from "../../../Common/loginHelper";
import VesselDialog, { VesselDialogType } from "./VesselDialog";
import DateValidationDialog from "./DateValidationDialog";
import moment from "moment";
import Utils from "formiojs/utils";
import { deleteFormAPIAsync } from "../../../Redux/CommonForm/CommonFormSlice.js.js";
import SnackbarAlert from "../../../Common/snackbarAlert";
import { ConsoleError, ConsoleInfo, ConsoleLog } from "../../../Common/Logger";
import ProgressBar from "../../../Common/progressBar";
import AutoSaveErrorDialog from "../../../Common/autoSaveError";
import getTitle from "../../../Common/FormIOComponent/titleComponent";
import getStatusText from "../../../Common/GetStatusText";
import {
	CommonHandleAllDropdownListMetadataFixV1,
	CommonHandleFlagOfRegistryMetadataFix,
	CommonHandleWHTShipDate,
	CommonPopulateQuestionEDH,
	CommonUpdateValidation_NonQualifyingAmountFinancedCost,
	CommonUpdateValidation_QualifyingPayment,
	CommonUpdateValidation_TotalNonQualifyingAmountFinanced,
	CommonUpdateValidation_TypeOfFinancingArrangementObtained,
	CommonVesselDatagrid_GetComponent_ClearFields,
	CommonVesselDatagrid_GetComponent_Index,
	CommonVesselDatagrid_GetComponent_Update,
	CommonVesselDatagrid_GetDuplicatedComponent_Index,
	CommonVesselDatagrid_IsImoNumber_Valid,
	DateTransferCertShip,
	TriggerEvent_DownloadVesselUploadSample,
	CommonToggleFileUploadVessels,
} from "../../../Common/GenericWHTShip";
import { SwapsCheckbox } from "../../../Common/SwapsCheckboxValidation";
import {
	ComponentFieldType,
	ComponentUpdateType,
	ImoType,
	SIMULATE_DUMMY_DATA_SRS_API,
	VesselDetailsStatus,
	UploadVesselCheckboxType,
	WHT_CONSTANT,
} from "../../../Common/CommonEnumTypes";
import {
	GenericFileUploadOnRender,
	GenericFileUploadOnChange,
} from "../../../Common/GenericForm";
import UploadVesselsDialog from "./UploadVesselsDialog";
import { ScheduleCallbackAfterDocumentReadyWithDelay } from '../../../Common/CommonUtils';

WHTShipForm.defaultProps = {
	formname: "Self-Declaration Form for Withholding Tax Exemption (Ship)",
	formstatus: "New",
	formId: config.form_name_target_ship_main,
	appFormBaseURL: `${config.appFormBaseURL}`,
	schema: "WHT_Ship",
};

export default function WHTShipForm({
	formname,
	formid,
	formstatus,
	formId,
	appFormBaseURL,
	schema,
	props,
}) {
	//-- useState variable(s)
	const [page, setPage] = useState(0); // Usage: Inform which tab are you in WHT_SHIP
	const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false); // Usage: Trigger open error dialog box
	const [isBusy, setBusy] = useState(true); // Usage: Busy = true means form is loading, dont render form yet
	const appFormJson = useRef(); // Usage: Load form using json files, for now we using load form using url
	const [openValidationDialog, setOpenValidationDialog] = useState(false); // Usage: When we click review button, we need validate dialog to validate everything, this flag will trigger whether to open validation dialog or not
	const [disableScrollingFunc, setScrollState] = useState(() => {
		// This method is intentionally empty.
	}); // Usage: Scroll to top when you press next button
	const [openAlert, setOpenAlert] = useState();
	const [open, setOpen] = useState(false);

	const [openDialog, setOpenDialog] = useState(false);
	const [openDateValidDialog, setOpenDateValidDialog] = useState(false);
	const [dialogMessageType, setDialogMessageType] = useState(
		VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER,
	);

	const [openUploadVesselsDialog, setOpenUploadVesselsDialog] = useState(false);
	const [uploadVesselsType, setUploadVesselsType] = useState();
	const vesselUploadTemplateRef = useRef();

	const [startSpinner, setStartSpinner] = useState(false);
	const [bar, setBar] = useState(false);
	const ref = useRef();

	//-- helper variable(s)
	let allowScrolling = true;
	let validityTriggered = false;

	//-- Debugger variables
	let showDebug = 0; // 0 to not show debug, 1 to show bernadette debug, 2 to show joel debug, 3 to show jaya debug

	let srsByIMO = {};

	// -- Dispatch
	const dispatch = useDispatch();

	//-- Navigation variable(s)
	const history = useHistory();
	const params = new URLSearchParams(window.location.search);
	let _id = params.get("_id"); // Usage: id of this form
	const theformId = _id;
	const selected = params.get("selected"); // Usage: In WHT_EntityChecker Form, user selected their entity type. We use 'selected' to get which entity type they selected.
	let createdData = { _id: _id };
	let getStatus = params.get("status");
	const [status, setStatus] = useState();
	let prevStatus;
	let reviewCaseID;

	//-- Pointing to the right id
	_id = !_id ? "" : "submission/" + _id;

	//-- Form instance and form url macros
	let appFormUrl2 = `${appFormBaseURL}/${formId}`;
	let appFormURL = `${appFormBaseURL}/${formId}/${_id}`;
	let createURL = `${appFormBaseURL}/${formId}/submission`;
	let currentForm = {};

	//-- Custom event 1: When vessel name have been modified and user press auto-fill in vessel details using vessel name,
	//                   it will call backend API and fetch the vessel details.
	let whtExemption_startDate = "";
	let whtExemption_endDate = "";

	//-- More useState(s) for Form
	const [thisForm, setThisForm] = useState();
	const [formReadOnly, setFormReadOnly] = useState(false);
	const [fetchingform, setFetchingform] = useState(true);
	const [pagesErrors, setPagesErrors] = useState([]);
	const [currentPageErrors, setCurrentPageErrors] = useState(0);

	const location = useLocation();
	const getCaseID = location?.state?.caseID;
	const [caseID, setCaseID] = useState(getCaseID);

	//-- Submission of form
	let alreadySubmitted = false;
	const submitID_Ref = useRef("");
	const form_ref = useRef({});
	const currentFormref = useRef({});
	let vesselHideList = [];
	let canSubmit = true;

	let reloadStrictMode = false;

	const checkErrorStatusAndOpenErrorStatusDialog = () => {
		if (sessionStorage.getItem("error") !== "409") {
			setOpenErrorStatusDialog(true);
		}
	};

	// ConsoleLog("caseIDShipForm", caseID)

	useEffect(() => {
		async function getform() {
			setBusy(true);
			let json = await fetch(appFormURL, "GET", null);
			if (json) {
				if (reloadStrictMode) {
					reloadStrictMode = false;
				} else {
					setBusy(false);

					if (getStatus === "All") {
						setStatus("draft");
						prevStatus = "draft";
					} else if (json.data.data) {
						const currentStatus =
							json.data.data.configContainer.publicApplicationStatus;
						const getText = getStatusText(currentStatus);
						setStatus(getText);
						prevStatus = getText;
					} else {
						setStatus("draft");
						prevStatus = "draft";
					}

					try {
						appFormJson.current = json.data;
					} catch (e) {}
				}
			}
		}
		getform();

		sessionStorage.setItem("submissionId", theformId);

		return () => (reloadStrictMode = true);
	}, []);

	useEffect(() => {
		// Create a new interval, and also run it every 'n' seconds
		let intervalInstance = setInterval(async function () {
			const entityTypeUseEffect =
				currentFormref?.current?.submission?.data?.containerParticulars
					?.containerBorrower?.entityType;

			// form_ref.current
			if (currentFormref.current) {
				CommonHandleAllDropdownListMetadataFixV1(
					currentFormref.current,
					entityTypeUseEffect,
				);

				await createOrSave(
					"draft",
					currentFormref.current,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);
			}
		}, 60000);

		return () => {
			clearInterval(intervalInstance);
		};
	}, []);

	function saveCreatedData(x) {
		createdData = x;
	}

	let allTitle;

	const formReady = async (form) => {
		$(async function () {
			setFetchingform(false);
			currentForm = form;
			currentForm.nosubmit = true;
			setThisForm(currentForm);
			const getAllTitle = getTitle(currentForm, theformId, "WHTSMainForm");
			allTitle = getAllTitle;
			if (appFormJson.current.data) {
				currentForm.data = appFormJson.current.data;
				let borrowersDetail = Utils.searchComponents(currentForm.components, {
					type: "textfield",
				});
				borrowersDetail.forEach((borrower) => {
					if (borrower.key === "borrowerName") borrower.disabled = false;
					else if (borrower.key === "uniqueEntityNumber")
						borrower.disabled = false;
					else if (borrower.key === "country") borrower.disabled = false;
					else if (borrower.key === "postalCode") borrower.disabled = false;
					else if (borrower.key === "block") borrower.disabled = false;
					else if (borrower.key === "street") borrower.disabled = false;
					else if (borrower.key === "floor") borrower.disabled = false;
					else if (borrower.key === "unit") borrower.disabled = false;
					else if (borrower.key === "buildingName") borrower.disabled = false;
				});

				whtExemption_startDate =
					currentForm.data.containerQualifyingDetails.containerQualifyingPeriod
						.effectiveDateOfWHTExemption;
				whtExemption_endDate =
					currentForm.data.containerQualifyingDetails.containerQualifyingPeriod
						.endDate;

				//Swaps checkbox validation
				SwapsCheckbox(currentForm);
			}
			if (getStatus === "All") {
				setStatus("draft");
				prevStatus = "draft";
			} else if (
				currentForm.submission.data.configContainer.publicApplicationStatus
			) {
				const statusTextCurrent = getStatusText(
					currentForm.submission.data.configContainer.publicApplicationStatus,
				);
				setStatus(statusTextCurrent);
				prevStatus = statusTextCurrent;
			} else {
				setStatus("draft");
				prevStatus = "draft";
			}

			if (
				currentForm.submission.data.configContainer.publicApplicationStatus !==
					1 &&
				currentForm.submission.data.configContainer.publicApplicationStatus !==
					9 &&
				currentForm.submission.data.configContainer.applicationStatus !== "1" &&
				currentForm.submission.data.configContainer.applicationStatus !== "9"
			) {
				canSubmit = false;
				submitID_Ref.current = createdData._id;
				form_ref.current = currentForm;
				goToReview();
			}

			// Applicant information
			let applicant = loginHelper.getLoginUser();
			if (
				applicant?.Name !== currentForm.submission.data["contactName"] ||
				currentForm.submission.data["contactName"] === ""
			) {
				currentForm.submission.data.configContainer["usersInvolved"] =
					applicant?.Name;
				currentForm.submission.data.configContainer["companyInvolved"] =
					applicant?.CompanyName;
				currentForm.submission.data["contactName"] = applicant?.Name;
				currentForm.submission.data["contactEmail"] = applicant?.Email;
			}

			window.sessionStorage.setItem("Started", JSON.stringify("true"));

			CommonPopulateQuestionEDH(currentForm, selected, applicant.UEN);
			const myInterval = setInterval(function () {
				if (JSON.parse(window.sessionStorage.getItem("Started")) === "false") {
					clearInterval(myInterval);
					setStartSpinner(false);
					const borrowerName = currentForm.getComponent("borrowerName");
					const uniqueEntityNumber =
						currentForm.getComponent("uniqueEntityNumber");
					const country = currentForm.getComponent("country");
					const postalCode = currentForm.getComponent("postalCode");
					const block = currentForm.getComponent("block");
					const street = currentForm.getComponent("street");
					const floor = currentForm.getComponent("floor");
					const unit = currentForm.getComponent("unit");
					const buildingName = currentForm.getComponent("buildingName");
					borrowerName.redraw();
					uniqueEntityNumber.redraw();
					country.redraw();
					postalCode.redraw();
					block.redraw();
					street.redraw();
					floor.redraw();
					unit.redraw();
					buildingName.redraw();
				}
			}, 1000);
			populateDeclarationAndUndertaking();

			if (createdData._id) {
				submitID_Ref.current = createdData._id;
			}

			//ref="datagrid-dataGridVessel1-addRow"
			//  Change Data Grid Add Button
			Utils.searchComponents(form.components, { type: "datagrid" }).forEach(
				(instance) => {
					if (instance?.component) {
						instance.component.addAnother = "Add On";
					}
				},
			);

			// Event Listener: Execute this event inside when you change something in a component
			currentForm.on("change", (changed) => {
				if (changed.changed?.component.key === "effectiveDateOfWHTExemption") {
					whtExemption_startDate = changed.changed.value;
					populateDeclarationAndUndertaking();
				} else if (changed.changed?.component.key === "periodOfFinancingTo") {
					whtExemption_endDate = changed.changed.value;
					populateDeclarationAndUndertaking();
				} else if (changed.changed?.component.key === "endDate") {
					whtExemption_endDate = changed.changed.value;
					populateDeclarationAndUndertaking();
				}

				//--Event: Input changed for Flag of Registry (in SHIP form)
				else if (changed.changed?.component.key === "countries") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.FIELD_OF_REGISTRY,
					);

					if (foundIndex !== -1) {
						// Reset fields
						if (
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.countries?.toLowerCase() !== WHT_CONSTANT.SINGAPORE
						) {
							CommonVesselDatagrid_GetComponent_ClearFields(
								foundIndex,
								currentForm,
								false,
							);
						}

						// Reset status
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.statusVesselDetail = VesselDetailsStatus.EMPTY;

						// Apply changes to metadata
						CommonHandleFlagOfRegistryMetadataFix(currentForm, foundIndex);

						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//--Event: Input changed for Borrower DataGrid (in SHIP form)
				else if (changed.changed?.component.key === "dataGridBorrower") {
					// Hide the css of datagridBorrower first delete btn
					removeBorrowerFirstDeleteButton();
				}

				//-- Event: Checkbox changed for IMO  (in SHIP form)
				else if (changed.changed?.component.key === "cbIMO") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.CHECKBOX_NO_IMO,
					);

					// If we managed to get a index of the dataGrid element we modify, we do the following
					if (foundIndex !== -1) {
						// Reset fields
						CommonVesselDatagrid_GetComponent_ClearFields(
							foundIndex,
							currentForm,
							false,
						);
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.CHECKED_NO_IMO_NUMBER;

						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//-- Event: Input changed for IMO number (in SHIP form)
				else if (changed.changed?.component.key === "imoNumber") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.IMO_NUMBER,
					);

					// Hide component->"Date of Registration in Singapore Registry of Ships" and clear partial fields according to the requirements
					if (foundIndex !== -1) {
						let imoNo =
							currentForm.submission.data.containerParticulars
								.containerShipVessel.dataGridVessel1[foundIndex]
								.containerDataGrid.imoNumber;

						if (CommonVesselDatagrid_IsImoNumber_Valid(imoNo) === false) {
							// Reset partial fields to empty
							CommonVesselDatagrid_GetComponent_ClearFields(
								foundIndex,
								currentForm,
								false,
							);
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.statusVesselDetail =
								VesselDetailsStatus.EMPTY;

							// Update formIO side to trigger change event and redraw ui event
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance._data,
								currentForm,
								ComponentFieldType.NORMAL_COLUMN_TOP,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance._data,
								currentForm,
								ComponentFieldType.NORMAL_COLUMN_BOTTOM,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
						} else {
							let duplicatedIndex =
								CommonVesselDatagrid_GetDuplicatedComponent_Index(
									changed.changed.instance._data,
									currentForm,
									ImoType.NORMAL,
								);

							if (duplicatedIndex !== -1) {
								// Popout dialogue to show duplicated
								toggleVesselDialog(
									true,
									VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER,
								);

								// Reset partial fields to empty
								CommonVesselDatagrid_GetComponent_ClearFields(
									foundIndex,
									currentForm,
									false,
								);
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.imoNumber = "";
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.statusVesselDetail =
									VesselDetailsStatus.EMPTY;

								// Update formIO side to trigger change event and redraw ui event
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance._data,
									currentForm,
									ComponentFieldType.NORMAL_COLUMN_TOP,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance._data,
									currentForm,
									ComponentFieldType.NORMAL_COLUMN_BOTTOM,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
							}
						}
					}
				}

				//-- Event: Input changed for Date of Transfer cert (in SHIP form)
				else if (changed.changed?.component.key === "dateOfTransferCert") {
					if (
						currentForm.submission.data.containerNonTax.containerLender
							.dateOfTransferCert === "" ||
						currentForm.submission.data.containerNonTax.containerLender
							.dateOfTransferCert === null
					) {
						currentForm.submission.data.containerNonTax.containerLender.totalAmountTransferred =
							"";

						const totalAmountTransferredComp = currentForm.getComponent(
							"totalAmountTransferred",
						);
						if (totalAmountTransferredComp) {
							totalAmountTransferredComp.triggerChange();
							totalAmountTransferredComp.triggerRedraw();
						}
					}
				}

				//-- Event: Input changed for Date of Signed Agreement
				else if (
					changed.changed?.component.key ===
					"dateOfSignedAgreementTransferCertificate"
				) {
					//[1] Period Of Financing From
					const periodOfFinancingFromComp = currentForm.getComponent(
						"periodOfFinancingFrom",
					);
					let datePeriod = moment(
						currentForm.submission.data.containerNonTax.containerLender
							.periodOfFinancingFrom ?? 0,
					);
					let datePeriodMinDate = moment(
						periodOfFinancingFromComp.component.datePicker.minDate ?? 0,
					);

					// Handle reset period of financing from
					if (datePeriodMinDate !== 0 && datePeriod !== 0) {
						if (datePeriod < datePeriodMinDate) {
							currentForm.submission.data.containerNonTax.containerLender.periodOfFinancingFrom =
								null;
							if (periodOfFinancingFromComp) {
								periodOfFinancingFromComp.triggerChange();
								periodOfFinancingFromComp.triggerRedraw();
							}
						}
					}

					DateTransferCertShip(currentForm);

					//[2] Date of Earilest Qualifying Payment
					const dateEarliestQualifyingPaymentComp = currentForm.getComponent(
						"dateEarliestQualifyingPayment",
					);
					let dateEarliestQualifying = moment(
						currentForm.data.containerQualifyingDetails
							.containerQualifyingPayment.dateEarliestQualifyingPayment ?? 0,
					);
					let datedateEarliestQualifyingMinDate = moment(
						dateEarliestQualifyingPaymentComp.component.datePicker.minDate ?? 0,
					);

					// Handle reset period of financing from
					if (
						datedateEarliestQualifyingMinDate !== 0 &&
						dateEarliestQualifying !== 0
					) {
						if (dateEarliestQualifying < datedateEarliestQualifyingMinDate) {
							currentForm.submission.data.containerQualifyingDetails.containerQualifyingPayment.dateEarliestQualifyingPayment =
								null;
							dateEarliestQualifyingPaymentComp.triggerChange();
							dateEarliestQualifyingPaymentComp.triggerRedraw();
						}
					}
				} else if (
					changed.changed?.component.key === "totalAmountFinanced" ||
					changed.changed?.component.key === "totalAmountTransferred"
				) {
					CommonUpdateValidation_TotalNonQualifyingAmountFinanced(currentForm);
				} else if (
					changed.changed?.component.key ===
						"totalNonQualifyingInterestPaymentCost" ||
					changed.changed?.component.key ===
						"totalNonQualifyingRelatedPaymentsCost"
				) {
					CommonUpdateValidation_NonQualifyingAmountFinancedCost(currentForm);
				} else if (
					changed.changed?.component.key === "selectBoxesQFA_1" ||
					changed.changed?.component.key === "selectBoxesQFA_2" ||
					changed.changed?.component.key === "selectBoxesQFA_3"
				) {
					CommonUpdateValidation_TypeOfFinancingArrangementObtained(
						currentForm,
					);
				} else if (
					changed.changed?.component.key === "checkboxMoreThanFiftyVessels"
				) {
					handleCheckboxUploadVesselsToggle();
				}
				CommonHandleWHTShipDate(currentForm, changed);
				GenericFileUploadOnChange(changed, false, currentForm, setStartSpinner);
			});

			//-- Event: Press prev page button, create/overwrite current form and navigate to prev page
			currentForm.on("prevPage", async () => {
				setOpenAlert();
				setStartSpinner(true);
				// Create/Overwrite current form data
				const responseStatus = await createOrSave(
					"draft",
					currentForm,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);

				if (responseStatus.status === 200 || responseStatus.status === 201) {
					setStartSpinner(false);
					// Navigate to prev page of current form
					currentForm.setPage(currentForm.page - 1);
				} else {
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}
			});

			//--Event: Fetch data from backend -> SRS
			currentForm.on("SearchByIMONumberEvent", async (e) => {
				/* Read this first: what is inside (e)?
          Object: {
              cbIMO: false,
              certNumContainer: {certificationNumber: '', certNum-search: false},
              countries: "Singapore",
              dateOfRegistration: "",
              deliveryDate3: "",
              grossTonnage: "",
              imoNumber: "",
              netTonnage: "",
              search: true,
              vesselName: "",
              [[Prototype]] }
        */
				// [Valid]: imoNumber (Client-side validation)
				if (CommonVesselDatagrid_IsImoNumber_Valid(e.imoNumber)) {
					// Fetch data from API
					retrieveSGVesselDetailsFromSRS(e);
				}
				// [Invalid]: imoNumber (Client-side validation)
				else {
					// Show feedback dialogue
					toggleVesselDialog(true, VesselDialogType.ERROR_INVALID_IMO_NUMBER);

					// Clear selected imo number
					let selectedDgIndex = CommonVesselDatagrid_GetComponent_Index(
						e,
						currentForm,
						ComponentFieldType.IMO_NUMBER,
					);
					CommonVesselDatagrid_GetComponent_ClearFields(
						selectedDgIndex,
						currentForm,
						false,
					);

					// Update UI
					CommonVesselDatagrid_GetComponent_Update(
						e,
						currentForm,
						ComponentFieldType.NORMAL_COLUMN_TOP,
						ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
					);
					CommonVesselDatagrid_GetComponent_Update(
						e,
						currentForm,
						ComponentFieldType.NORMAL_COLUMN_BOTTOM,
						ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
					);
				}
			});

			//-- Event: Press prev page button, create/overwrite current form and navigate to next page
			currentForm.on("nextPage", async () => {
				$('button:contains("Next")').hide();

				setOpenAlert();
				setStartSpinner(true);
				// Create/Overwrite current form data
				const responseStatus = await createOrSave(
					"draft",
					currentForm,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);

				// Only if ^ action is successful
				if (responseStatus.status === 200 || responseStatus.status === 201) {
					$('button:contains("Next")').show();

					if (
						JSON.parse(window.sessionStorage.getItem("Started")) === "false"
					) {
						setStartSpinner(false);
					}

					// Navigate to next page of current form
					currentForm.setPage(currentForm.page + 1);
					if (responseStatus.message.data.configContainer.applicationCaseId) {
						setCaseID(
							responseStatus.message.data.configContainer.applicationCaseId,
						);
						reviewCaseID =
							responseStatus.message.data.configContainer.applicationCaseId;
					}

					if (createdData._id) {
						submitID_Ref.current = createdData._id;
						currentForm.submission.data["mainFormId"] = createdData._id;
					}
				} else {
					$('button:contains("Next")').show();

					if (
						JSON.parse(window.sessionStorage.getItem("Started")) === "false"
					) {
						setStartSpinner(false);
					}

					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}
			});

			currentForm.on("draftSave", async () => {
				const entityTypeDraftSave =
					currentForm?.submission?.data?.containerParticulars?.containerBorrower
						?.entityType;
				CommonHandleAllDropdownListMetadataFixV1(
					currentFormref.current,
					entityTypeDraftSave,
				);
				$('button:contains("Next")').hide();
				setStartSpinner(true);

				const savingDraft = await createOrSave(
					"draft",
					currentForm,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);

				if (savingDraft.status === 200 || savingDraft.status === 201) {
					$('button:contains("Next")').show();
					setStartSpinner(false);
					setOpenAlert("Draft");
					setOpen(true);
				} else {
					$('button:contains("Next")').show();
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}
			});

			//-- Event: Press review button, overwrite current form data then validate the form fields, then navigate to review page
			currentForm.on("review", async () => {
				const entityTypeReview =
					currentForm?.submission?.data?.containerParticulars?.containerBorrower
						?.entityType;
				CommonHandleAllDropdownListMetadataFixV1(
					currentFormref.current,
					entityTypeReview,
				);
				$('button:contains("Next")').hide();
				allowScrolling
					? window.scrollTo(0, 0)
					: setScrollState(() => (allowScrolling = true));

				setStartSpinner(true);

				let responseStatus = await createOrSave(
					"draft",
					currentForm,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);

				// Only if ^ action is successful
				if (responseStatus.status === 200 || responseStatus.status === 201) {
					$('button:contains("Next")').show();
					// Check if valid, go to review page, if not, show dialog to feedback, there are still so errors
					submitID_Ref.current = createdData._id;
					validityTriggered = true;
					let valCheck = checkValidity();
					if (valCheck === false) {
						form_ref.current = currentForm;
						setStartSpinner(false);
						setOpenValidationDialog(true);
					} else {
						form_ref.current = currentForm;
						goToReview();
					}
				} else {
					$('button:contains("Next")').show();
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}
			});

			//-- Event: Press cancel button, then navigate to Dashboard page
			currentForm.on("cancelPage", () => {
				history.push({
					pathname: "/Dashboard",
					refresh: true,
				});
			});

			ScheduleCallbackAfterDocumentReadyWithDelay(() => {
				ConsoleInfo("Bind click action to FormIO Page navigation");
				$(".page-link").on("click", async (event) => {
					const findTitleIndex = allTitle.indexOf(
						event.currentTarget.outerText,
					);

					if (parseInt(currentForm.page) === 0) {
						return;
					}

					setStartSpinner(true);
					//-- Save/overwrite
					const response = await createOrSave(
						"draft",
						currentForm,
						alreadySubmitted,
						createdData,
						saveCreatedData,
						createURL,
						appFormBaseURL,
						formId,
					);
					if (response.status === 200 || response.status === 201) {
						setStartSpinner(false);
						currentForm.setPage(findTitleIndex);
					} else {
						setStartSpinner(false);
						// pop up and show error message
						checkErrorStatusAndOpenErrorStatusDialog();
					}
				});
			});

			currentForm.on("fileUploadingStart", async () => {
				setStartSpinner(true);
			});

			currentForm.on("fileUploadingEnd", async () => {
				setStartSpinner(false);
			});

			currentForm.on("downloadVesselUploadTemplate", async () => {
				TriggerEvent_DownloadVesselUploadSample(
					vesselUploadTemplateRef,
					currentFormref.current,
				);
			});

			$(".card-body").addClass("borderless");

			handleConvertAllUTCDateToLocalDate();

			currentFormref.current = currentForm;
		});
	};

	const handleCheckboxUploadVesselsToggle = () => {
		let changedResult =
			currentForm.submission.data.containerParticulars.containerShipVessel
				.checkboxMoreThanFiftyVessels;
		setOpenUploadVesselsDialog(!openUploadVesselsDialog);
		setUploadVesselsType(
			changedResult
				? UploadVesselCheckboxType.DATAGRID_TO_EXCEL
				: UploadVesselCheckboxType.EXCEL_TO_DATAGRID,
		);
	};

	const handleConvertAllUTCDateToLocalDate = () => {
		let showDebugDate = false; // Dont delete this variable, let it stay, it's for debugging. Set it to true if u want debug, set it to false to use realtime data
		let testDate = "2023-02-14T00:00:00+08:00";

		//--------------------------------------------------------------------------------
		// Handle All [Datagrids]->[Date/Time Component] here
		//--------------------------------------------------------------------------------
		// Handle Section 1
		{
			// Datagrid->Vessel Particular
			if (
				currentForm.submission.data.containerParticulars?.containerShipVessel
					?.dataGridVessel1 !== undefined &&
				currentForm.submission.data.containerParticulars?.containerShipVessel
					?.dataGridVessel1 !== null
			) {
				let size =
					currentForm.submission.data.containerParticulars?.containerShipVessel
						?.dataGridVessel1.length ?? 0;
				for (let i = 0; i < size; ++i) {
					let index = i.toString();

					// Vessel Particular -> Delivery Date
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"]["deliveryDate3"];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"deliveryDate3"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					// Vessel Particular -> dateOfRegistration
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"dateOfRegistration"
							];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"dateOfRegistration"
							] = showDebugDate ? testDate : dateLocal;
						}
					}
				}

				//-- Update formIO side to trigger change event and redraw ui event
				const dgComp = currentForm.getComponent("dataGridVessel1");
				if (dgComp) {
					dgComp.triggerChange();
					dgComp.triggerRedraw();
				}
			}

			if (
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.dataGridNonTaxResident !== undefined &&
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.dataGridNonTaxResident !== null
			) {
				// Dategrid->Details of Non-Tax Resident(s) and the Agreement
				{
					let size =
						currentForm.submission.data.containerNonTax?.containerNonTaxResident
							?.dataGridNonTaxResident.length ?? 0;
					for (let i = 0; i < size; ++i) {
						let index = i.toString();

						// Details of Non-Tax Resident(s) and the Agreement -> Date Of Signed Agreement
						let dateUTC =
							currentForm.submission.data["containerNonTax"][
								"containerNonTaxResident"
							]["dataGridNonTaxResident"][index]["containerNonTax"][
								"dateOfSignedAgreement"
							];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerNonTax"][
								"containerNonTaxResident"
							]["dataGridNonTaxResident"][index]["containerNonTax"][
								"dateOfSignedAgreement"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					//-- Update formIO side to trigger change event and redraw ui event
					const dgComp = currentForm.getComponent("dataGridNonTaxResident");
					if (dgComp) {
						dgComp.triggerChange();
						dgComp.triggerRedraw();
					}
				}
			}
		}

		//--------------------------------------------------------------------------------
		// Handle All "NON"->[Datagrids]->[Date/Time Component] here
		//--------------------------------------------------------------------------------
		// Handle Section 2
		{
			//-- [Date/Time Component] Handle Date Of Signed Agreement
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.dateOfSignedAgreementTransferCertificate !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.dateOfSignedAgreementTransferCertificate !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfSignedAgreementTransferCertificate"
					];
				let datePropertyName = "dateOfSignedAgreementTransferCertificate";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfSignedAgreementTransferCertificate"
					] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Period of Financing (From)
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingFrom !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingFrom !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingFrom"
					];
				let datePropertyName = "periodOfFinancingFrom";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingFrom"
					] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Period of Financing (To)
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingTo !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingTo !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingTo"
					];
				let datePropertyName = "periodOfFinancingTo";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingTo"
					] = showDebugDate ? testDate : dateLocal;
					//selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Period Covered (From)
			if (
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.datePeriodCoveredFrom !== undefined &&
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.datePeriodCoveredFrom !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"][
						"containerNonTaxResident"
					]["datePeriodCoveredFrom"];
				let datePropertyName = "datePeriodCoveredFrom";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"][
						"containerNonTaxResident"
					]["datePeriodCoveredFrom"] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Period Covered (To)
			if (
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.datePeriodCoveredTo !== undefined &&
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					.datePeriodCoveredTo !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"][
						"containerNonTaxResident"
					]["datePeriodCoveredTo"];
				let datePropertyName = "datePeriodCoveredTo";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"][
						"containerNonTaxResident"
					]["datePeriodCoveredTo"] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
		}

		// Handle Section 3
		{
			//-- [Date/Time Component] Handle Date of earliest relevant qualifying payment made
			if (
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPayment?.dateEarliestQualifyingPayment !==
					undefined &&
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPayment?.dateEarliestQualifyingPayment !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPayment"
					]["dateEarliestQualifyingPayment"];
				let datePropertyName = "dateEarliestQualifyingPayment";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPayment"
					]["dateEarliestQualifyingPayment"] = showDebugDate
						? testDate
						: dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Date of Effective date of WHT exemption
			if (
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.effectiveDateOfWHTExemption !==
					undefined &&
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.effectiveDateOfWHTExemption !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["effectiveDateOfWHTExemption"];
				let datePropertyName = "effectiveDateOfWHTExemption";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["effectiveDateOfWHTExemption"] = showDebugDate
						? testDate
						: dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Date of End date
			if (
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.endDate !== undefined &&
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.endDate !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["endDate"];
				let datePropertyName = "endDate";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["endDate"] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
		}

		//-- [Date/Time Component] Handle Date of Transfer Certificate
		if (
			currentForm.submission.data.containerNonTax?.containerLender
				?.dateOfTransferCert !== undefined &&
			currentForm.submission.data.containerNonTax?.containerLender
				?.dateOfTransferCert !== null
		) {
			let dateUTC =
				currentForm.submission.data["containerNonTax"]["containerLender"][
					"dateOfTransferCert"
				];
			let datePropertyName = "dateOfTransferCert";
			if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
				let dateLocal = new Date(dateUTC);
				currentForm.submission.data["containerNonTax"]["containerLender"][
					"dateOfTransferCert"
				] = showDebugDate ? testDate : dateLocal;
			}

			let selectedComponent = currentForm.getComponent(datePropertyName);
			if (selectedComponent) {
				selectedComponent.triggerChange();
				selectedComponent.triggerRedraw();
			}
		}
	};

	const handleCustomRenderEvent = () => {
		//-- Ensure borrower's datagrid->first element->delete button is always hidden
		removeBorrowerFirstDeleteButton();

		//-- To update the list of borrowers to be displayed in the declaration page
		updateBorrowersInDeclaration();

		//-- JQuery to overwrite file component functionality to allow file download and delete with JWT Token
		GenericFileUploadOnRender(false, currentForm, setStartSpinner);
	};

	const updateBorrowersInDeclaration = () => {
		if (currentForm.page == 5) {
			populateDeclarationAndUndertaking();
		}
	};

	const removeBorrowerFirstDeleteButton = () => {
		//-- Temporary solution: Ensure borrower datagrid first element delete button is always hidden
		// Only run at page 1 where the borrower datagrid exist in.
		if (currentForm.page == 1) {
			let listOfDataGridBorrower_td = $(
				".formio-component-dataGridBorrower",
			).find("td");
			if (listOfDataGridBorrower_td) {
				// >= 5 means it will have more than 2 entries in the datagrid (hardcoded for now),
				if (listOfDataGridBorrower_td.length >= 5) {
					// the delete html element is located in 2nd <td/>, hence we access listOfDataGridBorrower_td[1]
					listOfDataGridBorrower_td[1].hidden = true;
				}
			}
		}
	};

	const onRender = async () => {
		allowScrolling
			? window.scrollTo(0, 0)
			: setScrollState(() => (allowScrolling = true));

		await onRenderGeneric(
			setPage,
			currentForm,
			alreadySubmitted,
			createdData,
			saveCreatedData,
			createURL,
			appFormBaseURL,
			formId,
			prevStatus,
		);
		$("ol > li:not(.MuiBreadcrumbs-li)").css("list-style", "lower-alpha");
		$("ol > li > ol > li:not(.MuiBreadcrumbs-li)").css(
			"list-style",
			"lower-roman",
		);
		setTimeout(() => {
			checkValidity();
		}, 0);
		$(function () {
			handleCustomRenderEvent();
		});
	};

	function goToReview() {
		let contactName = form_ref.current._submission.data.contactName;
		history.push({
			pathname: "/WHTShipReview",
			search: `?_id=${submitID_Ref.current}&status=${prevStatus}`,
			// search: `?_id=${submitID_Ref.current}`,
			state: {
				user:
					contactName === ""
						? form_ref.current._submission.data.configContainer.usersInvolved
						: contactName,
				company:
					form_ref.current._submission.data.configContainer.companyInvolved,
				caseID: caseID === null || caseID === undefined ? reviewCaseID : caseID,
				canSubmit: canSubmit,
				prevStatus: prevStatus,
			},
			refresh: true,
		});
	}

	const populateDeclarationAndUndertaking = () => {
		whtExemption_startDate =
			moment(
				currentForm.submission.data.containerQualifyingDetails
					.containerQualifyingPeriod["effectiveDateOfWHTExemption"],
			)?.format("DD-MMM-YYYY") ?? "START_DATE_IS_EMPTY";
		whtExemption_endDate =
			moment(
				currentForm.submission.data.containerQualifyingDetails
					.containerQualifyingPeriod["endDate"],
			)?.format("DD-MMM-YYYY") ?? "END_DATE_IS_EMPTY";

		//-- Display the Main Borrower in Declaration page
		let getMainBorrower = "";

		for (const element of currentForm.submission.data.containerParticulars
			.containerBorrower.dataGridBorrower) {
			getMainBorrower =
				currentForm.submission.data.containerParticulars.containerBorrower
					.dataGridBorrower[0].containerCompanyRegisteredAddress.borrowerName;
		}

		let text = `(a) The WHT exemption is granted with effective from ${whtExemption_startDate} to ${whtExemption_endDate}\n\n(b) The information given or attached in the form is true and correct and that the WHT exemption is granted based on the representations made in this form. Should there be:\n\n     (i) any false or incorrect declaration of information; or\n\n     (ii) any change in material information [e.g. terms and conditions of financing agreement(s), sell down of loan, etc],\n\n    the borrower, ${getMainBorrower} is required to re-submit a new declaration form immediately.  \n\nWhere the qualifying conditions are not met, the WHT exemption granted will be withdrawn with immediate effect and the Government reserves the right to recover the taxes that should be withheld to account for any false or incorrect declarations made or material change in the information provided.  \n\n<b>Under the Singapore Income Tax Act 1947, there are penalties for making a false or incorrect declaration.</b> `;

		currentForm.submission.data.containerDeclarationAndUndertaking.textAreaDeclaration =
			text;

		const textDeclaration = currentForm.getComponent("textAreaDeclaration");
		textDeclaration.triggerChange();
		textDeclaration.triggerRedraw();
	};

	const retrieveSGVesselDetailsFromSRS = async (e) => {
		/* Read this first: what is inside (e)?
      Object: {
          cbIMO: false,
          certNumContainer: {certificationNumber: '', certNum-search: false},
          countries: "Singapore",
          dateOfRegistration: "",
          deliveryDate3: "",
          grossTonnage: "",
          imoNumber: "",
          netTonnage: "",
          search: true,
          vesselName: "",
          [[Prototype]] }
    */

		//-- Temp Variables
		let currentIndex = -1;

		//-- Loop thr entire data grid and see whether there is already an existing IMO number
		let duplicateIndex = CommonVesselDatagrid_GetDuplicatedComponent_Index(
			e,
			currentForm,
			ImoType.NORMAL,
		);

		//-- Find which index are we
		currentIndex = CommonVesselDatagrid_GetComponent_Index(
			e,
			currentForm,
			ComponentFieldType.IMO_NUMBER,
		);

		//-- Duplicate => Display dialog message error
		if (duplicateIndex !== -1) {
			toggleVesselDialog(true, VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER);

			//-- Reset all fields
			currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
				currentIndex
			].containerDataGrid.statusVesselDetail = VesselDetailsStatus.EMPTY;

			currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
				currentIndex
			].containerDataGrid.imoNumber = "";
			CommonVesselDatagrid_GetComponent_ClearFields(
				currentIndex,
				currentForm,
				false,
			);

			//-- Update formIO side to trigger change event and redraw ui event
			CommonVesselDatagrid_GetComponent_Update(
				e,
				currentForm,
				ComponentFieldType.NORMAL_COLUMN_TOP,
				ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
			);
			CommonVesselDatagrid_GetComponent_Update(
				e,
				currentForm,
				ComponentFieldType.NORMAL_COLUMN_BOTTOM,
				ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
			);
		}
		//-- No duplicate => We call api to check if imo exist on the server side
		else {
			//-- Fetch api using imoNumber and auto populate the details
			// check for valid IMO Number
			try {
				let srsByIMOList = null;
				let vesselDetails = null;

				// SRS Dummy data
				if (SIMULATE_DUMMY_DATA_SRS_API) {
					vesselDetails = {
						vesselParticulars: {
							certificate: [
								{
									registrationDate: "2015-02-09T00:00:00Z",
								},
							],
							netTonnage: 123,
							grossTonnage: 123456789,
							vesselType: "TEST VesselType",
							yearBuilt: 1234,
							vesselName: "TEST vesselName",
						},
					};
				}
				// SRS API Real Data
				else {
					srsByIMOList = await get_WHT_SRS_Info(e.imoNumber);
					srsByIMO = srsByIMOList[0];
					vesselDetails = srsByIMO;
				}

				//-- Fetched: Success
				// Check if the fetched data contain the information that we need
				let isFetchedDataSufficient = true;

				// [Error check #1: Insufficient data, because this imo number no vessel particulars]
				if (
					vesselDetails?.vesselParticulars === null ||
					vesselDetails?.vesselParticulars === undefined
				) {
					// Toggle flag
					isFetchedDataSufficient = false;

					// Error message on console
					ConsoleError(
						"Fetched success from SRS API, but insufficient data, because this imo number has no vessel particulars",
					);
				}

				// [Error check #2: Insufficient data, because this imo number no certificate number]
				else if (
					vesselDetails?.vesselParticulars.certificate?.length === 0 ||
					vesselDetails?.vesselParticulars.certificate?.length === undefined ||
					vesselDetails?.vesselParticulars.certificate?.length === null
				) {
					// Toggle flag
					isFetchedDataSufficient = false;

					// Error message on console
					ConsoleError(
						"Fetched success from SRS API, but insufficient data, because this imo number has vessel particulars but no certificate.",
					);
				}

				// [SUCCESS->Fetched Data: Sufficient data from the api of this imo number] Handle update fetched data to UI
				if (isFetchedDataSufficient) {
					//-- Set this element->state to SRS_FOUND
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.statusVesselDetail =
						VesselDetailsStatus.SRS_FOUND;

					//-- Populate fetched data from API
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.dateOfRegistration =
						vesselDetails?.vesselParticulars?.certificate[0]
							?.registrationDate ?? ""; // Date of registration in singapore registry of ships

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.typeOfVesselDescription =
						vesselDetails.vesselParticulars.vesselTypeDesc; // Vessel Type

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.yearBuilt =
						vesselDetails.vesselParticulars.yearBuilt; // Year Built

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.vesselName =
						vesselDetails.vesselParticulars.vesselName; //vessel name
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.grossTonnage =
						vesselDetails.vesselParticulars.grossTonnage; //gross tonnage
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.netTonnage =
						vesselDetails.vesselParticulars.netTonnage; //net tonnage

					//-- Show dialog that says successed to find vessel
					toggleVesselDialog(true, VesselDialogType.SUCCESS_VESSEL_FOUND);
				}
				// [FAILED->Fetched Data: Insufficient data from the api of this imo number..]
				else {
					//-- Set this element->state to SRS_NOT_FOUND
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.statusVesselDetail =
						VesselDetailsStatus.SRS_NOT_FOUND;

					//-- Reset all fields
					CommonVesselDatagrid_GetComponent_ClearFields(
						currentIndex,
						currentForm,
						false,
					);

					//-- Show dialog that says failed to find vessel
					toggleVesselDialog(true, VesselDialogType.ERROR_VESSEL_NOT_FOUND);
				}

				//-- Update formIO side to trigger change event and redraw ui event
				// CommonVesselDatagrid_GetComponent_Update(e, currentForm, ComponentFieldType.NORMAL_COLUMN_TOP, ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW);
				CommonVesselDatagrid_GetComponent_Update(
					e,
					currentForm,
					ComponentFieldType.NORMAL_COLUMN_BOTTOM,
					ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
				);
			} catch (error) {
				//-- Error message on console
				let errorInfo = `Error ${error.response.status}: ${error.response.statusText}`;
				ConsoleError("Fetched fail from SRS API.", errorInfo);

				//-- Set this element->state to SRS_NOT_FOUND to reflect changes on form ui
				currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
					currentIndex
				].containerDataGrid.statusVesselDetail =
					VesselDetailsStatus.SRS_NOT_FOUND;

				//-- Reset all fields
				CommonVesselDatagrid_GetComponent_ClearFields(
					currentIndex,
					currentForm,
					false,
				);

				//-- Show dialog that says failed to find vessel
				toggleVesselDialog(true, VesselDialogType.ERROR_VESSEL_NOT_FOUND);

				//-- Update formIO side to trigger change event and redraw ui event
				CommonVesselDatagrid_GetComponent_Update(
					e,
					currentForm,
					ComponentFieldType.NORMAL_COLUMN_TOP,
					ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
				);
				CommonVesselDatagrid_GetComponent_Update(
					e,
					currentForm,
					ComponentFieldType.NORMAL_COLUMN_BOTTOM,
					ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
				);
			}
		}
	};

	const onChange = async (event) => {
		if (
			prevStatus === "Draft" ||
			prevStatus === "draft" ||
			prevStatus === "All" ||
			prevStatus === "all" ||
			prevStatus === null ||
			prevStatus === undefined
		) {
			$(".formio-component-applicantcomment").hide();
		}

		onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
		checkValidity();

		switch (event.changed?.component.key) {
			case "periodOfFinancingTo": {
				if (
					currentForm.submission.data.containerNonTax.containerLender
						.periodOfFinancingTo !== "" &&
					currentForm.data.containerQualifyingDetails.containerQualifyingPeriod
						.effectiveDateOfWHTExemption <
						new Date(
							currentForm.submission.data.containerNonTax.containerLender.periodOfFinancingTo,
						)
				) {
					currentForm.submission.data.containerQualifyingDetails.containerQualifyingPeriod.endDate =
						currentForm.submission.data.containerNonTax.containerLender.periodOfFinancingTo;
				}
				const periodOfFinancingToComp = currentForm.getComponent(
					"periodOfFinancingTo",
				);
				if (periodOfFinancingToComp) {
					periodOfFinancingToComp.triggerRedraw();
				}
				break;
			}
			default: {
				break;
			}
		}

		if (
			event.changed?.component.key === "radioAnyPayment" ||
			event.changed?.component.key ===
				"isThereAnySwapArrangementInTheSameFinancingAgreement"
		) {
			CommonUpdateValidation_QualifyingPayment(
				currentForm,
				event.changed?.component.key,
			);
		}
		if (JSON.parse(window.sessionStorage.getItem("Started")) === "false") {
			setStartSpinner(false);
		}
	};

	const onSubmit = async (submission) => {
		onSubmitGeneric(
			submission,
			currentForm,
			alreadySubmitted,
			createdData,
			saveCreatedData,
			createURL,
			appFormBaseURL,
			formId,
		);
	};

	const onError = async (errors) => {
		onErrorGeneric(errors);
	};

	const onSubmitDone = (submission) => {
		onSubmitDoneGeneric(submission);
	};

	const onCustomEvent = async ({ type, component, data, event }) => {
		// This method is intentionally empty.
	};

	function changePage(x) {
		thisForm.setPage(x);
	}

	function scrollToError() {
		window.scrollTo(0, 0);
	}

	function goToTop() {
		window.scrollTo(0, 0);
		setTimeout(scrollToError, 2000);
	}

	function checkValidity() {
		let valCheck = false;
		if (validityTriggered) {
			valCheck = currentForm.checkValidity(null, true, null, false);
			let oldPagesErrors = pagesErrors;
			currentForm.pages.forEach(
				(x, index) => (oldPagesErrors[index] = x.errors.length),
			);
			setPagesErrors(oldPagesErrors);
			setCurrentPageErrors(pagesErrors[currentForm.page]);
		}
		return valCheck;
	}

	const handleDelete = async () => {
		try {
			if (submitID_Ref.current) {
				const originalPromiseResult = await dispatch(
					deleteFormAPIAsync({
						formId: formId,
						submissionId: submitID_Ref.current,
					}),
				).unwrap();
				history.push({
					pathname: "/NestedApplicationListing",
					search: `?schema=2`,
					refresh: true,
				});
			} else {
				alert("Delete is not allowed");
			}
		} catch (rejectedValueOrSerializedError) {
			ConsoleLog(rejectedValueOrSerializedError);
			alert("Delete is not allowed");
		}
	};

	const handleUploadVesselDialogResponse = (text, type) => {
		setOpenUploadVesselsDialog(!openUploadVesselsDialog);
		CommonToggleFileUploadVessels(currentFormref.current, text);
	};

	const toggleVesselDialog = (show, dialogMessageType) => {
		setOpenDialog(show);
		setDialogMessageType(dialogMessageType);
	};

	return (
		<div className="applicationform">
			<ValidationDialog
				open={openValidationDialog}
				setOpen={setOpenValidationDialog}
				goToTop={goToTop}
			/>
			<Container>
				{/* call from the ApplicationForm.js */}
				<FormHtmlHead
					formname={formname}
					schema={schema}
				/>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<FormHtmlTitle
						formname={formname}
						formid={formid}
						formstatus={status}
						caseID={caseID}
					/>
					{/* <Button className="large button-large univers-65-bold-white-16px role-margin"

            onClick={() => { handleDelete() }}
          >
            Delete
          </Button> */}
				</div>
				<br />
				<PageCarousel
					thisform={thisForm}
					setpage={changePage}
					pagesErrors={pagesErrors}
					removeAssessmentClick={true}
				/>
				<FormHtmlErrors
					thisForm={thisForm}
					pagesErrors={pagesErrors}
					currentPageErrors={currentPageErrors}
				/>
				<FormHtmlForm
					fetchingform={fetchingform}
					formReadOnly={formReadOnly}
					props={props}
					appFormURL={appFormUrl2}
					onChange={onChange}
					onError={onError}
					formReady={formReady}
					onSubmit={onSubmit}
					onSubmitDone={onSubmitDone}
					onRender={onRender}
					onCustomEvent={onCustomEvent}
					isBusy={isBusy}
					appFormJson={appFormJson}
				/>
			</Container>
			<VesselDialog
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				vesselDialogMessageType={dialogMessageType}
			/>
			<DateValidationDialog
				openDialog={openDateValidDialog}
				setOpenDateValidDialog={setOpenDateValidDialog}
			/>
			<UploadVesselsDialog
				open={openUploadVesselsDialog}
				setOpenDialog={setOpenUploadVesselsDialog}
				response={handleUploadVesselDialogResponse}
				type={uploadVesselsType}
			/>
			<AutoSaveErrorDialog
				open={openErrorStatusDialog}
				setOpen={setOpenErrorStatusDialog}
			/>
			<SnackbarAlert
				alert={openAlert}
				caseID={caseID}
				open={open}
				setOpen={setOpen}
			/>
			<ProgressBar
				ref={ref}
				startSpinner={startSpinner}
				setStartSpinner={setStartSpinner}
				bar={bar}
				setBar={setBar}
			/>
		</div>
	);
}
