/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "@mui/material";
import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
	createOrSave,
	FormHtmlErrors,
	FormHtmlForm,
	FormHtmlHead,
	FormHtmlTitle,
	onChangeGeneric,
	onErrorGeneric,
	onRenderGeneric,
	onSubmitDoneGeneric,
	onSubmitGeneric,
	PageCarousel,
} from "../../../Common/ApplicationForm";
import { get_WHT_SRS_Info } from "../../../Common/SRS";
import { config } from "../../../config";
import "../../ApplicationForm/ApplicationForm.css";
import { useHistory, useLocation } from "react-router-dom";
import ValidationDialog from "../../../Common/ValidationDialog";
import { fetch } from "../../../Common/API";
import loginHelper from "../../../Common/loginHelper";
import VesselDialog, { VesselDialogType } from "./VesselDialog";
import moment from "moment";
import DateValidationDialog from "./DateValidationDialog";
import Utils from "formiojs/utils";
import { deleteFormAPIAsync } from "../../../Redux/CommonForm/CommonFormSlice.js.js";
import flatComponent from "../../../Common/FormIOComponent/flatComponent";
import scanData from "../../../Common/FormIOComponent/scanData";
import {
	getTrackChanges,
	NamePropertyTrackChanges,
	TrackChanges,
} from "../../ApplicationListing/APICall/apicall";
import SnackbarAlert from "../../../Common/snackbarAlert";
import { ConsoleError, ConsoleInfo, ConsoleWarn } from "../../../Common/Logger";
import ProgressBar from "../../../Common/progressBar";
import AutoSaveErrorDialog from "../../../Common/autoSaveError";
import getTitle from "../../../Common/FormIOComponent/titleComponent";
import {
	ComponentFieldType,
	ComponentUpdateType,
	ImoType,
	SIMULATE_DUMMY_DATA_SRS_API,
	VesselDetailsStatus,
	UploadVesselCheckboxType,
	WHT_CONSTANT,
} from "../../../Common/CommonEnumTypes";
import getStatusText from "../../../Common/GetStatusText";
import {
	CommonHandleAllDropdownListMetadataFixV1,
	CommonHandleFlagOfRegistryMetadataFix,
	CommonPopulateQuestionEDH,
	CommonUpdateValidation_NonQualifyingAmountFinancedCost,
	CommonUpdateValidation_QualifyingPayment,
	CommonUpdateValidation_TotalNonQualifyingAmountFinanced,
	CommonUpdateValidation_TypeOfFinancingArrangementObtained,
	CommonUpdateVesselDetailsInNewForm,
	CommonVesselDatagrid_GetComponent_Index,
	CommonVesselDatagrid_GetComponent_Update,
	CommonVesselDatagrid_GetDuplicatedComponent_Index,
	CommonVesselDatagrid_IsImoNumber_Valid,
	DateTransferCertShip,
	CommonHandleWHTShipDate,
	CommonToggleFileUploadVessels,
	TriggerEvent_DownloadVesselUploadSample,
} from "../../../Common/GenericWHTShip";
import { SwapsCheckbox } from "../../../Common/SwapsCheckboxValidation";
import {
	GenericFileUploadOnRender,
	GenericFileUploadOnChange,
	GenericHandleDefaultCountry_BorrowerParticulars,
} from "../../../Common/GenericForm";
import UploadVesselsDialog from "./UploadVesselsDialog";
import { ApplicationStatusTextOptions as ApplicationStatuses } from "../../../Common/ApplicationStatusOptions";
import { ScheduleCallbackAfterDocumentReadyWithDelay } from "../../../Common/CommonUtils";

WHTShipChangeRequestForm.defaultProps = {
	formname: "Withholding Tax Exemption (Ship) Change Request",
	formstatus: "draft",
	formId: config.form_name_target_ship_cr,
	appFormBaseURL: `${config.appFormBaseURL}`,
	schema: "WHT_Ship",
};

export default function WHTShipChangeRequestForm({
	formname,
	formid,
	formstatus,
	formId,
	appFormBaseURL,
	schema,
	props,
}) {
	//declaration
	let declarationHTMLTEST = "";
	let borrowerName = "";

	//-- useState variable(s)
	const [page, setPage] = useState(0); // Usage: Inform which tab are you in WHT_SHIP
	const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false); // Usage: Trigger open error dialog box
	const [isBusy, setBusy] = useState(true); // Usage: Busy = true means form is loading, dont render form yet
	const appFormJson = useRef(); // Usage: Load form using json files, for now we using load form using url
	const [openValidationDialog, setOpenValidationDialog] = useState(false); // Usage: When we click review button, we need validate dialog to validate everything, this flag will trigger whether to open validation dialog or not
	const [disableScrollingFunc, setScrollState] = useState(() => {
		// This function is intentionally empty.
	}); // Usage: Scroll to top when you press next button

	const [openDialog, setOpenDialog] = useState(false);
	const [openDateValidDialog, setOpenDateValidDialog] = useState(false);
	const [dialogMessageType, setDialogMessageType] = useState(
		VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER,
	);

	let srsByIMO = {};
	let srsByCert = {};

	//-- helper variable(s)
	let allowScrolling = true;
	let validityTriggered = false;

	//
	const dispatch = useDispatch();

	//-- Navigation variable(s)
	const history = useHistory();
	const params = new URLSearchParams(window.location.search);
	let _id = params.get("_id"); // Usage: id of this form
	const theformId = _id;
	const selected = params.get("selected"); // Usage: In WHT_EntityChecker Form, user selected their entity type. We use 'selected' to get which entity type they selected.
	let createdData = { _id: _id };
	let mainSubmissionId = params.get("mainSubmissionId");
	let getStatus = params.get("status");
	const [status, setStatus] = useState();
	let prevStatus;
	let reviewCaseID;

	let formType = "create";
	if (_id) {
		formType = "update";
	}

	//-- Form instance and form url macros
	let createAppFormURL = `${appFormBaseURL}/${formId}`;
	let updateAppFormURL = `${appFormBaseURL}/${formId}/submission/${_id}`;
	let createURL = `${appFormBaseURL}/${formId}/submission`;
	let currentForm = {};

	let whtExemption_startDate = "";
	let whtExemption_endDate = "";

	let existingEntityType = "";

	//-- More useState(s) for Form
	const [thisForm, setThisForm] = useState();
	const [formReadOnly, setFormReadOnly] = useState(false);
	const [fetchingform, setFetchingform] = useState(true);
	const [pagesErrors, setPagesErrors] = useState([]);
	const [currentPageErrors, setCurrentPageErrors] = useState(0);

	const location = useLocation();
	const getCaseID = location?.state?.caseID;
	const [caseID, setCaseID] = useState(getCaseID);
	const [openAlert, setOpenAlert] = useState();
	const [open, setOpen] = useState(false);
	const [startSpinner, setStartSpinner] = useState(false);

	const [openUploadVesselsDialog, setOpenUploadVesselsDialog] = useState(false);
	const [uploadVesselsType, setUploadVesselsType] = useState();

	//-- Submission of form
	let alreadySubmitted = false;
	const submitID_Ref = useRef("");
	const form_ref = useRef({});
	const currentFormref = useRef({});
	const vesselUploadTemplateRef = useRef();
	let canSubmit = true;
	const [bar, setBar] = useState(false);
	const ref = useRef();

	let reloadStrictMode = false;

	const checkErrorStatusAndOpenErrorStatusDialog = () => {
		if (sessionStorage.getItem("error") !== "409") {
			setOpenErrorStatusDialog(true);
		}
	};

	const getImoType = (containerCheckBox) => {
		let imoType = ImoType.NONE;

		if (
			containerCheckBox?.editVessel !== undefined &&
			containerCheckBox?.editVessel === true
		) {
			imoType = ImoType.EDIT;
		} else if (
			containerCheckBox?.replaceVesselButton !== undefined &&
			containerCheckBox?.replaceVesselButton === true
		) {
			imoType = ImoType.REPLACE;
		} else if (
			containerCheckBox?.deleteVessel !== undefined &&
			containerCheckBox?.deleteVessel === true
		) {
			imoType = ImoType.DELETE;
		} else {
			imoType = ImoType.NONE;
		}
		return imoType;
	};

	const updateNewVesselinNewCR = (json) => {
		// New arrays (submission data and metadata) to overwrite old array of datagrid
		let newDataGridVessel = [];
		let newDataGridVesselMeta = [];

		// Arrays (submission data and metadata) to contain current value
		let oldDataGridVessel =
			json?.data?.containerParticulars?.containerShipVessel?.dataGridVessel1;
		let oldDataGridVesselMeta =
			json?.metadata?.selectData?.containerParticulars?.containerShipVessel
				?.dataGridVessel1;

		const dgSize = oldDataGridVessel?.length ?? 0;

		for (let i = 0; i < dgSize; i++) {
			let dgVessel = oldDataGridVessel[i];
			let dgVesselMeta =
				oldDataGridVesselMeta !== undefined ? oldDataGridVesselMeta[i] : null;

			//LOCKED - have 3 buttons
			if (
				dgVessel.containerDataGrid.statusVesselDetail ===
					VesselDetailsStatus.LOCKED ||
				dgVessel.containerDataGrid.statusVesselDetail ===
					VesselDetailsStatus.LOCKED_SRS_NOT_FOUND
			) {
				//Get Imo Type - Edit, Replace, Delete, None
				let checkboxType = getImoType(
					dgVessel.containerDataGrid.containerCheckbox,
				);
				let oldVessel = dgVessel;
				let newVessel = dgVessel;

				let oldVesselMeta = dgVesselMeta;
				let newVesselMeta = dgVesselMeta;

				//Existing vessels (for SRS found & SRS Not found) with EDIT checked
				if (checkboxType === ImoType.EDIT) {
					if (
						dgVessel.containerDataGrid.edit_statusVesselDetail ===
							VesselDetailsStatus.SRS_FOUND ||
						dgVessel.containerDataGrid.edit_statusVesselDetail ===
							VesselDetailsStatus.LOCKED
					) {
						newVessel.containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.SRS_FOUND;
					} else if (
						dgVessel.containerDataGrid.edit_statusVesselDetail ===
							VesselDetailsStatus.SRS_NOT_FOUND ||
						dgVessel.containerDataGrid.edit_statusVesselDetail ===
							VesselDetailsStatus.LOCKED_SRS_NOT_FOUND
					) {
						newVessel.containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.SRS_NOT_FOUND;
					} else {
						newVessel.containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.SRS_NOT_FOUND;
					}
				}

				//Existing vessels (for SRS found & SRS Not found) with REPLACE checked
				else if (checkboxType === ImoType.REPLACE) {
					if (
						dgVessel.containerDataGrid.replacement_statusVesselDetail ===
							VesselDetailsStatus.SRS_FOUND ||
						dgVessel.containerDataGrid.replacement_statusVesselDetail ===
							VesselDetailsStatus.LOCKED
					) {
						newVessel.containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.SRS_FOUND;
					} else if (
						dgVessel.containerDataGrid.replacement_statusVesselDetail ===
							VesselDetailsStatus.SRS_NOT_FOUND ||
						dgVessel.containerDataGrid.replacement_statusVesselDetail ===
							VesselDetailsStatus.LOCKED_SRS_NOT_FOUND
					) {
						newVessel.containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.SRS_NOT_FOUND;
					} else {
						newVessel.containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.SRS_NOT_FOUND;
					}
				}

				//Existing vessels (for SRS found & SRS Not found) with DELETE checked -> this will set to null
				else if (checkboxType === ImoType.DELETE) {
					newVessel = null;
					newVesselMeta = null;
				}

				//Existing vessels (for SRS found & SRS Not found) where none of the 3 checkboxes are checked
				else {
					if (
						dgVessel.containerDataGrid.statusVesselDetail ===
							VesselDetailsStatus.SRS_FOUND ||
						dgVessel.containerDataGrid.statusVesselDetail ===
							VesselDetailsStatus.LOCKED
					) {
						newVessel.containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.SRS_FOUND;
					} else if (
						dgVessel.containerDataGrid.statusVesselDetail ===
							VesselDetailsStatus.SRS_NOT_FOUND ||
						dgVessel.containerDataGrid.statusVesselDetail ===
							VesselDetailsStatus.LOCKED_SRS_NOT_FOUND
					) {
						newVessel.containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.SRS_NOT_FOUND;
					} else {
						newVessel.containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.SRS_NOT_FOUND;
					}
				}

				CommonUpdateVesselDetailsInNewForm(
					newVessel,
					oldVessel,
					newVesselMeta,
					oldVesselMeta,
					checkboxType,
					i,
				);

				// Modify the list
				if (newVessel !== null) {
					newDataGridVessel.push(newVessel);
				}
				if (newVesselMeta !== null) {
					newDataGridVesselMeta.push(newVesselMeta);
				}
			}

			//NOT LOCKED - don't have 3 buttons
			/*
        Ship Main to Ship CR ---> Data will come from the Main form
      */
			else {
				let oldVessel = dgVessel;
				newDataGridVessel.push(oldVessel);
			}
		}

		// Apply the changes
		json.data.containerParticulars.containerShipVessel.dataGridVessel1 =
			newDataGridVessel;
		if (
			json.metadata?.selectData?.containerParticulars?.containerShipVessel
				?.dataGridVessel1 !== undefined
		) {
			json.metadata.selectData.containerParticulars.containerShipVessel.dataGridVessel1 =
				newDataGridVesselMeta;
		}

		return json;
	};

	useEffect(() => {
		async function getform() {
			setBusy(true);

			let url = `${config.appURL}/api/application/GetLatestApproveMainORCRSubmissionBySubmissionId/${mainSubmissionId}`; // HW's change
			if (formType === "update") {
				url = updateAppFormURL;
			}

			let json = await fetch(url, "GET", null);
			if (json) {
				if (reloadStrictMode) {
					reloadStrictMode = false;
				} else {
					setBusy(false);

					let tempStatus = "";
					if (getStatus === "All") {
						setStatus("draft");
						prevStatus = "draft";
						tempStatus = ApplicationStatuses.Draft;
					} else {
						const currentStatus =
							json.data.data.configContainer.publicApplicationStatus;
						const getText = (tempStatus = getStatusText(currentStatus));
						setStatus(getText);
						prevStatus = getText;
					}

					try {
						/*
            We expect 3 different scenarios when fetching data:
            [1] Take from Main form
            [2a] Take from CR^n form
            [2b] Take from draft of CR form
            */
						vesselUploadTemplateRef.current =
							json?.data?.data?.containerParticulars?.containerShipVessel?.containerVesselsFileUpload?.fileUploadExcelSheet?.[0];

						appFormJson.current = json.data; //fetch all the data

						if (tempStatus === ApplicationStatuses.Draft) {
							cleanupEmptyFilesFromMigratedShipMain(appFormJson);
						}
					} catch (e) {
						ConsoleError(e);
					}
				}
			}
		}
		getform();

		sessionStorage.setItem("submissionId", theformId);

		return () => (reloadStrictMode = true);
	}, []);

	useEffect(() => {
		// Create a new interval, and also run it every 'n' seconds
		let intervalInstance = setInterval(async function () {
			const entityTypeUseEffect =
				currentFormref.current?.submission?.data?.containerParticulars
					?.containerBorrower?.entityType;

			if (currentFormref.current) {
				CommonHandleAllDropdownListMetadataFixV1(
					currentFormref.current,
					entityTypeUseEffect,
				);
				await createOrSave(
					"draft",
					currentFormref.current,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);
			}
		}, 60000);

		return () => {
			clearInterval(intervalInstance);
		};
	}, []);

	function saveCreatedData(x) {
		createdData = x;
	}

	async function saveChanges(component, currentData, preData, checkingDB) {
		let pageChanges = [];
		for (const item in currentData) {
			for (const check in preData) {
				if (item === check) {
					const checkSplit = check.split("-");
					const findChecking = checkingDB?.find(
						(i) => i?.key.split("-")[1] === checkSplit[1],
					);
					if (findChecking) {
					} else if (
						checkSplit[1].includes("date") ||
						checkSplit[1].includes("Date") ||
						checkSplit[1].includes("period") ||
						checkSplit[1].includes("Period") ||
						checkSplit[1].includes("totalNonQualifyingInterestPaymentCost") ||
						checkSplit[1].includes("totalNonQualifyingRelatedPaymentsCost") ||
						checkSplit[1].includes("nonQualifyingAmountFinancedCost") ||
						checkSplit[1].includes("purposeOfFinancingArrangement") ||
						checkSplit[1].includes("dataGridVessel1") ||
						checkSplit[1].includes("temp_delivery")
					) {
					} else {
						let now = currentData[item];
						let before = preData[check];
						if (
							before !== undefined &&
							(before === "01/01/1970" ||
								before === "01 Jan 1970" ||
								before === null ||
								before === "" ||
								before.toString() === "Invalid date")
						) {
							before = now;
						}
						if (now instanceof Date || before instanceof Date) {
							before = moment(before).format("DD/MM/YYYY");
							now = moment(now).format("DD/MM/YYYY");
							if (
								before === "01/01/1970" ||
								before === "01 Jan 1970" ||
								before === null ||
								before === "" ||
								before.toString() === "Invalid date"
							) {
								before = now;
							}
						}
						if (now instanceof Array || before instanceof Array) {
						} else if (before !== now) {
							if (item.split("-")[1].includes("uploadFile")) {
								const newName =
									item.split("-")[0] + "-" + component[item].label;
								changes.push(newName);
								pageChanges.push(newName);
							} else {
								changes.push(item);
								pageChanges.push(item);
							}
						}
					}
				}
			}

			if (item.split("-")[1].includes("uploadFile")) {
				const checkingBefore = Object.keys(preData).find((k) => k === item);
				if (!checkingBefore) {
					const newName = item.split("-")[0] + "-" + component[item].label;
					changes.push(newName);
					pageChanges.push(newName);
				}
			}
		}
		return await TrackChanges(pageChanges, theformId);
	}

	let resultComponent;
	let prevData;
	const changes = [];
	let data = [];

	function searchElements() {
		const element = document.querySelectorAll("[name]");
		for (const el of element) {
			const attr = el.getAttribute("name");
			if (attr) {
				if (attr.startsWith("data")) {
					let elementValue = el.value;
					if (
						el.name.toString().includes("Date") ||
						el.name.toString().includes("date") ||
						el.name.toString().includes("period") ||
						el.name.toString().includes("Period") ||
						el.name.toString().includes("[containerDataGrid][temp_delivery]")
					) {
						elementValue = moment(el.value).format("DD/MM/YYYY");
						if (
							elementValue === "01/01/1970" ||
							elementValue === null ||
							elementValue === "" ||
							elementValue.toString() === "Invalid date"
						) {
							elementValue = null;
						}
					}
					if (el.type.toString() === "checkbox") {
						elementValue = el.checked;
					}
					// if(el.name.toString().includes("[containerPurposeOfFa]") || el.name.toString().includes("[containerQualifyingPayment]")){
					//   elementValue = el.checked
					// }

					const theValue = {
						name: el.name,
						nodeName: el.nodeName,
						value: elementValue,
						element: el,
					};
					const check = data.find((item) => item.name === el.name);
					if (check) {
					} else {
						data.push(theValue);
						if (
							el.name
								.toString()
								.includes("[containerNonTax][nonTaxResidentName]")
						) {
							const fileParent =
								el.parentElement.parentElement.parentElement.parentElement
									.parentElement;
							let searchFile = $(fileParent.parentElement).find("a").text();
							const match = searchFile.match(
								/Press to open (.*?)\n|Press to open (.*)/,
							);
							if (match) {
								searchFile = (match[1] || match[2]).trim();
							}
							const fileData = el.name + "-AgreementDocument";
							const fileValue = {
								name: fileData,
								nodeName: "File",
								value: searchFile,
								element: fileParent.parentElement,
							};
							data.push(fileValue);
						}
						if (
							el.name
								.toString()
								.includes(
									"[containerWhyFA3][sgBasedBankOrFinancialInstitutionName]",
								)
						) {
							const fileParent =
								el.parentElement.parentElement.parentElement.parentElement;
							const searchTablefile = fileParent.querySelector(
								`[ref*="datagrid-dataGridAttachmentOfSgBankOrFI-tbody"]`,
							);

							const childLength = searchTablefile.children.length;

							for (let i = 0; i < childLength; i++) {
								const childComp = searchTablefile.children[i];
								const tableFile = $(childComp).find("a").text();
								const changefilename =
									el.name +
									"-" +
									`${i}` +
									"-sgBasedBankOrFinancialInstitutionName";
								const fileValue = {
									name: changefilename,
									nodeName: "File",
									value: tableFile,
									element: childComp,
								};
								data.push(fileValue);
							}
						}
					}
				}
			}
		}

		const selecttheBox = document.querySelector(`[id*="-a1"]`);

		if (
			selecttheBox &&
			selecttheBox.getAttribute("name").includes("selectBoxesQFA")
		) {
			for (let i = 1; i < 10; i++) {
				const theId = `-a${i}`;
				const searchselectBox = document.querySelector(`[id*=${theId}]`);
				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
		} else if (
			selecttheBox &&
			selecttheBox
				.getAttribute("name")
				.includes("selectBoxesStrategicManagement")
		) {
			for (let i = 1; i < 5; i++) {
				const theId = `-a${i}`;
				const searchselectBox = document.querySelector(`[id*=${theId}]`);
				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
			for (let i = 1; i < 8; i++) {
				const theId = `-b${i}`;
				const searchselectBox = document.querySelector(`[id*=${theId}]`);
				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
			for (let i = 1; i < 7; i++) {
				const theId = `-t${i}`;

				const searchselectBox = document.querySelector(`[id*=${theId}]`);

				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
			for (let i = 1; i < 7; i++) {
				const theId = `-r${i}`;
				const searchselectBox = document.querySelector(`[id*=${theId}]`);

				if (searchselectBox) {
					const theValueBox = {
						name: searchselectBox.id,
						nodeName: searchselectBox.nodeName,
						value: searchselectBox.checked,
						element: searchselectBox,
					};
					data.push(theValueBox);
				}
			}
		}

		return "success";
	}

	async function searchElementsSave(checkingDB) {
		let newData = [];
		const element = document.querySelectorAll("[name]");
		for (const el of element) {
			const attr = el.getAttribute("name");
			if (attr) {
				if (attr.startsWith("data")) {
					const check = data.find((item) => item.name === el.name);
					const findChecking = checkingDB?.find((i) => i.key === el.name);
					let elementValue = el.value;
					if (
						el.name.toString().includes("Date") ||
						el.name.toString().includes("date") ||
						el.name.toString().includes("period") ||
						el.name.toString().includes("Period") ||
						el.name.toString().includes("[containerDataGrid][temp_delivery]")
					) {
						elementValue = moment(el.value).format("DD/MM/YYYY");
						if (
							elementValue === "01/01/1970" ||
							elementValue === null ||
							elementValue === "" ||
							elementValue.toString() === "Invalid date"
						) {
							elementValue = null;
						}
					}
					if (el.type.toString() === "checkbox") {
						elementValue = el.checked;
					}

					// if(el.name.toString().includes("[containerPurposeOfFa]") || el.name.toString().includes("[containerQualifyingPayment]")){
					//   elementValue = el.checked
					// }

					const theValue = {
						name: el.name,
						nodeName: el.nodeName,
						value: elementValue,
						element: attr,
					};

					if (
						el.name.toString().includes("selectBoxesQFA_") ||
						el.name.toString().includes("[selectBoxesOtherCondition]") ||
						el.name.toString().includes("[selectBoxUndertakings]") ||
						el.name.toString().includes("[selectBoxesStrategicManagement]") ||
						el.name.toString().includes("[selectBoxesCommercialManagement]") ||
						el.name.toString().includes("[selectBoxesTechnicalManagement]") ||
						el.name.toString().includes("[selectBoxPreDelivery]")
					) {
					} else if (findChecking) {
					} else {
						if (check && check.value !== elementValue) {
							newData.push(theValue);
						} else if (check === undefined || !check) {
							if (el.name.toString().includes("[edit_")) {
								let replaceName;
								if (el.name.toString().includes("edit_deliveryDate")) {
									replaceName = el.name.replace(
										"edit_deliveryDate",
										"deliveryDate3",
									);
								} else {
									replaceName = el.name.replace("edit_", "");
								}

								const searchField = document.querySelector(
									`[name="${replaceName}"]`,
								);
								// const searchField = data.find(item => item.name === replaceName)
								if (searchField) {
									let searchValue = searchField.value;
									if (searchField.type.toString() === "checkbox") {
										searchValue = searchField.checked;
									}
									if (
										searchField.name.toString().includes("deliveryDate3") ||
										searchField.name
											.toString()
											.includes("dateOfRegistration") ||
										searchField.name.toString().includes("date")
									) {
										searchValue = moment(searchField.value).format(
											"DD/MM/YYYY",
										);
									}
									if (searchValue !== elementValue) {
										newData.push(theValue);
									}
								} else {
								}
							} else if (el.type.toString() === "checkbox") {
								if (el.checked === true) {
									newData.push(theValue);
								} else {
								}
							} else {
								newData.push(theValue);
								if (
									el.name
										.toString()
										.includes("[containerNonTax][nonTaxResidentName]")
								) {
									const fileParent =
										el.parentElement.parentElement.parentElement.parentElement
											.parentElement;
									let searchFile = $(fileParent.parentElement).find("a").text();
									const match = searchFile.match(
										/Press to open (.*?)\n|Press to open (.*)/,
									);
									if (match) {
										searchFile = (match[1] || match[2]).trim();
									}
									const fileData = el.name + "-AgreementDocument";
									const fileValue = {
										name: fileData,
										nodeName: "File",
										value: searchFile,
										element: attr,
									};
									newData.push(fileValue);
								}
							}
						}
					}

					if (
						el.name.toString().includes("[containerNonTax][nonTaxResidentName]")
					) {
						const parentComp =
							el.parentElement.parentElement.parentElement.parentElement
								.parentElement;
						let FileName = $(parentComp.parentElement).find("a").text();
						const match = FileName.match(
							/Press to open (.*?)\n|Press to open (.*)/,
						);
						if (match) {
							FileName = (match[1] || match[2]).trim();
						}
						const dataName = el.name + "-AgreementDocument";
						const checkFile = data.find((item) => item.name === dataName);
						const fileDBChecking = checkingDB?.find((i) => i.key === dataName);
						if (fileDBChecking) {
						}
						if (checkFile && checkFile.value !== FileName) {
							const fileValue = {
								name: dataName,
								nodeName: "File",
								value: FileName,
								element: attr,
							};
							newData.push(fileValue);
						}
					}

					if (
						el.name
							.toString()
							.includes("[containerFinancingAgreementSigned][uploadFile]")
					) {
						const parentComp =
							el.parentElement.parentElement.parentElement.parentElement
								.parentElement;
						let FileName = $(parentComp.parentElement).find("a").text();
						const match = FileName.match(
							/Press to open (.*?)\n|Press to open (.*)/,
						);
						if (match) {
							FileName = (match[1] || match[2]).trim();
						}
						const dataName = el.name + "-AgreementDocument";
						const checkFile = data.find((item) => item.name === dataName);
						const fileDBChecking = checkingDB?.find((i) => i.key === dataName);
						if (fileDBChecking) {
						}
						if (checkFile && checkFile.value !== FileName) {
							const fileValue = {
								name: dataName,
								nodeName: "File",
								value: FileName,
								element: attr,
							};
							newData.push(fileValue);
						}
					}

					if (
						el.name
							.toString()
							.includes(
								"[containerWhyFA3][sgBasedBankOrFinancialInstitutionName]",
							)
					) {
						const fileParent =
							el.parentElement.parentElement.parentElement.parentElement;
						const searchTablefile = fileParent.querySelector(
							`[ref*="datagrid-dataGridAttachmentOfSgBankOrFI-tbody"]`,
						);

						const childLength = searchTablefile.children.length;

						for (let i = 0; i < childLength; i++) {
							const childComp = searchTablefile.children[i];
							const tableFile = $(childComp).find("a").text();
							const changefilename =
								el.name +
								"-" +
								`${i}` +
								"-sgBasedBankOrFinancialInstitutionName";
							const checkFile = data.find(
								(item) => item.name === changefilename,
							);
							const fileDBChecking = checkingDB?.find(
								(i) => i.key === changefilename,
							);
							if (fileDBChecking) {
							}
							if (checkFile && checkFile.value !== tableFile) {
								const fileValue = {
									name: changefilename,
									nodeName: "File",
									value: tableFile,
									element: changefilename,
								};
								newData.push(fileValue);
							} else if (checkFile === undefined || !checkFile) {
								const fileValue = {
									name: changefilename,
									nodeName: "File",
									value: tableFile,
									element: changefilename,
								};
								newData.push(fileValue);
							}
						}
					}
				}
			}
		}

		const selectBox = document.querySelector(`[id*="-a1"]`);
		if (
			selectBox &&
			selectBox.getAttribute("name").includes("selectBoxesQFA")
		) {
			for (let i = 1; i < 10; i++) {
				const searchselectBox = document.querySelector(`[id*="-a${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id,
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						newData.push(theValueBox);
					}
				}
			}
		} else if (
			selectBox &&
			selectBox.getAttribute("name").includes("selectBoxesStrategicManagement")
		) {
			for (let i = 1; i < 5; i++) {
				const searchselectBox = document.querySelector(`[id*="-a${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id + "-" + searchselectBox.getAttribute("name"),
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						newData.push(theValueBox);
					}
				}
			}
			for (let i = 1; i < 8; i++) {
				const searchselectBox = document.querySelector(`[id*="-b${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id + "-" + searchselectBox.getAttribute("name"),
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						newData.push(theValueBox);
					}
				}
			}
			for (let i = 1; i < 7; i++) {
				const searchselectBox = document.querySelector(`[id*="-t${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id + "-" + searchselectBox.getAttribute("name"),
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						newData.push(theValueBox);
					}
				}
			}
			for (let i = 1; i < 7; i++) {
				const searchselectBox = document.querySelector(`[id*="-r${i}"]`);
				const checkData = data.find((item) => item.name === searchselectBox.id);
				const findCheckingDB = checkingDB?.find(
					(i) => i.key === searchselectBox.id,
				);
				const theValueBox = {
					name: searchselectBox.id + "-" + searchselectBox.getAttribute("name"),
					nodeName: searchselectBox.nodeName,
					value: searchselectBox.checked,
					element: searchselectBox.getAttribute("name"),
				};
				if (findCheckingDB) {
				} else {
					if (checkData && checkData.value !== searchselectBox.checked) {
						newData.push(theValueBox);
					}
				}
			}
		}
		const posting = await NamePropertyTrackChanges(newData, theformId);
		return posting;
	}

	let allTitle;
	const formReady = async (form) => {
		$(async function () {
			//form.io built-in (standard)
			setFetchingform(false);
			currentForm = form;
			currentForm.nosubmit = true;
			setThisForm(currentForm);
			const getAllTitle = getTitle(currentForm, theformId, "WHTSCRForm");
			allTitle = getAllTitle;

			// Fetch form, so that data are populated correctly.
			//if (appFormJson.current.data) {
			if (_id) {
				/*
        When Save as Draft, we do not override the data from the subsection (new vessel) to the main section (old vessel).
        In this case, the updateNewVesselinNewCR() function will not be called.
        We need to show both subsection and main section
        */
				currentForm.data = appFormJson.current.data;

				// Borrowers Details
				//-- Element after index 0: Modifiable (updated)
				let borrowersDetail = Utils.searchComponents(currentForm.components, {
					type: "textfield",
				});
				borrowersDetail.forEach((borrower) => {
					if (borrower.key === "borrowerName") borrower.disabled = false;
					else if (borrower.key === "uniqueEntityNumber")
						borrower.disabled = false;
					else if (borrower.key === "country") borrower.disabled = false;
					else if (borrower.key === "postalCode") borrower.disabled = false;
					else if (borrower.key === "block") borrower.disabled = false;
					else if (borrower.key === "street") borrower.disabled = false;
					else if (borrower.key === "floor") borrower.disabled = false;
					else if (borrower.key === "unit") borrower.disabled = false;
					else if (borrower.key === "buildingName") borrower.disabled = false;
				});

				try {
					//Swaps checkbox validation
					SwapsCheckbox(currentForm);

					whtExemption_startDate =
						currentForm.data.containerQualifyingDetails
							.containerQualifyingPeriod.effectiveDateOfWHTExemption;
					whtExemption_endDate =
						currentForm.data.containerQualifyingDetails
							.containerQualifyingPeriod.endDate;
				} catch (e) {
					ConsoleError(e);
				}
			} else {
				appFormJson.current = updateNewVesselinNewCR(appFormJson.current);

				let info = appFormJson.current.data;
				// Borrowers Details
				//-- Element after index 0: Modifiable (updated)
				let borrowers_Detail = Utils.searchComponents(currentForm.components, {
					type: "textfield",
				});
				borrowers_Detail.forEach((borrower) => {
					if (borrower.key === "borrowerName") borrower.disabled = false;
					else if (borrower.key === "uniqueEntityNumber")
						borrower.disabled = false;
					else if (borrower.key === "country") borrower.disabled = false;
					else if (borrower.key === "postalCode") borrower.disabled = false;
					else if (borrower.key === "block") borrower.disabled = false;
					else if (borrower.key === "street") borrower.disabled = false;
					else if (borrower.key === "floor") borrower.disabled = false;
					else if (borrower.key === "unit") borrower.disabled = false;
					else if (borrower.key === "buildingName") borrower.disabled = false;
				});

				try {
					currentForm.data = info;
					// "CR's main form", info  // end of HW's change
					currentForm.submission.data["mainFormId"] = mainSubmissionId;
					currentForm.submission.data["schemaExtension"] = 3;
					currentForm.submission.data.configContainer["applicationCaseId"] = ""; //Clear the main form case Id
					// currentForm.submission.data.configContainer['companyInvolved'] = info?.applicantName;
					existingEntityType =
						info.containerParticulars.containerBorrower["entityType"];
					whtExemption_startDate = moment(
						info.containerQualifyingDetails.containerQualifyingPeriod[
							"effectiveDateOfWHTExemption"
						],
					).format("DD-MMM-YYYY");
					whtExemption_endDate = moment(
						info.containerQualifyingDetails.containerQualifyingPeriod[
							"endDate"
						],
					).format("DD-MMM-YYYY");

					//reset declaration
					currentForm.submission.data.containerDeclarationAndUndertaking[
						"DeclarationAndAgreement"
					] = false;
					currentForm.submission.data.containerDeclarationAndUndertaking.selectBoxUndertakings = false;
					currentForm.submission.data["cpkName"] = "";
					currentForm.submission.data["cpkEmail"] = "";
					currentForm.submission.data["designation"] = "";
					currentForm.submission.data["contactNo"] = "";
					currentForm.submission.data["agreeToAll"] = false;

					//Swaps checkbox validation
					SwapsCheckbox(currentForm);

					whtExemption_startDate =
						info.containerQualifyingDetails.containerQualifyingPeriod
							.effectiveDateOfWHTExemption;
					whtExemption_endDate =
						info.containerQualifyingDetails.containerQualifyingPeriod.endDate;
				} catch (e) {
					ConsoleError(e);
				}

				//try this
				if (mainSubmissionId && existingEntityType === "r1") {
					//hide r2
					$(".formio-component-srsMsiInfo").css({ display: "none" });
					$(".formio-component-srsMsiContainer").css({ display: "none" });
					//hide r3
					$(".formio-component-srsMsiShipInfo").css({ display: "none" });
					$(".formio-component-srsMsiShipContainer").css({ display: "none" });
				} else if (mainSubmissionId && existingEntityType === "r2") {
					//hide r1
					$(".formio-component-srsInfo").css({ display: "none" });
					$(".formio-component-srsContainer").css({ display: "none" });

					//hide r3
					$(".formio-component-srsMsiShipInfo").css({ display: "none" });
					$(".formio-component-srsMsiShipContainer").css({ display: "none" });
				} else if (mainSubmissionId && existingEntityType === "r3") {
					//hide r1
					$(".formio-component-srsInfo").css({ display: "none" });
					$(".formio-component-srsContainer").css({ display: "none" });

					//hide r2
					$(".formio-component-srsMsiInfo").css({ display: "none" });
					$(".formio-component-srsMsiContainer").css({ display: "none" });
				}
				
				GenericHandleDefaultCountry_BorrowerParticulars(currentForm);

				// Switch vessel details datagrid to locked as this is a new CR form, we lock all the initial datagrid element data we got from mainship
				handleCustomInitVesselDetailsState_ForNewCRForm();
			}

			if (getStatus === "All") {
				setStatus("draft");
				prevStatus = "draft";
			} else {
				const statusTextCurrent = getStatusText(
					currentForm.submission.data.configContainer.publicApplicationStatus,
				);
				setStatus(statusTextCurrent);
				prevStatus = statusTextCurrent;
			}

			if (prevStatus === "Returned" && theformId !== null) {
				resultComponent = flatComponent(currentForm);
			}

			// below 1 is draft status, 9 is returned status
			// only draft and returned back-end statuses can edit contents of this form
			if (
				currentForm.submission.data.configContainer.publicApplicationStatus !==
					1 &&
				currentForm.submission.data.configContainer.publicApplicationStatus !==
					9 &&
				currentForm.submission.data.configContainer.applicationStatus !== "1" &&
				currentForm.submission.data.configContainer.applicationStatus !== "9" &&
				_id !== null
			) {
				canSubmit = false;
				submitID_Ref.current = createdData._id;
				form_ref.current = currentForm;
				goToReview();
			}

			//declaration
			// debugger
			declarationHTMLTEST =
				currentForm.submission.data.containerDeclarationAndUndertaking
					.textAreaDeclaration;
			populateDeclarationAndUndertaking();

			if (createdData._id) {
				submitID_Ref.current = createdData._id;
			}

			//  Change Data Grid Add Button
			Utils.searchComponents(form.components, { type: "datagrid" }).forEach(
				(instance) => {
					if (instance?.component) {
						instance.component.addAnother = "Add On";
					}
				},
			);

			// Applicant information
			let applicant = loginHelper.getLoginUser();
			if (
				applicant?.Name !== currentForm.submission.data["contactName"] ||
				currentForm.submission.data["contactName"] === ""
			) {
				currentForm.submission.data.configContainer["usersInvolved"] =
					applicant?.Name;
				currentForm.submission.data.configContainer["companyInvolved"] =
					applicant?.CompanyName;
				currentForm.submission.data.configContainer["applicationCaseId"] = "";
				currentForm.submission.data["contactName"] = applicant?.Name;
				currentForm.submission.data["contactEmail"] = applicant?.Email;
			}

			CommonPopulateQuestionEDH(currentForm, selected, applicant.UEN);

			// Event Listener: Execute this event inside when you change something in a component
			currentForm.on("change", (changed) => {
				if (changed.changed?.component.key === "effectiveDateOfWHTExemption") {
					whtExemption_startDate = changed.changed.value;
					populateDeclarationAndUndertaking();
				} else if (changed.changed?.component.key === "periodOfFinancingTo") {
					whtExemption_endDate = changed.changed.value;
					populateDeclarationAndUndertaking();
				} else if (changed.changed?.component.key === "endDate") {
					whtExemption_endDate = changed.changed.value;
					populateDeclarationAndUndertaking();
				}

				//-- Event: Checkbox changed for IMO  (in SHIP form)
				else if (changed.changed?.component.key === "cbIMO") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.CHECKBOX_NO_IMO,
					);

					if (foundIndex !== -1) {
						// Reset partial fields
						helperClearVesselParticularsDatagridFields(foundIndex, false);
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.statusVesselDetail =
							VesselDetailsStatus.CHECKED_NO_IMO_NUMBER;

						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//-- Event: Checkbox changed for Replace IMO (in SHIP form)
				else if (changed.changed?.component.key === "edit_cbIMO") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.CHECKBOX_NO_IMO_FOR_EDIT,
					);

					let imoNo =
						currentForm.submission.data.containerParticulars.containerShipVessel
							.dataGridVessel1[foundIndex].containerDataGrid.edit_imoNumber;

					if (!imoNo) {
						return;
					}

					if (foundIndex !== -1) {
						//-- Reset partial fields
						helperClearVesselParticularsEditDatagridFields(foundIndex, false);
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.edit_statusVesselDetail =
							VesselDetailsStatus.CHECKED_NO_IMO_NUMBER;

						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//-- Event: Checkbox changed for IMO  (in SHIP form)
				else if (changed.changed?.component.key === "replacement_cbIMO") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.CHECKBOX_NO_IMO_FOR_REPLACEMENT,
					);

					if (foundIndex !== -1) {
						// Reset partial fields
						helperClearVesselParticularsReplaceDatagridFields(
							foundIndex,
							false,
						);
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.replacement_statusVesselDetail =
							VesselDetailsStatus.CHECKED_NO_IMO_NUMBER;

						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//--Event: Input changed for Flag of Registry (in SHIP form)
				else if (changed.changed?.component.key === "countries") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.FIELD_OF_REGISTRY,
					);

					if (foundIndex !== -1) {
						// Clear field when it's non-SG
						if (
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.countries?.toLowerCase() !== WHT_CONSTANT.SINGAPORE
						) {
							helperClearVesselParticularsDatagridFields(foundIndex, false);
						}

						// Reset Statis
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.statusVesselDetail = VesselDetailsStatus.EMPTY;

						// Apply changes to metadata
						CommonHandleFlagOfRegistryMetadataFix(currentForm, foundIndex);

						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//--Event: Input changed for Flag of Registry (in SHIP form)
				else if (changed.changed?.component.key === "edit_countries") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.FIELD_OF_REGISTRY,
					);

					if (foundIndex !== -1) {
						// Clear field when it's non-SG
						if (
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_countries?.toLowerCase() !== WHT_CONSTANT.SINGAPORE
						) {
							helperClearVesselParticularsEditDatagridFields(foundIndex, false);
						}

						// Reset status
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.edit_statusVesselDetail =
							VesselDetailsStatus.EMPTY;

						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//--Event: Input changed for Flag of Registry (in SHIP form)
				else if (changed.changed?.component.key === "replacement_countries") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.FIELD_OF_REGISTRY,
					);

					if (foundIndex !== -1) {
						// Clear field when it's non-SG
						if (
							currentForm.submission.data.containerParticulars
								.containerShipVessel.dataGridVessel1[foundIndex]
								.containerDataGrid.replacement_countries?.toLowerCase() !== WHT_CONSTANT.SINGAPORE
						) {
							helperClearVesselParticularsReplaceDatagridFields(
								foundIndex,
								false,
							);
						}

						// Reset status
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.replacement_statusVesselDetail =
							VesselDetailsStatus.EMPTY;

						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance._data,
							currentForm,
							ComponentFieldType.NORMAL_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//-- Event: Input changed for IMO number (in SHIP form)
				else if (changed.changed?.component.key === "imoNumber") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.IMO_NUMBER,
					);

					// Hide component->"Date of Registration in Singapore Registry of Ships" and clear partial fields according to the requirements
					if (foundIndex !== -1) {
						let imoNo =
							currentForm.submission.data.containerParticulars
								.containerShipVessel.dataGridVessel1[foundIndex]
								.containerDataGrid.imoNumber;

						if (!imoNo) {
							return;
						}

						if (CommonVesselDatagrid_IsImoNumber_Valid(imoNo) === false) {
							// Reset partial fields to empty
							helperClearVesselParticularsDatagridFields(foundIndex, false);
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.statusVesselDetail =
								VesselDetailsStatus.EMPTY;

							// Update formIO side to trigger change event and redraw ui event
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance._data,
								currentForm,
								ComponentFieldType.NORMAL_COLUMN_TOP,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance._data,
								currentForm,
								ComponentFieldType.NORMAL_COLUMN_BOTTOM,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
						} else {
							let duplicatedIndex =
								CommonVesselDatagrid_GetDuplicatedComponent_Index(
									changed.changed.instance._data,
									currentForm,
									ImoType.NORMAL,
								);

							if (duplicatedIndex !== -1) {
								// Popout dialogue to show duplicated
								toggleVesselDialog(
									true,
									VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER,
								);

								// Reset partial fields to empty
								helperClearVesselParticularsDatagridFields(foundIndex, false);
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.imoNumber = "";
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.statusVesselDetail =
									VesselDetailsStatus.EMPTY;

								// Update formIO side to trigger change event and redraw ui event
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance._data,
									currentForm,
									ComponentFieldType.NORMAL_COLUMN_TOP,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance._data,
									currentForm,
									ComponentFieldType.NORMAL_COLUMN_BOTTOM,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
							}
						}
					}
				}

				//-- Event: Input changed for IMO number (in SHIP form)
				else if (changed.changed?.component.key === "edit_imoNumber") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.IMO_NUMBER,
					);

					if (foundIndex !== -1) {
						let imoNo =
							currentForm.submission.data.containerParticulars
								.containerShipVessel.dataGridVessel1[foundIndex]
								.containerDataGrid.edit_imoNumber;

						if (!imoNo) {
							return;
						}

						if (CommonVesselDatagrid_IsImoNumber_Valid(imoNo) === false) {
							// Reset partial fields to empty
							helperClearVesselParticularsEditDatagridFields(foundIndex, false);
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_statusVesselDetail =
								VesselDetailsStatus.EMPTY;

							// Update formIO side to trigger change event and redraw ui event
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance._data,
								currentForm,
								ComponentFieldType.EDIT_COLUMN_TOP,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance._data,
								currentForm,
								ComponentFieldType.EDIT_COLUMN_BOTTOM,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
						} else {
							let duplicatedIndex =
								CommonVesselDatagrid_GetDuplicatedComponent_Index(
									changed.changed.instance._data,
									currentForm,
									ImoType.EDIT,
								);

							// Duplicated found
							if (duplicatedIndex !== -1) {
								// Popout dialogue to show duplicated
								toggleVesselDialog(
									true,
									VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER,
								);

								// Reset partial fields to empty
								helperClearVesselParticularsEditDatagridFields(
									foundIndex,
									false,
								);
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.edit_imoNumber = "";
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.edit_statusVesselDetail =
									VesselDetailsStatus.EMPTY;

								// Update formIO side to trigger change event and redraw ui event
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance._data,
									currentForm,
									ComponentFieldType.EDIT_COLUMN_TOP,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance._data,
									currentForm,
									ComponentFieldType.EDIT_COLUMN_BOTTOM,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
							}
						}
					}
				}

				//-- Event: Input changed for IMO number (in SHIP form)
				else if (changed.changed?.component.key === "replacement_imoNumber") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.IMO_NUMBER,
					);

					if (foundIndex !== -1) {
						let imoNo =
							currentForm.submission.data.containerParticulars
								.containerShipVessel.dataGridVessel1[foundIndex]
								.containerDataGrid.replacement_imoNumber;

						if (!imoNo) {
							return;
						}

						if (CommonVesselDatagrid_IsImoNumber_Valid(imoNo) === false) {
							// Reset partial fields to empty
							helperClearVesselParticularsReplaceDatagridFields(
								foundIndex,
								false,
							);
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.replacement_statusVesselDetail =
								VesselDetailsStatus.EMPTY;

							// Update formIO side to trigger change event and redraw ui event
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance._data,
								currentForm,
								ComponentFieldType.REPLACEMENT_COLUMN_TOP,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance._data,
								currentForm,
								ComponentFieldType.REPLACEMENT_COLUMN_BOTTOM,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
						} else {
							let duplicatedIndex =
								CommonVesselDatagrid_GetDuplicatedComponent_Index(
									changed.changed.instance._data,
									currentForm,
									ImoType.REPLACE,
								);

							if (duplicatedIndex !== -1) {
								// Popout dialogue to show duplicated
								toggleVesselDialog(
									true,
									VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER,
								);

								// Reset partial fields to empty
								helperClearVesselParticularsReplaceDatagridFields(
									foundIndex,
									false,
								);
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.replacement_imoNumber = "";
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.replacement_statusVesselDetail =
									VesselDetailsStatus.EMPTY;

								// Update formIO side to trigger change event and redraw ui event
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance._data,
									currentForm,
									ComponentFieldType.REPLACEMENT_COLUMN_TOP,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance._data,
									currentForm,
									ComponentFieldType.REPLACEMENT_COLUMN_BOTTOM,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
							}
						}
					}
				}

				//--Event: Input changed for Borrower DataGrid (in SHIP form)
				else if (changed.changed?.component.key === "dataGridBorrower") {
					// Hide the css of datagridBorrower first delete btn
					removeBorrowerFirstDeleteButton();
				}

				//--Event: Input changed for Borrower DataGrid (in SHIP form)
				else if (changed.changed?.component.key === "dataGridVessel1") {
					// Hide the css of datagridBorrower first delete btn
					removeVesselDetailsDeleteButton_ForRecordsFromMainForm();
				}

				//-- Event: Checkbox value changed for Edit vessel (in SHIP form)
				else if (changed.changed?.component.key === "editVessel") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.CONTAINER_FOR_EDIT_CHECKBOX,
					);
					if (changed.changed.instance._data.editVessel) {
						if (foundIndex !== -1) {
							// Carry over value from original
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_countries =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.countries;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_vesselName =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.vesselName;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_grossTonnage =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.grossTonnage;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_deliveryDate =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.deliveryDate3;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_typeOfVesselDescription =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.typeOfVesselDescription;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_cbIMO =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.cbIMO;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_imoNumber =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.imoNumber;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_vesselCost =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.vesselCost;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_netTonnage =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.netTonnage;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_dateOfRegistration =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.dateOfRegistration;
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_yearBuilt =
								currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
									foundIndex
								].containerDataGrid.yearBuilt;

							// Update the status
							let newStatus = VesselDetailsStatus.EMPTY;
							switch (
								currentForm.submission.data.containerParticulars
									.containerShipVessel.dataGridVessel1[foundIndex]
									.containerDataGrid.statusVesselDetail
							) {
								case VesselDetailsStatus.LOCKED_SRS_NOT_FOUND:
									newStatus = VesselDetailsStatus.SRS_NOT_FOUND;
									break;

								case VesselDetailsStatus.LOCKED:
									newStatus = VesselDetailsStatus.SRS_FOUND;
									break;

								default:
									newStatus = VesselDetailsStatus.EMPTY;
									break;
							}
							currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
								foundIndex
							].containerDataGrid.edit_statusVesselDetail = newStatus;

							// Update formIO side to trigger change event and redraw ui event
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance.parent._data,
								currentForm,
								ComponentFieldType.EDIT_COLUMN_TOP,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
							CommonVesselDatagrid_GetComponent_Update(
								changed.changed.instance.parent._data,
								currentForm,
								ComponentFieldType.EDIT_COLUMN_BOTTOM,
								ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
							);
						}
					} else {
						// Upon un-checked, required to reset metadata to "country" instead of "edit_countries" as it affects the dropdown UI display.
						let metaElement =
							currentForm?.submission?.metadata?.selectData
								?.containerParticulars?.containerShipVessel?.dataGridVessel1?.[
								foundIndex
							]?.containerDataGrid?.[foundIndex];
						if (metaElement?.edit_countries?.data?.country !== undefined) {
							metaElement.edit_countries.data.country =
								metaElement?.countries?.data.country;
						}
						// Clear all fields upon unchecked.
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.edit_statusVesselDetail =
							VesselDetailsStatus.EMPTY;
						helperClearVesselParticularsEditDatagridFields(foundIndex, true);
						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance.parent._data,
							currentForm,
							ComponentFieldType.EDIT_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance.parent._data,
							currentForm,
							ComponentFieldType.EDIT_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//-- Event: Checkbox value changed for Delete vessel (in SHIP form)
				else if (changed.changed?.component.key === "deleteVessel") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.CONTAINER_FOR_EDIT_CHECKBOX,
					);

					if (changed.changed.instance._data.deleteVessel == false) {
						if (foundIndex !== -1) {
							let duplicatedIndex =
								CommonVesselDatagrid_GetDuplicatedComponent_Index(
									changed.changed.instance.parent._data,
									currentForm,
									ImoType.DELETE,
								);

							if (duplicatedIndex !== -1) {
								changed.changed.instance._data.deleteVessel = true;

								// Update formIO side to trigger change event and redraw ui event
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance._data,
									currentForm,
									ComponentFieldType.DELETE_VESSEL_CHECKBOX,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance.parent._data,
									currentForm,
									ComponentFieldType.NORMAL_COLUMN_TOP,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);
								CommonVesselDatagrid_GetComponent_Update(
									changed.changed.instance.parent._data,
									currentForm,
									ComponentFieldType.NORMAL_COLUMN_BOTTOM,
									ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
								);

								toggleVesselDialog(
									true,
									VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER,
								);
							}
						}
					}
				}

				//-- Event: Checkbox value changed for Replace vessel (in SHIP form)
				else if (changed.changed?.component.key === "replaceVesselButton") {
					let foundIndex = CommonVesselDatagrid_GetComponent_Index(
						changed.changed.instance._data,
						currentForm,
						ComponentFieldType.CONTAINER_FOR_EDIT_CHECKBOX,
					);
					if (changed?.changed?.instance?._data !== undefined) {
						// Clear regardless of check/unchecked
						currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
							foundIndex
						].containerDataGrid.replacement_statusVesselDetail =
							VesselDetailsStatus.EMPTY;
						helperClearVesselParticularsReplaceDatagridFields(foundIndex, true);
						// Update formIO side to trigger change event and redraw ui event
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance.parent._data,
							currentForm,
							ComponentFieldType.REPLACEMENT_COLUMN_TOP,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
						CommonVesselDatagrid_GetComponent_Update(
							changed.changed.instance.parent._data,
							currentForm,
							ComponentFieldType.REPLACEMENT_COLUMN_BOTTOM,
							ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
						);
					}
				}

				//-- Event: Input changed for Date of Transfer cert (in SHIP form)
				else if (changed.changed?.component.key === "dateOfTransferCert") {
					if (
						currentForm.submission.data.containerNonTax.containerLender
							.dateOfTransferCert === "" ||
						currentForm.submission.data.containerNonTax.containerLender
							.dateOfTransferCert === null
					) {
						currentForm.submission.data.containerNonTax.containerLender.totalAmountTransferred =
							"";

						const totalAmountTransferredComp = currentForm.getComponent(
							"totalAmountTransferred",
						);
						totalAmountTransferredComp.triggerChange();
						totalAmountTransferredComp.triggerRedraw();
					}
				}

				//-- Event: Input changed for Date of Signed Agreement
				else if (
					changed.changed?.component.key ===
					"dateOfSignedAgreementTransferCertificate"
				) {
					//[1] Period Of Financing From
					const periodOfFinancingFromComp = currentForm.getComponent(
						"periodOfFinancingFrom",
					);
					let datePeriod = moment(
						currentForm.submission.data.containerNonTax.containerLender
							.periodOfFinancingFrom ?? 0,
					);
					let datePeriodMinDate = moment(
						periodOfFinancingFromComp.component.datePicker.minDate ?? 0,
					);

					// Handle reset period of financing from
					if (datePeriodMinDate !== 0 && datePeriod !== 0) {
						if (datePeriod < datePeriodMinDate) {
							currentForm.submission.data.containerNonTax.containerLender.periodOfFinancingFrom =
								null;
							periodOfFinancingFromComp.triggerChange();
							periodOfFinancingFromComp.triggerRedraw();
						}
					}

					DateTransferCertShip(currentForm);

					//[2] Date of Earilest Qualifying Payment
					const dateEarliestQualifyingPaymentComp = currentForm.getComponent(
						"dateEarliestQualifyingPayment",
					);
					let dateEarliestQualifying = moment(
						currentForm.data.containerQualifyingDetails
							.containerQualifyingPayment.dateEarliestQualifyingPayment ?? 0,
					);
					let datedateEarliestQualifyingMinDate = moment(
						dateEarliestQualifyingPaymentComp.component.datePicker.minDate ?? 0,
					);

					// Handle reset period of financing from
					if (
						datedateEarliestQualifyingMinDate !== 0 &&
						dateEarliestQualifying !== 0
					) {
						if (dateEarliestQualifying < datedateEarliestQualifyingMinDate) {
							currentForm.submission.data.containerQualifyingDetails.containerQualifyingPayment.dateEarliestQualifyingPayment =
								null;
							dateEarliestQualifyingPaymentComp.triggerChange();
							dateEarliestQualifyingPaymentComp.triggerRedraw();
						}
					}
				} else if (
					changed.changed?.component.key === "totalAmountFinanced" ||
					changed.changed?.component.key === "totalAmountTransferred"
				) {
					CommonUpdateValidation_TotalNonQualifyingAmountFinanced(currentForm);
				} else if (
					changed.changed?.component.key ===
						"totalNonQualifyingInterestPaymentCost" ||
					changed.changed?.component.key ===
						"totalNonQualifyingRelatedPaymentsCost"
				) {
					CommonUpdateValidation_NonQualifyingAmountFinancedCost(currentForm);
				} else if (
					changed.changed?.component.key === "selectBoxesQFA_1" ||
					changed.changed?.component.key === "selectBoxesQFA_2" ||
					changed.changed?.component.key === "selectBoxesQFA_3"
				) {
					CommonUpdateValidation_TypeOfFinancingArrangementObtained(
						currentForm,
					);
				} else if (
					changed.changed?.component.key === "checkboxMoreThanFiftyVessels"
				) {
					handleCheckboxUploadVesselsToggle();
				}
				CommonHandleWHTShipDate(currentForm, changed);
				GenericFileUploadOnChange(changed, false, currentForm, setStartSpinner);
			});

			getPreData();
			currentForm.on("prevPage", async () => {
				setOpenAlert();
				setStartSpinner(true);
				const sendData = await getPrevData();

				if (sendData !== "noSave") {
					// Create/Overwrite current form data
					const responseStatus = await createOrSave(
						"draft",
						currentForm,
						alreadySubmitted,
						createdData,
						saveCreatedData,
						createURL,
						appFormBaseURL,
						formId,
					);

					if (responseStatus.status === 200 || responseStatus.status === 201) {
						setStartSpinner(false);
						// Navigate to prev page of current form
						currentForm.setPage(currentForm.page - 1);
					} else {
						setStartSpinner(false);
						//pop up and show error message
						checkErrorStatusAndOpenErrorStatusDialog();
					}
				} else {
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}

				if (prevStatus === "Returned") {
					getPreData();
				}
			});

			//--Event: Fetch data from backend -> SRS
			currentForm.on("SearchByIMONumberEvent", async (e) => {
				/* Read this first: what is inside (e)?
                    Object: {
                        cbIMO: false,
                        certNumContainer: {certificationNumber: '', certNum-search: false},
                        countries: "Singapore",
                        dateOfRegistration: "",
                        deliveryDate3: "",
                        grossTonnage: "",
                        imoNumber: "",
                        netTonnage: "",
                        search: true,
                        vesselName: "",
                        [[Prototype]] }
                */
				// [Valid]: imoNumber (Client-side validation)
				if (CommonVesselDatagrid_IsImoNumber_Valid(e.imoNumber)) {
					retrieveSGVesselDetailsFromSRS(e);
				}
				// [Invalid]: imoNumber (Client-side validation)
				else {
					// Show feedback dialogue
					toggleVesselDialog(true, VesselDialogType.ERROR_INVALID_IMO_NUMBER);

					// Clear selected imo number
					let selectedDgIndex = CommonVesselDatagrid_GetComponent_Index(
						e,
						currentForm,
						ComponentFieldType.IMO_NUMBER,
					);
					helperClearVesselParticularsDatagridFields(selectedDgIndex, false);

					// Update UI
					CommonVesselDatagrid_GetComponent_Update(
						e,
						currentForm,
						ComponentFieldType.NORMAL_COLUMN_TOP,
						ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
					);
					CommonVesselDatagrid_GetComponent_Update(
						e,
						currentForm,
						ComponentFieldType.NORMAL_COLUMN_BOTTOM,
						ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
					);
				}
			});

			//--Event: Fetch data from backend -> SRS
			currentForm.on("SearchByEditIMONumberEvent", async (e) => {
				/* Read this first: what is inside (e)?
                    Object: {
                        cbIMO: false,
                        certNumContainer: {certificationNumber: '', certNum-search: false},
                        countries: "Singapore",
                        dateOfRegistration: "",
                        deliveryDate3: "",
                        grossTonnage: "",
                        imoNumber: "",
                        netTonnage: "",
                        search: true,
                        vesselName: "",
                        [[Prototype]] }
                */
				// [Valid]: imoNumber (Client-side validation)
				if (CommonVesselDatagrid_IsImoNumber_Valid(e.edit_imoNumber)) {
					retrieveEditSGVesselDetailsFromSRS(e);
				}
				// [Invalid]: imoNumber (Client-side validation)
				else {
					// Show feedback dialogue
					toggleVesselDialog(true, VesselDialogType.ERROR_INVALID_IMO_NUMBER);

					// Clear selected imo number
					let selectedDgIndex = CommonVesselDatagrid_GetComponent_Index(
						e,
						currentForm,
						ComponentFieldType.IMO_NUMBER,
					);
					helperClearVesselParticularsEditDatagridFields(
						selectedDgIndex,
						false,
					);

					// Update UI
					CommonVesselDatagrid_GetComponent_Update(
						e,
						currentForm,
						ComponentFieldType.EDIT_COLUMN_TOP,
						ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
					);
					CommonVesselDatagrid_GetComponent_Update(
						e,
						currentForm,
						ComponentFieldType.EDIT_COLUMN_BOTTOM,
						ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
					);
				}
			});

			//--Event: Fetch data from backend -> SRS
			currentForm.on("SearchByReplaceIMONumberEvent", async (e) => {
				/* Read this first: what is inside (e)?
                    Object: {
                        cbIMO: false,
                        certNumContainer: {certificationNumber: '', certNum-search: false},
                        countries: "Singapore",
                        dateOfRegistration: "",
                        deliveryDate3: "",
                        grossTonnage: "",
                        imoNumber: "",
                        netTonnage: "",
                        search: true,
                        vesselName: "",
                        [[Prototype]] }
                */
				// [Valid]: imoNumber (Client-side validation)
				if (CommonVesselDatagrid_IsImoNumber_Valid(e.replacement_imoNumber)) {
					retrieveReplaceSGVesselDetailsFromSRS(e);
				}
				// [Invalid]: imoNumber (Client-side validation)
				else {
					// Show feedback dialogue
					toggleVesselDialog(true, VesselDialogType.ERROR_INVALID_IMO_NUMBER);

					// Clear selected imo number
					let selectedDgIndex = CommonVesselDatagrid_GetComponent_Index(
						e,
						currentForm,
						ComponentFieldType.IMO_NUMBER,
					);
					helperClearVesselParticularsReplaceDatagridFields(
						selectedDgIndex,
						false,
					);

					// Update UI
					CommonVesselDatagrid_GetComponent_Update(
						e,
						currentForm,
						ComponentFieldType.REPLACEMENT_COLUMN_TOP,
						ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
					);
					CommonVesselDatagrid_GetComponent_Update(
						e,
						currentForm,
						ComponentFieldType.REPLACEMENT_COLUMN_BOTTOM,
						ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
					);
				}
			});

			//-- Event: Press prev page button, create/overwrite current form and navigate to next page
			currentForm.on("nextPage", async () => {
				$('button:contains("Next")').hide();
				setOpenAlert();
				setStartSpinner(true);
				const sendData = await getPrevData();

				// searchElementsNew()

				if (sendData !== "noSave") {
					// Create/Overwrite current form data
					const responseStatus = await createOrSave(
						"draft",
						currentForm,
						alreadySubmitted,
						createdData,
						saveCreatedData,
						createURL,
						appFormBaseURL,
						formId,
					);

					// Only if ^ action is successful
					if (responseStatus.status === 200 || responseStatus.status === 201) {
						setStartSpinner(false);
						// Navigate to next page of current form
						currentForm.setPage(currentForm.page + 1);
						submitID_Ref.current = createdData._id;
						if (responseStatus.message.data.configContainer.applicationCaseId) {
							setCaseID(
								responseStatus.message.data.configContainer.applicationCaseId,
							);
							reviewCaseID =
								responseStatus.message.data.configContainer.applicationCaseId;
						}
					} else {
						setStartSpinner(false);
						// pop up and show error message
						checkErrorStatusAndOpenErrorStatusDialog();
					}
				} else {
					setStartSpinner(false);
					checkErrorStatusAndOpenErrorStatusDialog();
				}
				if (prevStatus === "Returned") {
					getPreData();
				}
			});

			currentForm.on("draftSave", async () => {
				const entityTypeDraftSave =
					currentForm.submission.data.containerParticulars.containerBorrower
						.entityType;
				CommonHandleAllDropdownListMetadataFixV1(
					currentFormref.current,
					entityTypeDraftSave,
				);

				$('button:contains("Next")').hide();
				setStartSpinner(true);

				const savingDraft = await createOrSave(
					"draft",
					currentForm,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);

				if (savingDraft.status === 200 || savingDraft.status === 201) {
					$('button:contains("Next")').show();
					setStartSpinner(false);
					setOpenAlert("Draft");
					setOpen(true);
				} else {
					$('button:contains("Next")').show();
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}
			});

			//-- Event: Press review button, overwrite current form data then validate the form fields, then navigate to review page
			currentForm.on("review", async () => {
				const entityTypeReview =
					currentForm?.submission?.data?.containerParticulars?.containerBorrower
						?.entityType;
				CommonHandleAllDropdownListMetadataFixV1(
					currentFormref.current,
					entityTypeReview,
				);

				$('button:contains("Next")').hide();
				allowScrolling
					? window.scrollTo(0, 0)
					: setScrollState(() => (allowScrolling = true));

				setStartSpinner(true);

				const savingChangesData = await getPrevData();

				const responseStatus = await createOrSave(
					"draft",
					currentForm,
					alreadySubmitted,
					createdData,
					saveCreatedData,
					createURL,
					appFormBaseURL,
					formId,
				);

				// Only if ^ action is successful
				if (
					(responseStatus.status === 200 || responseStatus.status === 201) &&
					savingChangesData !== "noSave"
				) {
					$('button:contains("Next")').show();
					setCaseID(
						responseStatus.message.data.configContainer.applicationCaseId,
					);
					// Check if valid, go to review page, if not, show dialog to feedback, there are still so errors
					submitID_Ref.current = createdData._id;
					validityTriggered = true;
					let valCheck = checkValidity();
					if (valCheck === false) {
						form_ref.current = currentForm;
						setOpenValidationDialog(true);
						setStartSpinner(false);
						getPreData();
					} else {
						/*[11-Jan-2023] Commented based on Jira ticket UAT-132
            var today = moment().format("DD MMM YYYY"); //take today's date
            var effectiveDate = moment(currentForm.submission.data.containerQualifyingDetails.containerQualifyingPeriod['effectiveDateOfWHTExemption']).format("DD MMM YYYY");

            //check if effective date is before the current date
            if (today <= effectiveDate) {
                setOpenDateValidDialog(false);
                form_ref.current = currentForm;
                goToReview();
            } else {
                setOpenDateValidDialog(true);
            }*/
						form_ref.current = currentForm;
						goToReview();
					}
				} else {
					$('button:contains("Next")').show();
					setStartSpinner(false);
					// pop up and show error message
					checkErrorStatusAndOpenErrorStatusDialog();
				}
			});

			//-- Event: Press cancel button, then navigate to Application Listing page
			currentForm.on("cancelPage", () => {
				history.push({
					pathname: "/NestedApplicationListing",
					search: `?schema=2`,
					refresh: true,
				});
			});

			ScheduleCallbackAfterDocumentReadyWithDelay(() => {
				ConsoleInfo("Bind click action to FormIO Page navigation");
				$(".page-link").on("click", async (event) => {
					setOpenAlert();

					const findTitleIndex = allTitle.indexOf(
						event.currentTarget.outerText,
					);

					if (parseInt(currentForm.page) === 0) {
					} else {
						setStartSpinner(true);
						const sendData = await getPrevData();

						if (sendData !== "noSave") {
							//-- Save/overwrite
							const response = await createOrSave(
								"draft",
								currentForm,
								alreadySubmitted,
								createdData,
								saveCreatedData,
								createURL,
								appFormBaseURL,
								formId,
							);

							if (response.status === 200 || response.status === 201) {
								setStartSpinner(false);
								currentForm.setPage(findTitleIndex);
							} else {
								setStartSpinner(false);
								// pop up and show error message
								checkErrorStatusAndOpenErrorStatusDialog();
							}
						} else {
							setStartSpinner(false);
							// pop up and show error message
							checkErrorStatusAndOpenErrorStatusDialog();
						}

						if (prevStatus === "Returned") {
							getPreData();
						}
					}
				});
			});

			currentForm.on("fileUploadingStart", async () => {
				setStartSpinner(true);
			});

			currentForm.on("fileUploadingEnd", async () => {
				setStartSpinner(false);
			});

			currentForm.on("downloadVesselUploadTemplate", async () => {
				TriggerEvent_DownloadVesselUploadSample(
					vesselUploadTemplateRef,
					currentFormref.current,
				);
			});

			$(".card-body").addClass("borderless");

			handleConvertAllUTCDateToLocalDate();
			currentFormref.current = currentForm;
		});
	};

	const handleCheckboxUploadVesselsToggle = () => {
		let changedResult =
			currentForm.submission.data.containerParticulars.containerShipVessel
				.checkboxMoreThanFiftyVessels;
		setOpenUploadVesselsDialog(true);
		setUploadVesselsType(
			changedResult
				? UploadVesselCheckboxType.DATAGRID_TO_EXCEL
				: UploadVesselCheckboxType.EXCEL_TO_DATAGRID,
		);
	};

	const handleConvertAllUTCDateToLocalDate = () => {
		let showDebugDate = false; // Dont delete this variable, let it stay, it's for debugging. Set it to true if u want debug, set it to false to use realtime data
		let testDate = "2023-02-14T00:00:00+08:00";

		//--------------------------------------------------------------------------------
		// Handle All [Datagrids]->[Date/Time Component] here
		//--------------------------------------------------------------------------------
		// Handle Section 1
		{
			// Datagrid->Vessel Particular
			if (
				currentForm.submission.data.containerParticulars?.containerShipVessel
					?.dataGridVessel1 !== undefined &&
				currentForm.submission.data.containerParticulars?.containerShipVessel
					?.dataGridVessel1 !== null
			) {
				let size =
					currentForm.submission.data.containerParticulars?.containerShipVessel
						?.dataGridVessel1?.length ?? 0;

				for (let i = 0; i < size; ++i) {
					let index = i.toString();

					// Vessel Particular -> Delivery Date (Normal)
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"]["deliveryDate3"];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"deliveryDate3"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					// Vessel Particular -> dateOfRegistration (Normal)
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"dateOfRegistration"
							];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"dateOfRegistration"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					// Vessel Particular -> Delivery Date (Edit Vessel)
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"edit_deliveryDate"
							];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"edit_deliveryDate"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					// Vessel Particular -> dateOfRegistration (Edit Vessel)
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"edit_dateOfRegistration"
							];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"edit_dateOfRegistration"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					// Vessel Particular -> Delivery Date (Replace Vessel)
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"replacement_deliveryDate"
							];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"replacement_deliveryDate"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					// Vessel Particular -> dateOfRegistration (Replace Vessel)
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"replacement_dateOfRegistration"
							];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"replacement_dateOfRegistration"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					// Vessel Particular -> Delivery Date (Delete Vessel)
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"]["temp_delivery"];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"][
								"temp_delivery"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					// Vessel Particular -> dateOfRegistration (Delete Vessel)
					{
						let dateUTC =
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"]["dateTime"];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerParticulars"][
								"containerShipVessel"
							]["dataGridVessel1"][index]["containerDataGrid"]["dateTime"] =
								showDebugDate ? testDate : dateLocal;
						}
					}
				}

				//-- Update formIO side to trigger change event and redraw ui event
				const dgComp = currentForm.getComponent("dataGridVessel1");
				dgComp.triggerChange();
				dgComp.triggerRedraw();
			}

			// Dategrid->Details of Non-Tax Resident(s) and the Agreement
			if (
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.dataGridNonTaxResident !== undefined &&
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.dataGridNonTaxResident !== null
			) {
				{
					let size =
						currentForm.submission.data.containerNonTax?.containerNonTaxResident
							?.dataGridNonTaxResident?.length ?? 0;

					for (let i = 0; i < size; ++i) {
						let index = i.toString();

						// Details of Non-Tax Resident(s) and the Agreement -> Date Of Signed Agreement
						let dateUTC =
							currentForm.submission.data["containerNonTax"][
								"containerNonTaxResident"
							]["dataGridNonTaxResident"][index]["containerNonTax"][
								"dateOfSignedAgreement"
							];
						if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
							let dateLocal = new Date(dateUTC);
							currentForm.submission.data["containerNonTax"][
								"containerNonTaxResident"
							]["dataGridNonTaxResident"][index]["containerNonTax"][
								"dateOfSignedAgreement"
							] = showDebugDate ? testDate : dateLocal;
						}
					}

					//-- Update formIO side to trigger change event and redraw ui event
					const dgComp = currentForm.getComponent("dataGridNonTaxResident");
					dgComp.triggerChange();
					dgComp.triggerRedraw();
				}
			}
		}

		//--------------------------------------------------------------------------------
		// Handle All "NON"->[Datagrids]->[Date/Time Component] here
		//--------------------------------------------------------------------------------
		// Handle Section 2
		{
			//-- [Date/Time Component] Handle Date Of Signed Agreement
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.dateOfSignedAgreementTransferCertificate !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.dateOfSignedAgreementTransferCertificate !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfSignedAgreementTransferCertificate"
					];
				let datePropertyName = "dateOfSignedAgreementTransferCertificate";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfSignedAgreementTransferCertificate"
					] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
			//-- [Date/Time Component] Handle Date of Transfer Certificate
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.dateOfTransferCert !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.dateOfTransferCert !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfTransferCert"
					];
				let datePropertyName = "dateOfTransferCert";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"dateOfTransferCert"
					] = showDebugDate ? testDate : dateLocal;
				}

				let selectedComponent = currentForm.getComponent(datePropertyName);
				if (selectedComponent) {
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
			//-- [Date/Time Component] Handle Period of Financing (From)
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingFrom !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingFrom !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingFrom"
					];
				let datePropertyName = "periodOfFinancingFrom";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingFrom"
					] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
			//-- [Date/Time Component] Handle Period of Financing (To)
			if (
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingTo !== undefined &&
				currentForm.submission.data.containerNonTax?.containerLender
					?.periodOfFinancingTo !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingTo"
					];
				let datePropertyName = "periodOfFinancingTo";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"]["containerLender"][
						"periodOfFinancingTo"
					] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Period Covered (From)
			if (
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.datePeriodCoveredFrom !== undefined &&
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.datePeriodCoveredFrom !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"][
						"containerNonTaxResident"
					]["datePeriodCoveredFrom"];
				let datePropertyName = "datePeriodCoveredFrom";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"][
						"containerNonTaxResident"
					]["datePeriodCoveredFrom"] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Period Covered (To)
			if (
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.datePeriodCoveredTo !== undefined &&
				currentForm.submission.data.containerNonTax?.containerNonTaxResident
					?.datePeriodCoveredTo !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerNonTax"][
						"containerNonTaxResident"
					]["datePeriodCoveredTo"];
				let datePropertyName = "datePeriodCoveredTo";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerNonTax"][
						"containerNonTaxResident"
					]["datePeriodCoveredTo"] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
		}

		// Handle Section 3
		{
			//-- [Date/Time Component] Handle Date of earliest relevant qualifying payment made
			if (
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPayment?.dateEarliestQualifyingPayment !==
					undefined &&
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPayment?.dateEarliestQualifyingPayment !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPayment"
					]["dateEarliestQualifyingPayment"];
				let datePropertyName = "dateEarliestQualifyingPayment";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPayment"
					]["dateEarliestQualifyingPayment"] = showDebugDate
						? testDate
						: dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Date of Effective date of WHT exemption
			if (
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.effectiveDateOfWHTExemption !==
					undefined &&
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.effectiveDateOfWHTExemption !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["effectiveDateOfWHTExemption"];
				let datePropertyName = "effectiveDateOfWHTExemption";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["effectiveDateOfWHTExemption"] = showDebugDate
						? testDate
						: dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}

			//-- [Date/Time Component] Handle Date of End date
			if (
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.endDate !== undefined &&
				currentForm.submission.data.containerQualifyingDetails
					?.containerQualifyingPeriod?.endDate !== null
			) {
				let dateUTC =
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["endDate"];
				let datePropertyName = "endDate";
				if (dateUTC !== undefined && dateUTC !== null && dateUTC !== "") {
					let dateLocal = new Date(dateUTC);
					let selectedComponent = currentForm.getComponent(datePropertyName);
					currentForm.submission.data["containerQualifyingDetails"][
						"containerQualifyingPeriod"
					]["endDate"] = showDebugDate ? testDate : dateLocal;
					selectedComponent.triggerChange();
					selectedComponent.triggerRedraw();
				}
			}
		}
	};

	const handleCustomInitVesselDetailsState_ForNewCRForm = () => {
		let size =
			currentForm.submission.data.containerParticulars.containerShipVessel
				.dataGridVessel1?.length;

		for (let i = 0; i < size; ++i) {
			if (
				currentForm.submission.data.containerParticulars.containerShipVessel
					.dataGridVessel1[i].containerDataGrid.statusVesselDetail ==
					VesselDetailsStatus.SRS_FOUND ||
				currentForm.submission.data.containerParticulars.containerShipVessel
					.dataGridVessel1[i].containerDataGrid.statusVesselDetail ==
					VesselDetailsStatus.LOCKED
			) {
				currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
					i
				].containerDataGrid.statusVesselDetail = VesselDetailsStatus.LOCKED;
			} else {
				currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
					i
				].containerDataGrid.statusVesselDetail =
					VesselDetailsStatus.LOCKED_SRS_NOT_FOUND;
			}
		}
		//-- Update formIO side to trigger change event and redraw ui event
		const dgComp = currentForm.getComponent("dataGridVessel1");
		if (dgComp) {
			dgComp.triggerChange();
			dgComp.triggerRedraw();
		}
	};

	const handleCustomRenderEvent = () => {
		//-- Ensure borrower's datagrid->first element->delete button is always hidden
		removeBorrowerFirstDeleteButton();

		//-- Ensure vessel detail's datagrid->(element where records fetched from main form)->delete button is always hidden
		removeVesselDetailsDeleteButton_ForRecordsFromMainForm();

		//-- To update the list of borrowers to be displayed in the declaration page
		updateBorrowersInDeclaration();

		//-- JQuery to overwrite file component functionality to allow file download and delete with JWT Token
		GenericFileUploadOnRender(false, currentForm, setStartSpinner);
	};

	const removeBorrowerFirstDeleteButton = () => {
		//-- Temporary solution: Ensure borrower datagrid first element delete button is always hidden
		// Only run at page 1 where the borrower datagrid exist in.
		if (currentForm.page == 1) {
			let listOfDataGridBorrower_td = $(
				".formio-component-dataGridBorrower",
			).find("td");
			if (listOfDataGridBorrower_td) {
				// >= 5 means it will have more than 2 entries in the datagrid (hardcoded for now),
				if (listOfDataGridBorrower_td?.length >= 5) {
					// the delete html element is located in 2nd <td/>, hence we access listOfDataGridBorrower_td[1]
					listOfDataGridBorrower_td[1].hidden = true;
				}
			}
		}
	};

	const removeVesselDetailsDeleteButton_ForRecordsFromMainForm = () => {
		//-- Temporary solution: Ensure borrower datagrid first element delete button is always hidden
		// Only run at page 1 where the borrower datagrid exist in.
		if (currentForm.page == 1) {
			let listOfDataGridBorrower_td = $(
				".formio-component-dataGridVessel1",
			).find("td");
			if (listOfDataGridBorrower_td) {
				const size = listOfDataGridBorrower_td.length;
				let counter = 0;

				for (
					let i = 0;
					i <
					currentForm.submission.data.containerParticulars.containerShipVessel
						.dataGridVessel1?.length;
					++i
				) {
					if (
						currentForm.submission.data.containerParticulars.containerShipVessel
							.dataGridVessel1[i].containerDataGrid.statusVesselDetail >=
						VesselDetailsStatus.LOCKED
					) {
						++counter;
					}
				}

				for (let i = 1; i < size; i += 2) {
					if (counter > 0) {
						--counter;
						listOfDataGridBorrower_td[i].hidden = true;
					} else {
						break;
					}
				}
			}
		}
	};

	const updateBorrowersInDeclaration = () => {
		if (currentForm.page == 5) {
			populateDeclarationAndUndertaking();
		}
	};

	const onRender = async () => {
		allowScrolling
			? window.scrollTo(0, 0)
			: setScrollState(() => (allowScrolling = true));

		const rendering = await onRenderGeneric(
			setPage,
			currentForm,
			alreadySubmitted,
			createdData,
			saveCreatedData,
			createURL,
			appFormBaseURL,
			formId,
			prevStatus,
		);
		$("ol > li:not(.MuiBreadcrumbs-li)").css("list-style", "lower-alpha");
		$("ol > li > ol > li:not(.MuiBreadcrumbs-li)").css(
			"list-style",
			"lower-roman",
		);
		setTimeout(() => {
			checkValidity();
		}, 0);
		$(function () {
			handleCustomRenderEvent();
		});
		if (rendering === "ok") {
			if (prevStatus === "Returned" || prevStatus === "returned") {
				setStartSpinner(true);
				$('button:contains("Next")').hide();
				$('button:contains("Back")').hide();
				$('button:contains("Save as Draft")').hide();
				$('button:contains("Review")').hide();
			}
		}
	};

	function goToReview() {
		let contactName = form_ref.current._submission.data.contactName;
		history.push({
			pathname: "/WHTShipChangeRequestReview",
			search: `?_id=${submitID_Ref.current}&status=${prevStatus}`,
			// search: `?_id=${submitID_Ref.current}`,
			state: {
				user:
					contactName === ""
						? form_ref.current._submission.data.configContainer.usersInvolved
						: contactName,
				company:
					form_ref.current._submission.data.configContainer.companyInvolved,
				caseID: caseID === null || caseID === undefined ? reviewCaseID : caseID,
				canSubmit: canSubmit,
				prevStatus: prevStatus,
			},
			refresh: true,
		});
	}

	function getPreData() {
		if (prevStatus === "Returned" && theformId !== null) {
			resultComponent = flatComponent(currentForm);
			const preData = scanData(resultComponent);
			const getSearch = searchElements();
			if (preData && getSearch) {
				prevData = preData;
				setTimeout(() => {
					$('button:contains("Next")').show();
					$('button:contains("Back")').show();
					$('button:contains("Review")').show();
					setStartSpinner(false);
				}, 1000);
			}
		}
	}

	async function getPrevData() {
		resultComponent = flatComponent(currentForm);
		const latestData = scanData(resultComponent);
		if (prevStatus === "Returned" && theformId !== null) {
			// await getChanges(resultComponent, latestData, prevData)
			const checkingDB = await getTrackChanges(theformId);
			const searchSave = await searchElementsSave(checkingDB.data.data);
			const TrackChangesSave = await saveChanges(
				resultComponent,
				latestData,
				prevData,
				checkingDB.data.data,
			);
			if (
				[200, 201].includes(searchSave.status) &&
				[200, 201].includes(TrackChangesSave.status)
			) {
				return "ok Success";
			} else {
				return "noSave";
			}
		} else {
			return "no track changes";
		}
	}

	const populateDeclarationAndUndertaking = () => {
		whtExemption_startDate =
			moment(
				currentForm.submission.data.containerQualifyingDetails
					.containerQualifyingPeriod["effectiveDateOfWHTExemption"],
			)?.format("DD-MMM-YYYY") ?? "START_DATE_IS_EMPTY";
		whtExemption_endDate =
			moment(
				currentForm.submission.data.containerQualifyingDetails
					.containerQualifyingPeriod["endDate"],
			)?.format("DD-MMM-YYYY") ?? "END_DATE_IS_EMPTY";

		//-- Display the Main Borrower in Declaration page
		let getMainBorrower = "";

		for (
			let i = 0;
			i <
			currentForm.submission.data.containerParticulars.containerBorrower
				.dataGridBorrower?.length;
			i++
		) {
			getMainBorrower =
				currentForm.submission.data.containerParticulars.containerBorrower
					.dataGridBorrower[0].containerCompanyRegisteredAddress.borrowerName;
		}

		let text = `(a) The WHT exemption is granted with effective from ${whtExemption_startDate} to ${whtExemption_endDate}\n\n(b) The information given or attached in the form is true and correct and that the WHT exemption is granted based on the representations made in this form. Should there be:\n\n     (i) any false or incorrect declaration of information; or\n\n     (ii) any change in material information [e.g. terms and conditions of financing agreement(s), sell down of loan, etc],\n\n    the borrower, ${getMainBorrower} is required to re-submit a new declaration form immediately.  \n\nWhere the qualifying conditions are not met, the WHT exemption granted will be withdrawn with immediate effect and the Government reserves the right to recover the taxes that should be withheld to account for any false or incorrect declarations made or material change in the information provided.  \n\n<b>Under the Singapore Income Tax Act 1947, there are penalties for making a false or incorrect declaration.</b>`;

		currentForm.submission.data.containerDeclarationAndUndertaking.textAreaDeclaration =
			text;

		const textDeclaration = currentForm.getComponent("textAreaDeclaration");
		textDeclaration.triggerChange();
		textDeclaration.triggerRedraw();
	};

	const retrieveSGVesselDetailsFromSRS = async (e) => {
		/* Read this first: what is inside (e)?
      Object: {
          cbIMO: false,
          certNumContainer: {certificationNumber: '', certNum-search: false},
          countries: "Singapore",
          dateOfRegistration: "",
          deliveryDate3: "",
          grossTonnage: "",
          imoNumber: "",
          netTonnage: "",
          search: true,
          vesselName: "",
          [[Prototype]] }
    */

		//-- Temp Variables
		let currentIndex = -1;

		//-- Loop thr entire data grid and see whether there is already an existing IMO number
		let duplicateIndex = CommonVesselDatagrid_GetDuplicatedComponent_Index(
			e,
			currentForm,
			ImoType.NORMAL,
		);

		//-- Find which index are we
		currentIndex = CommonVesselDatagrid_GetComponent_Index(
			e,
			currentForm,
			ComponentFieldType.IMO_NUMBER,
		);

		//-- Duplicate => Display dialog message error
		if (duplicateIndex !== -1) {
			let displayIndex = duplicateIndex + 1; // We are doing this because user dont know index start from 0, we show them layman counting start from 1
			toggleVesselDialog(true, VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER);

			currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
				currentIndex
			].containerDataGrid.imoNumber = "";

			//-- Set this element->state to SRS_FOUND
			currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
				currentIndex
			].containerDataGrid.statusVesselDetail =
				VesselDetailsStatus.SRS_NOT_FOUND;

			//-- Set state
			currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
				currentIndex
			].containerDataGrid.statusVesselDetail = VesselDetailsStatus.EMPTY;

			//-- Reset all fields
			helperClearVesselParticularsDatagridFields(currentIndex, true);
		}
		//-- No duplicate => We call api to check if imo exist on the server side
		else {
			//-- Fetch api using imoNumber and auto populate the details
			// check for valid IMO Number
			try {
				let srsByIMOList = null;
				let vesselDetails = null;

				// SRS Dummy data
				if (SIMULATE_DUMMY_DATA_SRS_API) {
					vesselDetails = {
						vesselParticulars: {
							certificate: [
								{
									registrationDate: "2015-02-09T00:00:00Z",
								},
							],
							netTonnage: 123,
							grossTonnage: 123456789,
							vesselType: "TEST VesselType",
							yearBuilt: 1234,
							vesselName: "TEST vesselName",
						},
					};
				}
				// SRS API Real Data
				else {
					srsByIMOList = await get_WHT_SRS_Info(e.imoNumber);
					srsByIMO = srsByIMOList[0];
					vesselDetails = srsByIMO;
				}

				//-- Fetched: Success
				// Check if the fetched data contain the information that we need
				let isFetchedDataSufficient = true;

				// [Error check #1: Insufficient data, because this imo number no vessel particulars]
				if (
					vesselDetails?.vesselParticulars === null ||
					vesselDetails?.vesselParticulars === undefined
				) {
					// Toggle flag
					isFetchedDataSufficient = false;

					// Error message on console
					ConsoleError(
						"Fetched success from SRS API, but insufficient data, because this imo number has no vessel particulars",
					);
				}

				// [Error check #2: Insufficient data, because this imo number no certificate number]
				else if (
					vesselDetails?.vesselParticulars.certificate?.length === 0 ||
					vesselDetails?.vesselParticulars.certificate?.length === undefined ||
					vesselDetails?.vesselParticulars.certificate?.length === null
				) {
					// Toggle flag
					isFetchedDataSufficient = false;

					// Error message on console
					ConsoleError(
						"Fetched success from SRS API, but insufficient data, because this imo number has vessel particulars but no certificate.",
					);
				}

				// [SUCCESS->Fetched Data: Sufficient data from the api of this imo number] Handle update fetched data to UI
				if (isFetchedDataSufficient) {
					//-- Set this element->state to SRS_FOUND
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.statusVesselDetail =
						VesselDetailsStatus.SRS_FOUND;

					//-- Populate fetched data from API
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.dateOfRegistration =
						vesselDetails?.vesselParticulars?.certificate[0]
							?.registrationDate ?? ""; // Date of registration in singapore registry of ships

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.typeOfVesselDescription =
						vesselDetails.vesselParticulars.vesselTypeDesc; // Vessel Type

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.yearBuilt =
						vesselDetails.vesselParticulars.yearBuilt; // Year Built

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.vesselName =
						vesselDetails.vesselParticulars.vesselName; //vessel name
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.grossTonnage =
						vesselDetails.vesselParticulars.grossTonnage; //gross tonnage
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.netTonnage =
						vesselDetails.vesselParticulars.netTonnage; //net tonnage

					//-- Show dialog that says successed to find vessel
					toggleVesselDialog(true, VesselDialogType.SUCCESS_VESSEL_FOUND);
				}
				// [FAILED->Fetched Data: Insufficient data from the api of this imo number..]
				else {
					//-- Set this element->state to SRS_NOT_FOUND
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.statusVesselDetail =
						VesselDetailsStatus.SRS_NOT_FOUND;

					//-- Reset all fields
					helperClearVesselParticularsDatagridFields(currentIndex, false);

					//-- Show dialog that says failed to find vessel
					toggleVesselDialog(true, VesselDialogType.ERROR_VESSEL_NOT_FOUND);
				}
			} catch (error) {
				//-- Error message on console
				let errorInfo = `Error ${error.response.status}: ${error.response.statusText}`;
				ConsoleError("Fetched fail from SRS API.", errorInfo);

				//-- Set this element->state to SRS_NOT_FOUND to reflect changes on form ui
				currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
					currentIndex
				].containerDataGrid.statusVesselDetail =
					VesselDetailsStatus.SRS_NOT_FOUND;

				//-- Reset all fields
				helperClearVesselParticularsDatagridFields(currentIndex, false);

				//-- Show dialog that says failed to find vessel
				toggleVesselDialog(true, VesselDialogType.ERROR_VESSEL_NOT_FOUND);
			}
		}

		//-- Update formIO side to trigger change event and redraw ui event
		CommonVesselDatagrid_GetComponent_Update(
			e,
			currentForm,
			ComponentFieldType.NORMAL_COLUMN_TOP,
			ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
		);
		CommonVesselDatagrid_GetComponent_Update(
			e,
			currentForm,
			ComponentFieldType.NORMAL_COLUMN_BOTTOM,
			ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
		);
	};

	const retrieveEditSGVesselDetailsFromSRS = async (e) => {
		/* Read this first: what is inside (e)?
      Object: {
          cbIMO: false,
          certNumContainer: {certificationNumber: '', certNum-search: false},
          countries: "Singapore",
          dateOfRegistration: "",
          deliveryDate3: "",
          grossTonnage: "",
          imoNumber: "",
          netTonnage: "",
          search: true,
          vesselName: "",
          [[Prototype]] }
    */

		//-- Temp Variables
		let currentIndex = -1;

		//-- Loop thr entire data grid and see whether there is already an existing IMO number
		let duplicateIndex = CommonVesselDatagrid_GetDuplicatedComponent_Index(
			e,
			currentForm,
			ImoType.EDIT,
		);

		//-- Find which index are we
		currentIndex = CommonVesselDatagrid_GetComponent_Index(
			e,
			currentForm,
			ComponentFieldType.IMO_NUMBER,
		);

		//-- Duplicate => Display dialog message error
		if (duplicateIndex !== -1) {
			let displayIndex = duplicateIndex + 1; // We are doing this because user dont know index start from 0, we show them layman counting start from 1
			toggleVesselDialog(true, VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER);

			currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
				currentIndex
			].containerDataGrid.edit_imoNumber = "";

			//-- Set state
			currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
				currentIndex
			].containerDataGrid.edit_statusVesselDetail = VesselDetailsStatus.EMPTY;

			//-- Reset all fields
			helperClearVesselParticularsEditDatagridFields(currentIndex, true);
		}
		//-- No duplicate => We call api to check if imo exist on the server side
		else {
			//-- Fetch api using imoNumber and auto populate the details
			// check for valid IMO Number
			try {
				let srsByIMOList = null;
				let vesselDetails = null;

				// SRS Dummy data
				if (SIMULATE_DUMMY_DATA_SRS_API) {
					vesselDetails = {
						vesselParticulars: {
							certificate: [
								{
									registrationDate: "2015-02-09T00:00:00Z",
								},
							],
							netTonnage: 123,
							grossTonnage: 123456789,
							vesselType: "TEST VesselType",
							yearBuilt: 1234,
							vesselName: "TEST vesselName",
						},
					};
				}
				// SRS API Real Data
				else {
					srsByIMOList = await get_WHT_SRS_Info(e.edit_imoNumber);
					srsByIMO = srsByIMOList[0];
					vesselDetails = srsByIMO;
				}

				//-- Fetched: Success
				// Check if the fetched data contain the information that we need
				let isFetchedDataSufficient = true;

				// [Error check #1: Insufficient data, because this imo number no vessel particulars]
				if (
					vesselDetails?.vesselParticulars === null ||
					vesselDetails?.vesselParticulars === undefined
				) {
					// Toggle flag
					isFetchedDataSufficient = false;

					// Error message on console
					ConsoleError(
						"Fetched success from SRS API, but insufficient data, because this imo number has no vessel particulars",
					);
				}

				// [Error check #2: Insufficient data, because this imo number no certificate number]
				else if (
					vesselDetails?.vesselParticulars.certificate?.length === 0 ||
					vesselDetails?.vesselParticulars.certificate?.length === undefined ||
					vesselDetails?.vesselParticulars.certificate?.length === null
				) {
					// Toggle flag
					isFetchedDataSufficient = false;

					// Error message on console
					ConsoleError(
						"Fetched success from SRS API, but insufficient data, because this imo number has vessel particulars but no certificate.",
					);
				}

				// [SUCCESS->Fetched Data: Sufficient data from the api of this imo number] Handle update fetched data to UI
				if (isFetchedDataSufficient) {
					//-- Set this element->state to SRS_FOUND
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.edit_statusVesselDetail =
						VesselDetailsStatus.SRS_FOUND;

					//-- Populate fetched data from API
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.edit_dateOfRegistration =
						vesselDetails?.vesselParticulars?.certificate[0]
							?.registrationDate ?? ""; // Date of registration in singapore registry of ships

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.edit_typeOfVesselDescription =
						vesselDetails.vesselParticulars.vesselTypeDesc; // Vessel Type

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.edit_yearBuilt =
						vesselDetails.vesselParticulars.yearBuilt; // Year Built

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.edit_vesselName =
						vesselDetails.vesselParticulars.vesselName; //vessel name
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.edit_grossTonnage =
						vesselDetails.vesselParticulars.grossTonnage; //gross tonnage
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.edit_netTonnage =
						vesselDetails.vesselParticulars.netTonnage; //net tonnage

					//-- Show dialog that says successed to find vessel
					toggleVesselDialog(true, VesselDialogType.SUCCESS_VESSEL_FOUND);
				}
				// [FAILED->Fetched Data: Insufficient data from the api of this imo number..]
				else {
					//-- Set this element->state to SRS_NOT_FOUND
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.edit_statusVesselDetail =
						VesselDetailsStatus.SRS_NOT_FOUND;

					//-- Reset all fields
					helperClearVesselParticularsEditDatagridFields(currentIndex, false);

					//-- Show dialog that says failed to find vessel
					toggleVesselDialog(true, VesselDialogType.ERROR_VESSEL_NOT_FOUND);
				}
			} catch (error) {
				//-- Error message on console
				let errorInfo = `Error ${error.response.status}: ${error.response.statusText}`;
				ConsoleError("Fetched fail from SRS API.", errorInfo);

				//-- Set this element->state to SRS_NOT_FOUND to reflect changes on form ui
				currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
					currentIndex
				].containerDataGrid.edit_statusVesselDetail =
					VesselDetailsStatus.SRS_NOT_FOUND;

				//-- Reset all fields
				helperClearVesselParticularsEditDatagridFields(currentIndex, false);

				//-- Show dialog that says failed to find vessel
				toggleVesselDialog(true, VesselDialogType.ERROR_VESSEL_NOT_FOUND);
			}
		}

		//-- Update formIO side to trigger change event and redraw ui event
		CommonVesselDatagrid_GetComponent_Update(
			e,
			currentForm,
			ComponentFieldType.EDIT_COLUMN_TOP,
			ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
		);
		CommonVesselDatagrid_GetComponent_Update(
			e,
			currentForm,
			ComponentFieldType.EDIT_COLUMN_BOTTOM,
			ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
		);
	};

	const retrieveReplaceSGVesselDetailsFromSRS = async (e) => {
		/* Read this first: what is inside (e)?
      Object: {
          cbIMO: false,
          certNumContainer: {certificationNumber: '', certNum-search: false},
          countries: "Singapore",
          dateOfRegistration: "",
          deliveryDate3: "",
          grossTonnage: "",
          imoNumber: "",
          netTonnage: "",
          search: true,
          vesselName: "",
          [[Prototype]] }
    */

		//-- Temp Variables
		let currentIndex = -1;

		//-- Loop thr entire data grid and see whether there is already an existing IMO number
		let duplicateIndex = CommonVesselDatagrid_GetDuplicatedComponent_Index(
			e,
			currentForm,
			ImoType.REPLACE,
		);

		//-- Find which index are we
		currentIndex = CommonVesselDatagrid_GetComponent_Index(
			e,
			currentForm,
			ComponentFieldType.IMO_NUMBER,
		);

		//-- Duplicate => Display dialog message error
		if (duplicateIndex !== -1) {
			let displayIndex = duplicateIndex + 1; // We are doing this because user dont know index start from 0, we show them layman counting start from 1
			toggleVesselDialog(true, VesselDialogType.ERROR_DUPLICATED_IMO_NUMBER);

			currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
				currentIndex
			].containerDataGrid.replacement_imoNumber = "";

			//-- Set state
			currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
				currentIndex
			].containerDataGrid.replacement_statusVesselDetail =
				VesselDetailsStatus.EMPTY;

			//-- Reset all fields
			helperClearVesselParticularsReplaceDatagridFields(currentIndex, true);
		}
		//-- No duplicate => We call api to check if imo exist on the server side
		else {
			//-- Fetch api using imoNumber and auto populate the details
			// check for valid IMO Number
			try {
				let srsByIMOList = null;
				let vesselDetails = null;

				// SRS Dummy data
				if (SIMULATE_DUMMY_DATA_SRS_API) {
					vesselDetails = {
						vesselParticulars: {
							certificate: [
								{
									registrationDate: "2015-02-09T00:00:00Z",
								},
							],
							netTonnage: 123,
							grossTonnage: 123456789,
							vesselType: "TEST VesselType",
							yearBuilt: 1234,
							vesselName: "TEST vesselName",
						},
					};
				}
				// SRS API Real Data
				else {
					srsByIMOList = await get_WHT_SRS_Info(e.replacement_imoNumber);
					srsByIMO = srsByIMOList[0];
					vesselDetails = srsByIMO;
				}

				//-- Fetched: Success
				// Check if the fetched data contain the information that we need
				let isFetchedDataSufficient = true;

				// [Error check #1: Insufficient data, because this imo number no vessel particulars]
				if (
					vesselDetails?.vesselParticulars === null ||
					vesselDetails?.vesselParticulars === undefined
				) {
					// Toggle flag
					isFetchedDataSufficient = false;

					// Error message on console
					ConsoleError(
						"Fetched success from SRS API, but insufficient data, because this imo number has no vessel particulars",
					);
				}

				// [Error check #2: Insufficient data, because this imo number no certificate number]
				else if (
					vesselDetails?.vesselParticulars.certificate?.length === 0 ||
					vesselDetails?.vesselParticulars.certificate?.length === undefined ||
					vesselDetails?.vesselParticulars.certificate?.length === null
				) {
					// Toggle flag
					isFetchedDataSufficient = false;

					// Error message on console
					ConsoleError(
						"Fetched success from SRS API, but insufficient data, because this imo number has vessel particulars but no certificate.",
					);
				}

				// [SUCCESS->Fetched Data: Sufficient data from the api of this imo number] Handle update fetched data to UI
				if (isFetchedDataSufficient) {
					//-- Set this element->state to SRS_FOUND
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.replacement_statusVesselDetail =
						VesselDetailsStatus.SRS_FOUND;

					//-- Populate fetched data from API
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.replacement_dateOfRegistration =
						vesselDetails?.vesselParticulars?.certificate[0]
							?.registrationDate ?? ""; // Date of registration in singapore registry of ships

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.replacement_typeOfVesselDescription =
						vesselDetails.vesselParticulars.vesselTypeDesc; // Vessel Type

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.replacement_yearBuilt =
						vesselDetails.vesselParticulars.yearBuilt; // Year Built

					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.replacement_vesselName =
						vesselDetails.vesselParticulars.vesselName; //vessel name
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.replacement_grossTonnage =
						vesselDetails.vesselParticulars.grossTonnage; //gross tonnage
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.replacement_netTonnage =
						vesselDetails.vesselParticulars.netTonnage; //net tonnage

					//-- Show dialog that says successed to find vessel
					toggleVesselDialog(true, VesselDialogType.SUCCESS_VESSEL_FOUND);
				}
				// [FAILED->Fetched Data: Insufficient data from the api of this imo number..]
				else {
					//-- Set this element->state to SRS_NOT_FOUND
					currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
						currentIndex
					].containerDataGrid.replacement_statusVesselDetail =
						VesselDetailsStatus.SRS_NOT_FOUND;

					//-- Reset all fields
					helperClearVesselParticularsReplaceDatagridFields(
						currentIndex,
						false,
					);

					//-- Show dialog that says failed to find vessel
					toggleVesselDialog(true, VesselDialogType.ERROR_VESSEL_NOT_FOUND);
				}
			} catch (error) {
				//-- Error message on console
				let errorInfo = `Error ${error.response.status}: ${error.response.statusText}`;
				ConsoleError("Fetched fail from SRS API.", errorInfo);

				//-- Set this element->state to SRS_NOT_FOUND to reflect changes on form ui
				currentForm.submission.data.containerParticulars.containerShipVessel.dataGridVessel1[
					currentIndex
				].containerDataGrid.replacement_statusVesselDetail =
					VesselDetailsStatus.SRS_NOT_FOUND;

				//-- Reset all fields
				helperClearVesselParticularsReplaceDatagridFields(currentIndex, false);

				//-- Show dialog that says failed to find vessel
				toggleVesselDialog(true, VesselDialogType.ERROR_VESSEL_NOT_FOUND);
			}
		}

		//-- Update formIO side to trigger change event and redraw ui event
		CommonVesselDatagrid_GetComponent_Update(
			e,
			currentForm,
			ComponentFieldType.REPLACEMENT_COLUMN_TOP,
			ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
		);
		CommonVesselDatagrid_GetComponent_Update(
			e,
			currentForm,
			ComponentFieldType.REPLACEMENT_COLUMN_BOTTOM,
			ComponentUpdateType.TRIGGER_CHANGE_AND_REDRAW,
		);
	};

	const helperClearVesselParticularsDatagridFields = (
		targetIndex,
		resetAll,
	) => {
		const dataGridVessel =
			currentForm.submission.data.containerParticulars.containerShipVessel
				.dataGridVessel1;

		if (resetAll === true) {
			dataGridVessel[targetIndex].containerDataGrid.vesselCost = "";
			dataGridVessel[targetIndex].containerDataGrid.deliveryDate3 = "";
		}

		dataGridVessel[targetIndex].containerDataGrid.dateOfRegistration = "";
		dataGridVessel[targetIndex].containerDataGrid.typeOfVesselDescription = "";
		dataGridVessel[targetIndex].containerDataGrid.yearBuilt = "";
		dataGridVessel[targetIndex].containerDataGrid.vesselName = "";
		dataGridVessel[targetIndex].containerDataGrid.grossTonnage = "";
		dataGridVessel[targetIndex].containerDataGrid.netTonnage = "";
	};

	const helperClearVesselParticularsEditDatagridFields = (
		targetIndex,
		resetAll,
	) => {
		const dataGridVessel =
			currentForm.submission.data.containerParticulars.containerShipVessel
				.dataGridVessel1;

		if (resetAll) {
			dataGridVessel[targetIndex].containerDataGrid.edit_vesselCost = "";
			dataGridVessel[targetIndex].containerDataGrid.edit_deliveryDate = "";
		}
		dataGridVessel[targetIndex].containerDataGrid.edit_dateOfRegistration = "";
		dataGridVessel[targetIndex].containerDataGrid.edit_typeOfVesselDescription =
			"";
		dataGridVessel[targetIndex].containerDataGrid.edit_yearBuilt = "";
		dataGridVessel[targetIndex].containerDataGrid.edit_vesselName = "";
		dataGridVessel[targetIndex].containerDataGrid.edit_grossTonnage = "";
		dataGridVessel[targetIndex].containerDataGrid.edit_netTonnage = "";
	};

	const helperClearVesselParticularsReplaceDatagridFields = (
		targetIndex,
		resetAll,
	) => {
		const dataGridVessel =
			currentForm.submission.data.containerParticulars.containerShipVessel
				.dataGridVessel1;

		if (resetAll) {
			dataGridVessel[targetIndex].containerDataGrid.replacement_vesselCost = "";
			dataGridVessel[targetIndex].containerDataGrid.replacement_deliveryDate =
				"";
		}

		dataGridVessel[
			targetIndex
		].containerDataGrid.replacement_dateOfRegistration = "";
		dataGridVessel[
			targetIndex
		].containerDataGrid.replacement_typeOfVesselDescription = "";
		dataGridVessel[targetIndex].containerDataGrid.replacement_yearBuilt = "";
		dataGridVessel[targetIndex].containerDataGrid.replacement_vesselName = "";
		dataGridVessel[targetIndex].containerDataGrid.replacement_grossTonnage = "";
		dataGridVessel[targetIndex].containerDataGrid.replacement_netTonnage = "";
	};

	const onChange = async (event) => {
		if (
			prevStatus === "Draft" ||
			prevStatus === "draft" ||
			prevStatus === "All" ||
			prevStatus === "all" ||
			prevStatus === null ||
			prevStatus === undefined
		) {
			$(".formio-component-applicantcomment").hide();
		}

		onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
		checkValidity();

		if (
			event.changed?.component.key === "radioAnyPayment" ||
			event.changed?.component.key ===
				"isThereAnySwapArrangementInTheSameFinancingAgreement"
		) {
			CommonUpdateValidation_QualifyingPayment(
				currentForm,
				event.changed?.component.key,
			);
		}

		setStartSpinner(false);
	};

	const onSubmit = async (submission) => {
		onSubmitGeneric(
			submission,
			currentForm,
			alreadySubmitted,
			createdData,
			saveCreatedData,
			createURL,
			appFormBaseURL,
			formId,
		);
	};

	const onError = async (errors) => {
		onErrorGeneric(errors);
	};

	const onSubmitDone = (submission) => {
		onSubmitDoneGeneric(submission);
	};

	const onCustomEvent = async ({ type, component, data, event }) => {
		// This method is intentionally empty.
	};

	const populateQ1 = (currentForm, selected) => {
		let r;
		switch (selected) {
			case "SRS":
				r = "r1";
				break;
			case "MSI-AIS":
				r = "r2";
				break;
			case "MSI-ML-Ship":
				r = "r3";
				break;
			case "MSI-ML-Container":
				r = "r4";
				break;
			default:
				return null;
		}
		currentForm.submission.data.containerParticulars.containerBorrower.entityType =
			r;

		currentForm.redraw(); //render the updated option ??
	};

	function changePage(x) {
		thisForm.setPage(x);
	}

	function scrollToError() {
		window.scrollTo(0, 0);
	}

	function goToTop() {
		window.scrollTo(0, 0);
		setTimeout(scrollToError, 2000);
	}

	function checkValidityVesselParticularsDatagrid() {
		let datagridErrorCounter = 0;
		//-- Search for all dataGrid component in this form
		let listOfDatagrid = Utils.searchComponents(currentForm.components, {
			type: "datagrid",
		});

		//-- Loop through all the datagrids in this world
		listOfDatagrid.forEach((eachDataGrid) => {
			//-- Found datagrid with the property name: dataGridVessel1
			if (eachDataGrid.key === "dataGridVessel1") {
				//-- Get the size of updated datagrid. This is mandatory since size changes overtime.
				let vesselDGSize =
					currentForm.submission.data.containerParticulars.containerShipVessel
						.dataGridVessel1?.length;

				//-- Loop through each item in this DataGrid, the intention for this "for loop" is
				//-> To know which index of the datagrid we are currently triggering the input changed event.
				for (let i = 0; i < vesselDGSize; ++i) {
					//-- Reference to each element in the dataGrid
					let datagridItem = eachDataGrid?.components[i];
					if (datagridItem) {
						datagridErrorCounter +=
							datagridItem.checkValidity(null, true, null, false) === false
								? 1
								: 0;
					}
				}
			}
		});

		// Return
		// -> true if no error
		// -> false if have error
		return datagridErrorCounter === 0;
	}

	function checkValidity() {
		let valCheck = false;
		if (validityTriggered) {
			// Check custom->validity error (Datagrid->Vessel Particular)
			let valCheckDataGridError = checkValidityVesselParticularsDatagrid();

			// Check formio->validity (Non-Datagrid->All fields)
			let valCheckNonDatagridError = currentForm.checkValidity(
				null,
				true,
				null,
				false,
			);

			// Final check result
			valCheck = valCheckDataGridError && valCheckNonDatagridError;

			// Display error
			let oldPagesErrors = pagesErrors;
			currentForm.pages.forEach(
				(x, index) => (oldPagesErrors[index] = x.errors.length),
			);
			setPagesErrors(oldPagesErrors);
			setCurrentPageErrors(pagesErrors[currentForm.page]);
		}
		return valCheck;
	}

	function cleanupEmptyFilesFromMigratedShipMain(appFormJson) {
		const countFinancingAgreementSignedFiles =
			appFormJson.current.data.containerNonTax.containerLender
				.containerFinancingAgreementSigned?.uploadFile?.length;
		for (let i = 0; i < countFinancingAgreementSignedFiles; i++) {
			if (
				appFormJson.current.data.containerNonTax.containerLender
					.containerFinancingAgreementSigned?.uploadFile[i]?.hash?.length === 0
			) {
				appFormJson.current.data.containerNonTax.containerLender.containerFinancingAgreementSigned?.uploadFile?.splice(
					i,
					1,
				);
			}
		}

		const countFinancingAddendumFiles =
			appFormJson.current.data.containerNonTax.containerLender
				.containerFinancingAddendum?.uploadFile?.length;
		for (let i = 0; i < countFinancingAddendumFiles; i++) {
			if (
				appFormJson.current.data.containerNonTax.containerLender
					.containerFinancingAddendum?.uploadFile[i]?.hash?.length === 0
			) {
				appFormJson.current.data.containerNonTax.containerLender.containerFinancingAddendum?.uploadFile?.splice(
					i,
					1,
				);
			}
		}

		const countLetterOfUndertakingFiles =
			appFormJson.current.data.containerNonTax.containerLender
				.containerLetterOfUndertaking?.uploadFile?.length;
		for (let i = 0; i < countLetterOfUndertakingFiles; i++) {
			if (
				appFormJson.current.data.containerNonTax.containerLender
					.containerLetterOfUndertaking?.uploadFile[i]?.hash?.length === 0
			) {
				appFormJson.current.data.containerNonTax.containerLender.containerLetterOfUndertaking?.uploadFile?.splice(
					i,
					1,
				);
			}
		}

		const countAttachmentOfSgBankOrFIFiles =
			appFormJson.current.data.containerCU.containerReasonFA.containerWhyFA3
				?.dataGridAttachmentOfSgBankOrFI?.length;
		for (let i = 0; i < countAttachmentOfSgBankOrFIFiles; i++) {
			if (
				appFormJson.current.data.containerCU.containerReasonFA.containerWhyFA3
					?.dataGridAttachmentOfSgBankOrFI[i]?.uploadFile[0]?.hash?.length === 0
			) {
				appFormJson.current.data.containerCU.containerReasonFA.containerWhyFA3?.dataGridAttachmentOfSgBankOrFI?.splice(
					i,
					1,
				);
			}
		}
	}

	const handleDelete = async () => {
		try {
			if (submitID_Ref.current) {
				const originalPromiseResult = await dispatch(
					deleteFormAPIAsync({
						formId: formId,
						submissionId: submitID_Ref.current,
					}),
				).unwrap();
				history.push({
					pathname: "/NestedApplicationListing",
					search: `?schema=2`,
					refresh: true,
				});
			} else {
				alert("Delete is not allowed");
			}
		} catch (rejectedValueOrSerializedError) {
			ConsoleWarn(rejectedValueOrSerializedError);
			alert("Delete is not allowed");
		}
	};

	const handleUploadVesselDialogResponse = (text, type) => {
		setOpenUploadVesselsDialog(false);
		CommonToggleFileUploadVessels(currentFormref.current, text);
	};

	const toggleVesselDialog = (show, dialogMessageType) => {
		setOpenDialog(show);
		setDialogMessageType(dialogMessageType);
	};

	return (
		<div className="applicationform">
			<ValidationDialog
				open={openValidationDialog}
				setOpen={setOpenValidationDialog}
				goToTop={goToTop}
			/>
			<Container>
				{/* call from the ApplicationForm.js */}
				<FormHtmlHead
					formname={formname}
					schema={schema}
				/>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<FormHtmlTitle
						formname={formname}
						formid={formid}
						formstatus={status}
						caseID={caseID}
					/>
					{/* <Button className="large button-large univers-65-bold-white-16px role-margin"

            onClick={() => { handleDelete() }}
          >
            Delete
          </Button> */}
				</div>
				<br />
				<PageCarousel
					thisform={thisForm}
					setpage={changePage}
					pagesErrors={pagesErrors}
					status={status}
					removeAssessmentClick={true}
				/>
				<FormHtmlErrors
					thisForm={thisForm}
					pagesErrors={pagesErrors}
					currentPageErrors={currentPageErrors}
				/>

				<FormHtmlForm
					fetchingform={fetchingform}
					formReadOnly={formReadOnly}
					props={props}
					appFormURL={createAppFormURL}
					onChange={onChange}
					onError={onError}
					formReady={formReady}
					onSubmit={onSubmit}
					onSubmitDone={onSubmitDone}
					onRender={onRender}
					onCustomEvent={onCustomEvent}
					isBusy={isBusy}
					appFormJson={appFormJson}
					//options={options}
				/>
			</Container>
			<VesselDialog
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				vesselDialogMessageType={dialogMessageType}
			/>
			<DateValidationDialog
				openDialog={openDateValidDialog}
				setOpenDateValidDialog={setOpenDateValidDialog}
				//isEligible={isEligible}
				//ineligibleMessage={ineligibleMessage}
				//goToForm={goToForm}
			/>
			<UploadVesselsDialog
				open={openUploadVesselsDialog}
				setOpenDialog={setOpenUploadVesselsDialog}
				response={handleUploadVesselDialogResponse}
				type={uploadVesselsType}
			/>
			<AutoSaveErrorDialog
				open={openErrorStatusDialog}
				setOpen={setOpenErrorStatusDialog}
			/>

			<SnackbarAlert
				alert={openAlert}
				caseID={caseID}
				open={open}
				setOpen={setOpen}
			/>
			<ProgressBar
				ref={ref}
				startSpinner={startSpinner}
				setStartSpinner={setStartSpinner}
				bar={bar}
				setBar={setBar}
			/>
		</div>
	);
}
