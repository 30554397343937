import React, { useEffect, useImperativeHandle } from "react";
interface Props {
	id: string;
	dataId: string;
	limit: number;
	disabled: boolean;
	handleChange: (e: string) => void;
	value: string;
	placeholder: string;
	note?: string;
	rows?: number;
	cols?: number;
}
export const CommentTextArea: React.FC<Props> = (props, ref) => {
	const [content, setContent] = React.useState(
		props.value.slice(0, props.limit)
	);
	useImperativeHandle(ref, () => ({
		changeContent,
	}));

	const setFormattedContent = React.useCallback(
		(text) => {
			setContent(text.slice(0, props.limit));
		},
		[props.limit, setContent]
	);
	const newText = "";

	const changeContent = () => {
		setContent(newText.slice(0, props.limit));
	};

	useEffect(() => {
		setContent(props.value.slice(0, props.limit));
	}, []);

	return (
		<div>
			{props.note && (
				<div
					style={{
						fontSize: "small",
						color: "red",
					}}
				>
					{props.note}
				</div>
			)}
			<textarea
				id={props.id}
				data-id={props.dataId}
				disabled={props.disabled}
				onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
					const value = e.target.value.toString();
					const formattedContent =
						value.length > props.limit ? value.slice(0, props.limit) : value;
					setFormattedContent(formattedContent);
					props.handleChange(formattedContent);
				}}
				value={props.value}
				style={{
					width: "100%",
					height: "5em",
					border: "1px solid #bdbdbd",
				}}
				rows={props.rows}
				cols={props.cols}
				placeholder={props.placeholder ?? ""}
			></textarea>
			<p style={{ color: "lightgrey", fontSize: 15 }}>
				{content.length}/{props.limit} characters
			</p>
		</div>
	);
};
