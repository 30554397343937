import applicantcomment from "./Comment/ApplicantCommentComponent";
import comment from "./Comment/OfficerCommentComponent";
import review from "./Review/Review";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	comment,
	applicantcomment,
	review,
};
