import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../config";
import { fetch } from "../../Common/API";
import { ConsoleError } from "../../Common/Logger";
import {
	ApplicantComment,
	CommentsByFieldNameType,
	sortByTime,
} from "../../Components/Comment/CommentUtils";

export const addNewApplicantCommentAsync = createAsyncThunk(
	"applicantcomment/addNewApplicantCommentAsync",
	async (body: any, { rejectWithValue }) => {
		try {
			const response = await fetch(
				`${config.appURL}/api/Comment/PostComment`,
				"POST",
				body,
			);
			return await response.data;
		} catch (ex: any) {
			return rejectWithValue(ex.response.data);
		}
	},
);

export const editApplicantCommentAsync = createAsyncThunk(
	"applicantcomment/editApplicantCommentAsync",
	async ({ id, body }: { id: string; body: any }, { rejectWithValue }) => {
		try {
			let response = await fetch(
				`${config.appURL}/api/Comment/PostComment/${id}`,
				"PUT",
				body,
			);
			return await response.data;
		} catch (ex: any) {
			return rejectWithValue(ex.response.data);
		}
	},
);

export const deleteApplicantCommentAsync = createAsyncThunk(
	"applicantcomment/deleteApplicantCommentAsync",
	async (id: string, { rejectWithValue }) => {
		try {
			let response = await fetch(
				`${config.appURL}/api/Comment/PostComment/${id}`,
				"DELETE",
			);
			return await response.data;
		} catch (ex: any) {
			return rejectWithValue(ex.response.data);
		}
	},
);

export const getCommentsByFieldNameAsync = createAsyncThunk(
	"applicantcomment/getCommentsByFieldNameAsync",
	async (
		{
			fieldName,
			submissionId,
		}: { fieldName: string; submissionId: string | null },
		{ rejectWithValue },
	) => {
		try {
			const response = await fetch(
				`${config.appURL}/api/comment/GetCommentsByFieldName?FieldName=${fieldName}&SubmissionId=${submissionId}`,
				"GET",
			);
			return await { fieldName: fieldName, responseData: response.data };
		} catch (err: any) {
			return rejectWithValue(err.response.data);
		}
	},
);

const ApplicantCommentSlice = createSlice({
	name: "applicantcomment",
	initialState: { ApplicantComments: {} } as {
		ApplicantComments: Record<string, ApplicantComment[]>;
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			getCommentsByFieldNameAsync.fulfilled,
			(
				state,
				action: PayloadAction<{ fieldName: string; responseData: any }>,
			) => {
				if (!Array.isArray(action.payload.responseData.Comments)) {
					ConsoleError("Expect Comments result to be array, but it is not.");
					return;
				}
				const field = action.payload.fieldName;
				const Comments = action.payload.responseData
					.Comments as ApplicantComment[];

				const fieldComments = Comments.filter((c) => c.FieldName === field);

				if (fieldComments.length <= 0) {
					delete state.ApplicantComments[field];
					return;
				}

				state.ApplicantComments[field] = sortByTime(
					fieldComments,
				) as ApplicantComment[];
			},
		);
		builder.addCase(
			addNewApplicantCommentAsync.fulfilled,
			(state: any, action: PayloadAction<any>) => {
				const data = action.payload.CommentResponse;
				const fieldName = action.payload.CommentResponse.FieldName;
				if (!state.ApplicantComments[fieldName]) {
					state.ApplicantComments[fieldName] = [];
				}
				state.ApplicantComments[fieldName].push(data);
			},
		);
	},
});

export default ApplicantCommentSlice.reducer;
