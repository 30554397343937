import { Container, Link, Typography, } from "@mui/material";
import { IconCheckupList } from "@tabler/icons";
import Utils from "formiojs/utils";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createOrSave,
  FormHtmlErrors,
  FormHtmlForm,
  FormHtmlHead,
  FormHtmlTitle,
  onChangeGeneric,
  onErrorGeneric,
  onRenderGeneric,
  onSubmitDoneGeneric,
  onSubmitGeneric,
  PageCarousel,
} from "../../../Common/ApplicationForm";
import { getIDPEDHInfo } from "../../../Common/EDH";
import { EligibleDialogGrant } from "../../../Components/Incentives/WHT/EligibleDialog";
import { config } from "../../../config";
import "../../ApplicationForm/ApplicationForm.css";
import pagination from "../../ApplicationForm/pagination";
import { useHistory, useLocation } from "react-router-dom";
import ValidationDialog from "../../../Common/ValidationDialog";
import $ from "jquery";
import { fetch } from "../../../Common/API";
import loginHelper from "../../../Common/loginHelper";
import { deleteFormAPIAsync, } from "../../../Redux/CommonForm/CommonFormSlice.js.js";
import flatComponent from "../../../Common/FormIOComponent/flatComponent";
import scanData from "../../../Common/FormIOComponent/scanData";
import { getTrackChanges, NamePropertyTrackChanges, TrackChanges, } from "../../ApplicationListing/APICall/apicall";
import moment from "moment";
import SnackbarAlert from "../../../Common/snackbarAlert";
import ProgressBar from "../../../Common/progressBar";
import AutoSaveErrorDialog from "../../../Common/autoSaveError";
import getTitle from "../../../Common/FormIOComponent/titleComponent";
import getStatusText from "../../../Common/GetStatusText";
import { ConvertUtcToLocale_ApplicantRegistrationDate, GenericTabTimeout } from "../../../Common/GenericIDP";
import { GenericFileUploadOnRender, GenericFileUploadOnChange } from "../../../Common/GenericForm";
import { ConsoleInfo } from "../../../Common/Logger";
import { ScheduleCallbackAfterDocumentReadyWithDelay } from "../../../Common/CommonUtils";

let shareholders = 0;

IDPMainForm.defaultProps = {
  formname: "Industry Digital Plan Grant Form",
  formstatus: "draft",
  formId: `${config.form_name_target_idp_main}`,
  appFormBaseURL: `${config.appFormBaseURL}`,
  schema: "IDP",
};

let allowScrolling = true;
export default function IDPMainForm({
  formname,
  formid,
  formstatus,
  formId,
  appFormBaseURL,
  schema,
  props,
}) {
  const [page, setPage] = useState(0);
  const [openErrorStatusDialog, setOpenErrorStatusDialog] = useState(false);
  const [isBusy, setBusy] = useState(true);
  const appFormJson = useRef();
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  let validityTriggered = false;

  const [openAlert, setOpenAlert] = useState();
  const [open, setOpen] = useState(false);
  const [disableScrollingFunc, setScrollState] = useState(() => {
    // This function is intentionally empty.
  });
  const [isEligible, setIsEligible] = useState();
  const [ineligibleMessage, setIneligibleMessage] = useState();
  const [grantType, setGrantType] = useState({ type: "", fullName: "" });
  const [openEligibleDialog, setOpenEligibleDialog] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  let _id = params.get("_id");
  let getStatus = params.get("status");

  const [status, setStatus] = useState();
  let prevStatus;
  const theformId = _id;

  let createdData = { _id: _id };
  let submissionId = _id;
  if (!_id) {
    //new applcation
    _id = "";
  } else {
    // update existing application
    if (page === 0) setPage(2);
    _id = "submission/" + _id;
  }

  //var appFormURL = `${appFormBaseURL}/${formId}/${_id}`
  let appFormURL = `${appFormBaseURL}/${formId}`;
  let appFormURL2 = `${appFormBaseURL}/${formId}/${_id}`;
  let createURL = `${appFormBaseURL}/${formId}/submission`;
  let currentForm = {};
  const [thisForm, setThisForm] = useState();
  const [formReadOnly, setFormReadOnly] = useState(false);

  const [fetchingform, setFetchingform] = useState(true);
  const [pagesErrors, setPagesErrors] = useState([]);
  const [currentPageErrors, setCurrentPageErrors] = useState(0);

  const location = useLocation();
  const getCaseID = location?.state?.caseID;
  const [caseID, setCaseID] = useState();

  let reviewCaseID;

  let alreadySubmitted = false;
  const submitID_ref = useRef("");
  const form_ref = useRef({});
  let canSubmit = true;
  const approvedList = useSelector((state) => state.WHTForm);
  const [startSpinner, setStartSpinner] = useState(false);
  const [bar, setBar] = useState(false);
  const ref = useRef();

  let reloadStrictMode = false;
  let tabBufferCounter = 5;
  let refTabCounterTimeOut = useRef(null);

  useEffect(() => {
    async function getform() {
      setBusy(true);
      shareholders = 0;
      let json = await fetch(appFormURL2, "GET", null);
      if (json) {
        if (reloadStrictMode) {
          reloadStrictMode = false;
        } else {
          setBusy(false);

          appFormJson.current = json.data;
          if (getStatus === "All") {
            setStatus("draft");
            prevStatus = "draft";
          } else if (json.data.data) {
            const currentStatus =
              json.data.data.configContainer.publicApplicationStatus;
            const getText = getStatusText(currentStatus);
            setStatus(getText);
            prevStatus = getText;
            for (const item of json.data.data.shareholders) {
              if (item.shareholderUEN) {
                shareholders++;
              }
            }
          } else {
            setStatus("draft");
            prevStatus = "draft";
          }
        }
      }
    }

    getform().catch(console.error);
    if (getCaseID !== null || getCaseID !== undefined) {
      setCaseID(getCaseID);
    }
    return () => reloadStrictMode = true;
  }, []);

  const checkErrorStatusAndOpenErrorStatusDialog = () => {
    if(sessionStorage.getItem("error") !== "409") {
      setOpenErrorStatusDialog(true);
    }
  };

  useEffect(() => {

    return () => {
      if (ref.Interval !== null)
        clearInterval(ref.Interval);
      if(refTabCounterTimeOut.current != null)
        clearTimeout(refTabCounterTimeOut.current);
    };
  }, []);

  function saveCreatedData(x) {
    createdData = x;
  }

  async function getChanges(component, currentData, preData) {
    for (const item in currentData) {
      for (const check in preData) {
        if (item === check) {
          let now = currentData[item];
          let before = preData[check];
          if (now instanceof Date || before instanceof Date) {
            before = moment(before).format("DD/MM/YYYY");
            now = moment(now).format("DD/MM/YYYY");
          }
          if (now instanceof Array || before instanceof Array) {
            let theLength;
            if (now.length > before.length) {
              theLength = now.length;
            } else {
              theLength = before.length;
            }
            for (let i = 0; i < theLength; i++) {
              if (before[i] !== now[i]) {
                changes.push(item - `key:${i}`);
              }
            }
          } else if (before !== now) {
            changes.push(item);
          }
        }
      }
    }

    return;
  }

  async function saveChanges(component, currentData, preData, checkingDB) {
    let pageChanges = [];
    for (const item in currentData) {
      for (const check in preData) {
        if (item === check) {
          const checkSplit = check.split("-");
          const findChecking = checkingDB?.find(
            (item) => item?.key.split("-")[1] === checkSplit[1]
          );
          if (findChecking) {
          } else if (
            checkSplit[1].includes("estimatedCommencementDate") ||
            checkSplit[1].includes("estimatedCompletionDate") ||
            checkSplit[1].includes("applicantRegistrationDateTime") ||
            checkSplit[1].includes("date") ||
            checkSplit[1].includes("Date") ||
            checkSplit[1].includes("period") ||
            checkSplit[1].includes("Period")
          ) {

          } else {
            let now = currentData[item];
            let before = preData[check];
            if (now instanceof Date || before instanceof Date) {
              before = moment(before).format("DD/MM/YYYY");
              now = moment(now).format("DD/MM/YYYY");
            }
            if (now instanceof Array || before instanceof Array) {

            } else if (before !== now) {
              changes.push(item);
              pageChanges.push(item);
            }
          }
        }
      }
    }
    return await TrackChanges(pageChanges, theformId);
  }

  let resultComponent;
  let prevData;
  const changes = [];
  let data = [];
  const NewChanges = [];

  function searchElements() {
    const element = document.querySelectorAll("[name]");
    for (const el of element) {
      const attr = el.getAttribute("name");
      if (attr) {
        if (attr.startsWith("data")) {
          let elementValue = el.value;
          if (
            el.name.toString().includes("Date") ||
            el.name.toString().includes("date") ||
            el.name.toString().includes("period") ||
            el.name.toString().includes("Period")
          ) {
            elementValue = moment(el.value).format("DD/MM/YYYY");
          }
          if (
            el.name
              .toString()
              .includes("data[declarationAgreement][agreementCheck")
          ) {
            elementValue = el.checked;
          }
          const theValue = {
            name: el.name,
            nodeName: el.nodeName,
            value: elementValue,
            element: el,
          };
          const check = data.find((item) => item.name === el.name);
          if (check) {
          } else {
            data.push(theValue);
          }
        }
      }
    }
    return "success";
  }

  async function searchElementsSave(checkingDB) {
    let newData = [];
    const element = document.querySelectorAll("[name]");
    for (const el of element) {
      const attr = el.getAttribute("name");
      if (attr) {
        if (attr.startsWith("data")) {
          let elementValue = el.value;
          if (
            el.name
              .toString()
              .includes("data[declarationAgreement][agreementCheck")
          ) {
            elementValue = el.checked;
          }

          const check = data.find((item) => item.name === el.name);
          const findChecking = checkingDB?.find((i) => i.key === el.name);

          if (
            el.name.toString().includes("Date") ||
            el.name.toString().includes("date") ||
            el.name.toString().includes("period") ||
            el.name.toString().includes("Period")
          ) {
            elementValue = moment(el.value).format("DD/MM/YYYY");
          }

          const theValue = {
            name: el.name,
            nodeName: el.nodeName,
            value: elementValue,
            element: attr,
          };

          if (
            el.name.toString().includes("selectBoxesQFA_") ||
            el.name.toString().includes("agreeToAll")
          ) {
          } else if (findChecking) {
          } else if (check && check.nodeName === "BUTTON") {
          } else {
            if (check && check.value !== elementValue) {
              // NewChanges.push(theValue)
              newData.push(theValue);
            } else if (check === undefined || !check) {
              // NewChanges.push(theValue)
              newData.push(theValue);
            }
          }
        }
      }
    }

    // const posting = await NamePropertyTrackChanges(NewChanges, theformId)
    const posting = await NamePropertyTrackChanges(newData, theformId);
    return posting;
  }

  let allTitle;

  const formReady = async (form) => {
    $(async function () {
      setFetchingform(false);
      currentForm = form;
      currentForm.nosubmit = true;
      setThisForm(currentForm);
      if (currentForm.data.configContainer.publicApplicationStatus ===
        1 ||
        currentForm.data.configContainer.publicApplicationStatus ===
        9 ||
        currentForm.data.configContainer.applicationStatus === "1" ||
        currentForm.data.configContainer.applicationStatus === "9") {
        ref.Interval = setInterval(async function () {
          if (currentForm)
            await createOrSave('draft', currentForm, alreadySubmitted, createdData, saveCreatedData, createURL, appFormBaseURL, formId);
        }, 60000);
      }
      const getAllTitle = getTitle(currentForm, theformId, "IDPMainForm");
      allTitle = getAllTitle;

      if (appFormJson.current.data) {
        currentForm.data = appFormJson.current.data;
      }

      if (getStatus === "All") {
        setStatus("draft");
        prevStatus = "draft";
      } else if (
        currentForm.submission.data.configContainer.publicApplicationStatus
      ) {
        const statusTextCurrent = getStatusText(
          currentForm.submission.data.configContainer.publicApplicationStatus
        );
        setStatus(statusTextCurrent);
        prevStatus = statusTextCurrent;
      } else {
        setStatus("draft");
        prevStatus = "draft";
      }

      if (prevStatus === "Returned" && theformId !== null) {
        resultComponent = flatComponent(currentForm);
      }

      // const currentData = appFormJson.current.data

      // below 1 is draft status, 9 is returned status
      // only draft and returned back-end statuses can edit contents of this form
      if (
        currentForm.submission.data.configContainer.publicApplicationStatus !==
        1 &&
        currentForm.submission.data.configContainer.publicApplicationStatus !==
        9 &&
        currentForm.submission.data.configContainer.applicationStatus !== "1" &&
        currentForm.submission.data.configContainer.applicationStatus !== "9"
      ) {
        canSubmit = false;
        submitID_ref.current = createdData._id;
        form_ref.current = currentForm;
        goToReview();
      }

      // Applicant information.
      let applicant = loginHelper.getLoginUser();
      currentForm.submission.data.configContainer["usersInvolved"] =
        applicant?.Name;
      if (
        !currentForm.submission.data["contactName"] || !currentForm.submission.data["contactEmail"]
      ) {
        currentForm.submission.data.configContainer["companyInvolved"] =
          applicant?.CompanyName;
        if (!currentForm.submission.data["contactName"]) {
          currentForm.submission.data["contactName"] =
            applicant?.Name;
        }
        if (!currentForm.submission.data["contactEmail"]) {
          currentForm.submission.data["contactEmail"] =
            applicant?.Email;
        }
        try {
          let EDH = await getIDPEDHInfo(applicant.UEN);

          populateEDH(currentForm, EDH);
        } catch (e) {
          alert("Unable to retrieve company details");
        }
      }

      const commencementDate =
        currentForm.submission.data?.estimatedCommencementDate != null
          ? new Date(currentForm.submission.data["estimatedCommencementDate"])
          : null;
      currentForm.submission.data["estimatedCommencementDate"] =
        commencementDate;

      ConvertUtcToLocale_ApplicantRegistrationDate(currentForm);

      if (createdData._id) {
        currentForm.component.hidden = true;
        currentForm.setPage(2);
        submitID_ref.current = createdData._id;
        refTabCounterTimeOut.current = GenericTabTimeout(
          "applicantName",
          refTabCounterTimeOut,
          setStartSpinner,
          tabBufferCounter
        );
      }
      Utils.searchComponents(form.components, { type: "datagrid" }).forEach(
        (instance) => {
          if (instance?.component) {
            instance.component.addAnother = "Add On";
          }
        }
      );
      setTimeout(() => {
        getPreData();
      }, 500);

      currentForm.on("prevPage", async () => {
        setOpenAlert();
        setStartSpinner(true);
        const sendData = await getPrevData();

        if (sendData !== "noSave") {
          await prevPage(resultComponent);
        } else {
          setStartSpinner(false);
          // pop up and show error message
          checkErrorStatusAndOpenErrorStatusDialog(); 
        }
      });

      currentForm.on("change", (changed) => {
        GenericFileUploadOnChange(changed, false, currentForm, setStartSpinner);
      });

      currentForm.on("nextPage", async () => {
        $('button:contains("Next")').hide();
        setOpenAlert();
        setStartSpinner(true);
        const sendData = await getPrevData();

        if (sendData !== "noSave") {
          await nextPage(resultComponent);
        } else {
          setStartSpinner(false);
          // pop up and show error message
          checkErrorStatusAndOpenErrorStatusDialog(); 
        }
      });

      currentForm.on("draftSave", async () => {
        $('button:contains("Next")').hide();
        setStartSpinner(true);

        const savingDraft = await createOrSave(
          "draft",
          currentForm,
          alreadySubmitted,
          createdData,
          saveCreatedData,
          createURL,
          appFormBaseURL,
          formId
        );

        if (savingDraft.status === 200 || savingDraft.status === 201) {
          $('button:contains("Next")').show();
          setStartSpinner(false);
          setOpenAlert("Draft");
          setOpen(true);
        } else {
          $('button:contains("Next")').show();
          setStartSpinner(false);
          checkErrorStatusAndOpenErrorStatusDialog(); 
        }
      });

      currentForm.on("review", async () => {
        $('button:contains("Next")').hide();
        if (allowScrolling) window.scrollTo(0, 0);
        else setScrollState(() => (allowScrolling = true));
        setStartSpinner(true);

        const savingChangesData = await getPrevData();
        const responseStatus = await createOrSave(
          "draft",
          currentForm,
          alreadySubmitted,
          createdData,
          saveCreatedData,
          createURL,
          appFormBaseURL,
          formId
        );

        if (
          (responseStatus.status === 200 || responseStatus.status === 201) &&
          savingChangesData !== "noSave"
        ) {
          $('button:contains("Next")').show();
          validityTriggered = true;
          let valCheck = checkValidity();

          if (valCheck === false) {
            setOpenValidationDialog(true);
            form_ref.current = currentForm;
            submitID_ref.current = createdData._id;
            getPreData();
            setStartSpinner(false);
          } else {
            submitID_ref.current = createdData._id;
            form_ref.current = currentForm;
            goToReview();
          }
        } else {
          $('button:contains("Next")').show();
          setStartSpinner(false);
          checkErrorStatusAndOpenErrorStatusDialog(); 
        }
      });

      setTimeout(() => {
        pagination(currentForm);
      }, 90);

      if (currentForm.submission.data.configContainer)
        setCaseID(
          currentForm.submission.data.configContainer.applicationCaseId
        );

      if (currentForm.submission.data.configContainer.applicationCaseId)
        reviewCaseID =
          currentForm.submission.data.configContainer.applicationCaseId;

      ScheduleCallbackAfterDocumentReadyWithDelay(() => {
				ConsoleInfo("Bind click action to FormIO Page navigation");
        $(".page-link").on("click", async (event) => {
          const findTitleIndex = allTitle.indexOf(
            event.currentTarget.outerText
          );
          setOpenAlert();
          setStartSpinner(true);
          const sendData = await getPrevData();

          if (sendData !== "noSave") {
            const response = await createOrSave(
              "draft",
              currentForm,
              alreadySubmitted,
              createdData,
              saveCreatedData,
              createURL,
              appFormBaseURL,
              formId
            );

            if (response.status === 200 || response.status === 201) {
              currentForm.setPage(findTitleIndex);
              setStartSpinner(false);
            } else {
              setStartSpinner(false);
              // pop up and show error message
              checkErrorStatusAndOpenErrorStatusDialog(); 
            }
          } else {
            setStartSpinner(false);
            // pop up and show error message
            checkErrorStatusAndOpenErrorStatusDialog(); 
          }

          if (prevStatus === "Returned") {
            setTimeout(() => {
              getPreData();
            }, 1000);
          }
        });
      });

      currentForm.on("fileUploadingStart", async () => {
        setStartSpinner(true);
      });
      currentForm.on("fileUploadingEnd", async () => {
        setStartSpinner(false);
      });

      handleCustomInit();
    });
  };

  const handleCustomInit = () => {
    handleMinimumDateFields();
  };

  const handleCustomRenderEvent = () => {
    //-- JQuery to overwrite file component functionality to allow file download and delete with JWT Token
    GenericFileUploadOnRender(false, currentForm, setStartSpinner);
  };

  const handleMinimumDateFields = () => {
    // Minimum date should not depends on "today date", for already "submitted" or "route-back" forms
    if (
      currentForm.data?.estimatedCommencementDate !== undefined &&
      currentForm.data?.estimatedCommencementDate !== null
    ) {
      // This minDate logic: came from formio editor, which follows the business requirement logic.
      let minDate = moment()
        .startOf("day")
        .add(4, "weeks")
        .format("DD MMM yyyy");

      // Converts UTC to locale time
      const convertedMinDate = new Date(minDate);
      const estimateDate = new Date(currentForm.data.estimatedCommencementDate);

      if (estimateDate && convertedMinDate) {
        if (estimateDate < convertedMinDate) {
          const estimatedStartComp = currentForm.getComponent(
            "estimatedCommencementDate"
          );

          // Modifying the correct minDate
          estimatedStartComp.component.widget.minDate = estimateDate;
        }
      }
    }
  };

  // const [solutionNamePage, setSolutionNamePage] = useState(1)
  let solutionNamePage = 1;

  const onRender = async () => {
    if (allowScrolling) window.scrollTo(0, 0);
    else setScrollState(() => (allowScrolling = true));
    const rendering = await onRenderGeneric(
      setPage,
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId,
      prevStatus
    );
    setTimeout(() => {
      checkValidity();
    }, 0);
    $(function() {
      handleCustomRenderEvent();
    })
    if (rendering === "ok") {
      if (prevStatus === "Returned" || prevStatus === "returned") {
        //do not put getPre function in render (because sometimes the form change and the page rerender, so data will not be valid)
        setStartSpinner(true);
        $('button:contains("Next")').hide();
        $('button:contains("Back")').hide();
        $('button:contains("Review")').hide();
        $('button:contains("Save as Draft")').hide();
        if (
          (currentForm.page === 1 || currentForm.page === 3) &&
          solutionNamePage === 1
        ) {
          solutionNamePage = solutionNamePage + 1;
        }
        if (currentForm.page === 2 && solutionNamePage === 2) {
          solutionNamePage = solutionNamePage + 1;
        }
        // else if(currentForm.page === 2 && solutionNamePage > 2){
        //   setTimeout(() => {
        //     removeSpinner();
        //   }, 2000);
        // }
        else if (solutionNamePage === 2 || solutionNamePage > 2) {
          setTimeout(() => {
            removeSpinner();
          }, 2000);
        }
      }
    }
  };

  const onChange = async (event) => {
    if (event.changed != undefined) {
      switch (event.changed.component.key) {
        case "solutionName": {
          let solutionID = event.changed.value.textField;
          let getVendors = await fetch(
            `${config.appURL}/api/Generic/GetVendorsBySolutionId?id=${solutionID}`
          ).then((json) => {
            return json.data[0].data.vendor.data.vendorName;
          });
          let vendorName = currentForm.getComponent("vendorName");
          vendorName.setValue(getVendors.toString());
          currentForm.submission.data["vendorName"] = getVendors.toString();
          setScrollState(() => {
            allowScrolling = false;
          });
          currentForm.redraw();
          break;
        }
        case "chkSameAddress": {
          if (currentForm.submission.data.chkSameAddress === false) {
            //  Clear all the post data
            currentForm.submission.data.postalAddressInfo.postBlkNo = "";
            currentForm.submission.data.postalAddressInfo.postBuildingName = "";
            currentForm.submission.data.postalAddressInfo.postCountry = "";
            currentForm.submission.data.postalAddressInfo.postLvl = "";
            currentForm.submission.data.postalAddressInfo.postPtCode = "";
            currentForm.submission.data.postalAddressInfo.postStreet = "";
            currentForm.submission.data.postalAddressInfo.postUnitNo = "";
          } else {
            const postCountryComponent = currentForm.getComponent("postCountry");
            postCountryComponent.setValue(
              currentForm.submission.data.companyAddressInfo.companyCountry
            );
          }
          setScrollState(() => {
            allowScrolling = false;
          });
          currentForm.redraw();
          break;
        }
        default: {
          break;
        }
      }
    }
    onChangeGeneric(alreadySubmitted, setFormReadOnly, event);
    checkValidity();
    setStartSpinner(false);
  };

  const onSubmit = async (submission) => {
    onSubmitGeneric(
      submission,
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId
    );
  };

  const onError = async (errors) => {
    onErrorGeneric(errors);
  };

  const onSubmitDone = (submission) => {
    onSubmitDoneGeneric(submission);
  };

  const populateEDH = async (currentForm, EDH) => {
    let [user, companyCapital] = EDH;
    currentForm.submission.data["applicantName"] =
      user.companyDetails.entityName;
    currentForm.submission.data["applicantRegistrationNo"] = user.uen;
    const registrationDate = moment(
      user.companyDetails.registrationDate,
      "YYYY-MM-DD"
    );
    let registrationDateString = moment(registrationDate).format("YYYY-MM-DD");

    currentForm.submission.data["applicantRegistrationDateTime"] =
      registrationDateString;

    currentForm.submission.data["applicantIndustryName"] =
      user.companyDetails.primaryActivityDesc;

    currentForm.submission.data["companyAddressInfo"]["companyCountry"] =
      user.formattedAddress?.residentCountry || "Singapore"; //! Neither response has company country, default to Singapore
    currentForm.submission.data["companyAddressInfo"]["companyPtCode"] =
      user.formattedAddress.postalCode;
    currentForm.submission.data["companyAddressInfo"]["companyBlkNo"] =
      user.formattedAddress.blockHouseNumber;
    currentForm.submission.data["companyAddressInfo"]["companyStreet"] =
      user.formattedAddress.streetName;
    currentForm.submission.data["companyAddressInfo"]["companyLvl"] =
      user.formattedAddress.levelNumber;
    currentForm.submission.data["companyAddressInfo"]["companyUnitNo"] =
      user.formattedAddress.unitNumber.padStart(2, "0");
    currentForm.submission.data["companyAddressInfo"]["companyBuildingName"] =
      user.formattedAddress.buildingName;
    currentForm.submission.data["paidUpCapital"] =
      companyCapital.listCompanyCapital[0].paidUpCapitalAmount || 0;
    currentForm.submission.data.configContainer["companyInvolved"] =
      user.companyDetails.entityName;
  };

  const onCustomEvent = async ({ type, component, data, event }) => {
    switch (type) {
      case "checkEligibility": {
        // Updated eligibility logic
        // If answer is no to Q1, Q3,   REJECT!!
        // If answer is no to Q2, Not eligible, show link
        // If all questions answered, Q2 => YES -> Direct to IDP application, via ESG funding
        let message = "";
        let errors = { answeredYes: [], answeredNo: [], missing: [] };

        //  Check if eligible
        let eligibility_condition =
          currentForm.submission.data["question1"] !== "no" &&
          currentForm.submission.data["question3"] !== "no" &&
          currentForm.submission.data["question4"] !== "no";

        let reject_show_link_condition =
          currentForm.submission.data["question2"] === "no";
        let allQuestionsFilled = () => {
          let q_data = [
            currentForm.submission.data["question1"],
            currentForm.submission.data["question2"],
            currentForm.submission.data["question3"],
            currentForm.submission.data["question4"],
          ];

          return q_data.every((val) => val.trim() !== "");
        };

        //  Conditional checking for eligibility
        if (
          allQuestionsFilled() &&
          !reject_show_link_condition &&
          eligibility_condition
        ) {
          setIsEligible(true);
          setOpenEligibleDialog(true);
        } else {
          let q_data = [
            { q: 1, data: currentForm.submission.data["question1"] },
            { q: 2, data: currentForm.submission.data["question2"] },
            { q: 3, data: currentForm.submission.data["question3"] },
            { q: 4, data: currentForm.submission.data["question4"] },
          ];

          if (!allQuestionsFilled()) {
            message = "Please answer all the questions";
          } else if (!eligibility_condition) {
            q_data.forEach((val) => {
              if (val.data === "no") {
                errors.answeredNo.push(`Q${val.q}`);
              } else if (val.data === "yes") {
                errors.answeredYes.push(`Q${val.q}`);
              }
            });

            message = "You are not eligible for the grant because\n";
            //  Reason for error message
            message +=
              errors.answeredNo.length > 0
                ? `You answered "No" to question(s): ${errors.answeredNo.join(
                  ","
                )}\n`
                : "";
          } else {
            message = (
              <p>
                You are not eligible for IDP.
                <br />
                You may wish to consider applying for a grant under the
                MCF-Productivity at{" "}
                <Link
                  href="https://www.mpa.gov.sg/web/portal/home/maritime-companies/setting-up-in-singapore/developing-manpower/maritime-cluster-fund-mcf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.mpa.gov.sg/web/portal/home/maritime-companies/setting-up-in-singapore/developing-manpower/maritime-cluster-fund-mcf
                </Link>
              </p>
            );
          }

          setIneligibleMessage(message);
          setIsEligible(false);
          setOpenEligibleDialog(true);
        }
        break;
      }
      case "goToMain": {
        window.location.href = `/Dashboard`;
        break;
      }
      default: {
        break;
      }
    }
  };

  async function prevPage(resultComponent) {
    const responseStatus = await createOrSave(
      "draft",
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId
    );

    if (responseStatus.status === 200 || responseStatus.status === 201) {
      currentForm.setPage(currentForm.page - 1);
      setStartSpinner(false);
    } else {
      setStartSpinner(false);
      //pop up and show error message
      checkErrorStatusAndOpenErrorStatusDialog(); 
    }

    setTimeout(() => {
      getPreData();
    }, 1000);
  }

  async function nextPage(resultComponent) {
    $('button:contains("Next")').hide();
    let responseStatus = await createOrSave(
      "draft",
      currentForm,
      alreadySubmitted,
      createdData,
      saveCreatedData,
      createURL,
      appFormBaseURL,
      formId
    );
    if (responseStatus.status === 200 || responseStatus.status === 201) {
      $('button:contains("Next")').show();

      currentForm.setPage(currentForm.page + 1);
      setStartSpinner(false);      
      if (responseStatus.message.data.configContainer.applicationCaseId) {
        setCaseID(responseStatus.message.data.configContainer.applicationCaseId);        
        reviewCaseID =
          responseStatus.message.data.configContainer.applicationCaseId;
      }
      if (createdData._id) {
        submitID_ref.current = createdData._id;
        currentForm.submission.data["mainFormId"] = createdData._id;
      }
    } else {
      $('button:contains("Next")').show();
      setStartSpinner(false);
      //pop up and show error message
      checkErrorStatusAndOpenErrorStatusDialog(); 
    }

    window.scrollTo(0, 0);
    getPreData();
    // searchElements()
  }

  function removeSpinner() {
    setTimeout(() => {
      $('button:contains("Next")').show();
      $('button:contains("Back")').show();
      $('button:contains("Review")').show();
      // $('button:contains("Save as Draft")').show()
      setStartSpinner(false);
    }, 2000);
  }

  function getPreData() {
    if (prevStatus === "Returned" && theformId !== null) {
      const preData = scanData(resultComponent);
      const getSearch = searchElements();

      if (preData && getSearch) {
        if (currentForm.page === 2) {
          const checkSolutionName = Object.keys(preData).find(
            (key) => key.split("-")[1] === "solutionName"
          );

          if (
            checkSolutionName &&
            (preData[checkSolutionName] === "" ||
              preData[checkSolutionName] === undefined)
          ) {
            setTimeout(() => {
              getPreData();
            }, 1000);
          } else {
            prevData = preData;
            setTimeout(() => {
              $('button:contains("Next")').show();
              $('button:contains("Back")').show();
              $('button:contains("Review")').show();
              // $('button:contains("Save as Draft")').show()
              setStartSpinner(false);
            }, 2000);
          }
        } else {
          prevData = preData;
          setTimeout(() => {
            $('button:contains("Next")').show();
            $('button:contains("Back")').show();
            $('button:contains("Review")').show();
            // $('button:contains("Save as Draft")').show()
            setStartSpinner(false);
          }, 2000);
        }
      }
    }
  }

  async function getPrevData() {
    const latestData = scanData(resultComponent);

    if (prevStatus === "Returned" && theformId !== null && latestData) {
      const checkingDB = await getTrackChanges(theformId);
      const searchSave = await searchElementsSave(checkingDB.data.data);
      const TrackChangesSave = await saveChanges(
        resultComponent,
        latestData,
        prevData,
        checkingDB.data.data
      );

      if ([200, 201].includes(searchSave.status) && [200, 201].includes(TrackChangesSave.status)) {
        return "ok Success";
      } else {
        return "noSave";
      }
    } else {
      return "no track changes";
    }
  }

  function changePage(x) {
    thisForm.setPage(x);
  }

  function goToReview() {
    let contactName = form_ref.current._submission.data.contactName;

    if (caseID === null || caseID === undefined) {
      setCaseID(reviewCaseID);
    }

    history.push({
      pathname: "/IDPMainReview",
      search: `?_id=${submitID_ref.current}&status=${prevStatus}`,
      // search: `?_id=${submitID_ref.current}`,

      state: {
        user:
          contactName === ""
            ? form_ref.current._submission.data.configContainer.usersInvolved
            : contactName,
        company:
          form_ref.current._submission.data.configContainer.companyInvolved,
        caseID: caseID === null || caseID === undefined ? reviewCaseID : caseID,
        prevStatus: prevStatus,
        canSubmit: canSubmit,
      },
      refresh: true,
    });
  }

  function scrollToError() {
    window.scrollTo(0, 0);
  }

  function goToTop() {
    window.scrollTo(0, 0);
    setTimeout(scrollToError, 2000);
  }

  function checkValidity() {
    let valCheck = false;
    if (validityTriggered) {
      valCheck = currentForm.checkValidity(null, true, null, false);
      let oldPagesErrors = pagesErrors;
      currentForm.pages.forEach(
        (x, index) => (oldPagesErrors[index] = x.errors.length)
      );
      setPagesErrors(oldPagesErrors);
      setCurrentPageErrors(pagesErrors[currentForm.page]);
    }
    return valCheck;
  }

  function goToNextPage() {
    setOpenEligibleDialog(false);
    thisForm.setPage(1);
  }

  const handleDelete = async () => {
    try {
      if (submitID_ref.current) {
        const originalPromiseResult = await dispatch(
          deleteFormAPIAsync({
            formId: formId,
            submissionId: submitID_ref.current,
          })
        ).unwrap();
        history.push({
          pathname: "/NestedApplicationListing",
          search: `?schema=1`,
          refresh: true,
        });
      } else {
        alert("Delete is not allowed");
      }
    } catch (rejectedValueOrSerializedError) {
      alert("Delete is not allowed");
    }
  };

  return (
    <div className="applicationform">
      <ValidationDialog
        open={openValidationDialog}
        setOpen={setOpenValidationDialog}
        goToTop={goToTop}
      />
      <Container>
        <FormHtmlHead formname={formname} schema={schema} />
        <div style={{ display: page === 0 ? "block" : "none" }}>
          <Typography variant="h2">
            Apply for Industry Digital Plan (IDP)
          </Typography>
          <br />
          <Typography className="univers-55roman-normal-mine-shaft-22px">
            Answer a few simple questions to check if you are eligible for this
            grant.
          </Typography>
          <br />
          <div style={{ display: "flex" }}>
            <IconCheckupList />
            &nbsp;&nbsp;
            <Typography variant="h4">Check your eligibility</Typography>
          </div>
        </div>
        <div
          style={{
            display: page === 0 ? "none" : "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormHtmlTitle
              formname={formname}
              formid={formid}
              formstatus={status}
              caseID={caseID}
            />
          </div>
          <br />
          <PageCarousel
            thisform={thisForm}
            setpage={changePage}
            pagesErrors={pagesErrors}
            status={status}
          />
        </div>
        <FormHtmlErrors
          thisForm={thisForm}
          pagesErrors={pagesErrors}
          currentPageErrors={currentPageErrors}
        />

        <FormHtmlForm
          fetchingform={fetchingform}
          formReadOnly={formReadOnly}
          props={props}
          appFormURL={appFormURL}
          onChange={onChange}
          onError={onError}
          formReady={formReady}
          onSubmit={onSubmit}
          onSubmitDone={onSubmitDone}
          onRender={onRender}
          onCustomEvent={onCustomEvent}
          isBusy={isBusy}
          appFormJson={appFormJson}
        />
      </Container>
      {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>test</div>
            </Modal> */}
      <EligibleDialogGrant
        open={openEligibleDialog}
        setOpen={setOpenEligibleDialog}
        isEligible={isEligible}
        ineligibleMessage={ineligibleMessage}
        goToForm={goToNextPage}
        grantType={grantType.fullName}
      />
      <AutoSaveErrorDialog
        open={openErrorStatusDialog}
        setOpen={setOpenErrorStatusDialog}
      />
      <SnackbarAlert
        alert={openAlert}
        caseID={caseID}
        open={open}
        setOpen={setOpen}
      />

      <ProgressBar
        ref={ref}
        startSpinner={startSpinner}
        setStartSpinner={setStartSpinner}
        bar={bar}
        setBar={setBar}
      />
    </div>
  );
}
