import { ReactComponent as FormioReactComponent } from "@formio/react";
import editForm from "./Comment.form";
import { createRoot } from "react-dom/client";
import { ConsoleInfo } from "../../Common/Logger";

// Formio Custom React Component
export default class OfficerCommentComponent extends FormioReactComponent {
	private container: any;

	/**
	 * This function tells the form builder about your component. It's name, icon and what group it should be in.
	 *
	 * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
	 */
	static builderInfo = {
		title: "Comment",
		group: "basic",
		icon: "fa fa-table",
		weight: 70,
		documentation: "/userguide/#table",
		schema: OfficerCommentComponent.schema(),
	};

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema() {
		return (FormioReactComponent as any).schema({
			type: "comment",
			numRows: 2,
			numCols: 1,
		});
	}

	/*
	 * Defines the settingsForm when editing a component in the builder.
	 */
	public static editForm = editForm;

	/**
	 * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
	 *
	 * @param DOMElement
	 * #returns ReactInstance
	 */
	attachReact(element: any) {
		this.hideLabelElement();

		ConsoleInfo(
			"attaching react component to formio key",
			(this as any).component.key,
		);

		// render nothing
		this.container = createRoot(element);
		return this.container.render();
	}

	private hideLabelElement() {
		const labelElement = document.getElementById(
			`l-${(this as any).id}-${(this as any).key}`,
		);
		if (labelElement) {
			labelElement!.style.display = "none";
		}
		return labelElement;
	}

	/**
	 * Automatically detach any react components.
	 *
	 * @param element
	 */
	detachReact(element: any) {
		if (element) {
			super.detachReact(element);
		}
	}

	verify(token: string): boolean {
		return true;
	}
}
