import { ConsoleError } from "../../Common/Logger";

const COMMENT_UI_CONFIG = {
	COMMENT_TEXT_AREA_CHARACTER_LIMIT: 1000,
};

export const CommentStatus = {
	DRAFT: "draft",
	SUBMITTED: "submitted",
	DELETED: "deleted",
};

export interface Comment {
	_id?: string;
	Comment: string;
	OfficerId: string;
	FieldName: string;
	SubmissionId: string;
	FormName?: string;
	Timestamp: Date;
	Status: string;
	AuthorOfficerId: number | null;
	IsCurrent: number;
}

export interface ApplicantComment extends Comment {
	ApplicantId: string;
	AuthorApplicantId: number | null;
}

export type CommentsByFieldNameType = {
	FieldName?: string;
	Comments?: Array<ApplicantComment>;
};

export function checkExternalCommentNotification(
	commentsOfThisField: ApplicantComment[],
	submissionId: string | null,
	fieldName: string,
	isSubmissionReturned: boolean,
) {
	if (!commentsOfThisField || commentsOfThisField.length <= 0) {
		return false;
	}

	const lastComment = commentsOfThisField
		?.slice()
		?.filter(
			(cm) => cm.SubmissionId === submissionId && cm.FieldName === fieldName,
		)
		.filter((c) => c.IsCurrent === 0)
		.pop();

	if (!lastComment) {
		return false;
	}

	const madeBy = CommentMadeBy(lastComment);
	const enableIcon = isSubmissionReturned && madeBy === "officer";
	return enableIcon;
}

export function IsCommentMadeByCorrectOfficer(
	comment: Comment | undefined,
	user: any,
): boolean {
	if (!comment) {
		return false;
	}
	const username = getUserFullName(user);
	return comment.OfficerId === username;
}

export function CommentMadeBy(
	comment: ApplicantComment | undefined,
): undefined | "officer" | "applicant" {
	if (!comment) {
		return undefined;
	}
	const isOfficer = comment.OfficerId ? "officer" : undefined;
	const isApplicant = comment.ApplicantId ? "applicant" : undefined;
	return isOfficer ?? isApplicant; // undefined if both don't exists
}

export function getCommentsForFieldAndSort(
	commentList: CommentsByFieldNameType[],
	fieldName: string,
): Comment[] | ApplicantComment[] | undefined {
	try {
		const filtered = commentList.filter((c) => c.FieldName === fieldName);
		if (filtered.length <= 0) {
			return undefined;
		}
		return sortByTime(filtered?.[0].Comments);
	} catch (e) {
		ConsoleError(
			"sortAndFilterCommentList - error - input: ",
			commentList as any,
		);
		ConsoleError("sortAndFilterCommentList - error - input: ", fieldName);
		ConsoleError("sortAndFilterCommentList - error: ", e);
		return undefined;
	}
}

export function sortByTime(
	inputArray: Comment[] | ApplicantComment[] | undefined,
): Comment[] | ApplicantComment[] | undefined {
	const toSort = inputArray?.slice() ?? [];
	if (toSort.length <= 0) {
		return undefined;
	}

	return toSort.sort((a, b) => {
		let da = new Date(a.Timestamp);
		let db = new Date(b.Timestamp);
		return da.getTime() - db.getTime();
	});
}

export function getUserFullName(user: any) {
	return user.Name;
}

export function trimEndChar(str: string, char: string): string {
	return str.replace(new RegExp(`${char}+$`), "");
}

export function getQueryParam(paramName: string): string | null {
	const urlParams = new URLSearchParams(
		trimEndChar(window.location.search, "/"),
	);
	return urlParams.get(paramName);
}

export function getSubmissionId() {
	return sessionStorage.getItem("submissionId");
}

export function formatFieldName(input: string) {
	return input.replace(/[:\s]/g, "");
}

export { COMMENT_UI_CONFIG };
